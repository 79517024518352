const EmptyCircleIcon = () => (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="4.56592" cy="4.56592" r="4.06592" stroke="#525252" />
    </svg>
);

const EmptyCircleIconBlue = () => (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="4.56592" cy="4.56592" r="4.06592" stroke="#006AFF" />
    </svg>
);

const IconCircleRevertHover = () => (
    <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="9.56592" cy="11.5659" r="4.06592" fill="#006AFF" />
        <circle cx="9.56592" cy="11.5659" r="4.06592" stroke="#006AFF" />
        <circle cx="9.56592" cy="11.5659" r="4.06592" stroke="#006AFF" />
        <circle cx="9.56592" cy="11.5659" r="4.06592" fill="#006AFF" />
        <circle cx="9.56592" cy="11.5659" r="4.06592" stroke="#006AFF" />
        <circle cx="9.56592" cy="11.5659" r="4.06592" stroke="#006AFF" />
        <path d="M3 6.02248L8.75525 5.56373L5.48033 0.808916L3 6.02248ZM17.5 11.5C17.5 15.9183 13.9183 19.5 9.5 19.5V20.5C14.4706 20.5 18.5 16.4706 18.5 11.5H17.5ZM9.5 19.5C5.08172 19.5 1.5 15.9183 1.5 11.5H0.5C0.5 16.4706 4.52944 20.5 9.5 20.5V19.5ZM9.5 3.5C13.9183 3.5 17.5 7.08172 17.5 11.5H18.5C18.5 6.52944 14.4706 2.5 9.5 2.5V3.5ZM6.87049 3.94204C7.6934 3.65578 8.57798 3.5 9.5 3.5V2.5C8.46461 2.5 7.46905 2.67504 6.54193 2.99756L6.87049 3.94204Z" fill="#006AFF" />
    </svg>
);

const IconCircleRevert = () => (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="4.56592" cy="4.56592" r="4.06592" fill="#006AFF" />
        <circle cx="4.56592" cy="4.56592" r="4.06592" stroke="#006AFF" />
        <circle cx="4.56592" cy="4.56592" r="4.06592" stroke="#006AFF" />
        <circle cx="4.56592" cy="4.56592" r="4.06592" fill="#006AFF" />
        <circle cx="4.56592" cy="4.56592" r="4.06592" stroke="#006AFF" />
        <circle cx="4.56592" cy="4.56592" r="4.06592" stroke="#006AFF" />
    </svg>
);

export {
    EmptyCircleIcon,
    IconCircleRevert,
    IconCircleRevertHover,
    EmptyCircleIconBlue,
};