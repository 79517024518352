import React, { useContext, useState } from "react";
import { Circle, Rect, Layer, Line, Stage, Image } from "react-konva";
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from "react-redux";

import axios from "axios";

import { API_ROUTE } from "../../../index";
import { TakeoffContext } from "../../helper/Context";
import { selectAuth } from "../../../redux/slices/authSlice";

export default function RectangleConstructor({ }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages,
        groups, setGroups,
        currentGroup,
        selectedGroups,
        createMeasurement,
        createMeasurements,
        setDrawingRectangle,
        history, setHistory,
        currentHistory, setCurrentHistory,
        setMeasurements,
        handleChangeFilter,
    } = useContext(TakeoffContext);

    const [drawing, setDrawing] = useState(false);

    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);

    const addRectangle = (rect) => {
        handleChangeFilter('include_area', true);

        let tempName = 'temp' + uuidv4();

        setMeasurements(prev => ({
            ...prev,
            [tempName]: {
                'type': 'rectangle',
                'page': pageID,
                'color': groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3',
                'width': rect.width,
                'height': rect.height,
                ['rectangle']: {
                    x: rect.x,
                    y: rect.y,
                },
            }
        }))

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/rectangle/`,
            data: {
                'userID': auth.user.id,
                'pageID': pageID,
                'group': currentGroup || null,
                'groups': [...(currentGroup ? new Set([currentGroup, ...selectedGroups]) : new Set(selectedGroups))],
                'x': rect.x,
                'y': rect.y,
                'width': rect.width,
                'height': rect.height,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);

                //createMeasurement(response.data, false, tempName);
                createMeasurements(response.data, tempName);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <>
            {(drawing && start && end) &&
                <Rect
                    x={start.x}
                    y={start.y}
                    width={end.x - start.x}
                    height={end.y - start.y}
                    fill={currentGroup ? groups[currentGroup]?.color : '#9DD9F3'}
                    opacity={0.5}
                />
            }

            <Rect
                x={0}
                y={0}
                width={pages[pageID].width}
                height={pages[pageID].height}
                //onMouseOver={(e) => e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+rectangle.svg') 8 24, auto"}
                onClick={(e) => {
                    if (e.evt.button !== 0) return;
                    
                    if (drawing) {
                        const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                        const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                        addRectangle({ x: start.x, y: start.y, width: x - start.x, height: y - start.y });
                        setStart(null);
                        setEnd(null);
                        setDrawing(false);
                    } else {
                        setDrawing(true);
                        const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                        const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                        setStart({ x: x, y: y });
                    }
                }}
                onMouseMove={(e) => {
                    if (drawing) {
                        const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                        const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                        setEnd({ x: x, y: y });
                    }
                }}
            />
        </>
    );
}