import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectAuth } from "../redux/slices/authSlice";
import axios from "axios";
import { useEffect, useState } from "react";
import { API_ROUTE } from "..";

import './styles/ProjectDashboard.css';
import ProjectDashboardModal from "./components/ProjectDetailsModal";
import { IconArrowLeft, IconArrowLeftTail, IconCalculator, IconCheck, IconCircleCheckFilled, IconCircleDot, IconCircles, IconFileExport, IconFiles, IconFileUpload, IconMessage, IconPencil, IconPlaneDeparture, IconPolygon, IconRectangle, IconRobot, IconRobotFace, IconTableExport, IconTimeline } from "@tabler/icons-react";
import Icon from "@mdi/react";
import InfoIcon from "../components/InfoIcon";

export default function ProjectDashboard() {
    const params = useParams();
    const projectUUID = params.projectUUID;

    const auth = useSelector(selectAuth);

    const [project, setProject] = useState(null);
    const [projectDashboard, setProjectDashboard] = useState(null);

    const [showProjectDetails, setShowProjectDetails] = useState(false);

    useEffect(() => {
        if (auth.token) {
            axios({
                method: 'get',
                url: `${API_ROUTE}/api/project-dashboard/`,
                params: {
                    'projectUUID': projectUUID
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json"
                },
            })
                .then((response) => {
                    console.log(response);

                    setProjectDashboard(response.data);
                    setProject(response.data.project);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [auth]);

    return (
        <>
            <div className='projectdashboardnavbar-container'>
                <a href="/dashboard">
                    <div className='projectdetailsnavbar-back'>
                        <IconArrowLeft size={20} /> All Projects
                    </div>
                </a>
                <div className='projectdetailsnavbar-profile-picture'>
                    <a href={`/profile`}>
                        {projectDashboard?.contractor?.profile_picture
                            ? <img
                                src={projectDashboard?.contractor?.profile_picture}
                                alt='profile picture'
                                className='dashboard-navbar-profile-picture'
                            />
                            : projectDashboard?.contractor?.first_name && projectDashboard?.contractor?.last_name
                                ? <div className='dashboard-navbar-profile-picture-initials'>
                                    {projectDashboard?.contractor?.first_name[0] + projectDashboard?.contractor?.last_name[0]}
                                </div>
                                : <img
                                    src={'https://bobyard-public-images.s3.us-west-2.amazonaws.com/2828447.png'}
                                    alt='profile picture'
                                    className='dashboard-navbar-profile-picture'
                                />
                        }
                    </a>
                </div>
            </div>

            <div className='projectdashboard-container'>
                <div className='projectdashboard-left-container'>
                    <div className="projectdashboard-left-section-container">
                        <div className="projectdashboard-projectdetails-header">
                            <div className="projectdashboard-projectdetails-title">
                                {project?.title}
                            </div>
                            <div
                                className="projectdashboard-projectdetails-edit"
                                onClick={() => setShowProjectDetails(true)}
                            >
                                <IconPencil size={20} />
                            </div>
                        </div>

                        {showProjectDetails && projectDashboard &&
                            <ProjectDashboardModal
                                project={project}
                                setProject={setProject}
                                showProjectDetails={showProjectDetails}
                                setShowProjectDetails={setShowProjectDetails}
                            />
                        }

                        <div className="projectdashboard-projectdetails-address">
                            {project?.address || 'No address...'}
                        </div>
                        <div className="projectdashboard-projectdetails-description">
                            {project?.desc || 'No description...'}
                        </div>
                    </div>

                    <div className="projectdashboard-left-section-container">
                        <div className="projectdashboard-projectdetails-header">
                            <div className="projectdashboard-takeofffiles-title">
                                <div className="projectdashboard-projectdetails-title">
                                    <IconFileUpload size={20} /> Takeoff files
                                </div>
                                <div className="projectdashboard-takeofffiles-count">
                                    {projectDashboard?.takeoff_files?.files?.length || 0} file{projectDashboard?.takeoff_files?.files?.length !== 1 && 's'}
                                </div>
                                <div className="projectdashboard-takeofffiles-count">
                                    {projectDashboard?.takeoff_files?.pages?.length || 0} page{projectDashboard?.takeoff_files?.pages?.length !== 1 && 's'}
                                </div>
                                <div className="projectdashboard-takeofffiles-count">
                                    {projectDashboard?.takeoff_files?.pages?.filter(page => page.is_included).length || 0} page{projectDashboard?.takeoff_files?.pages?.filter(page => page.is_included).length !== 1 && 's'} included
                                </div>
                            </div>
                            <a href={`/files/${projectUUID}`}>
                                <div className="projectdashboard-step-action">
                                    {projectDashboard?.takeoff_files?.files?.length > 0 ? 'Manage' : 'Upload'}
                                </div>
                            </a>
                        </div>
                        {projectDashboard?.takeoff_files?.files?.length === 0 &&
                            <div className="projectdashboard-takeofffiles-error">
                                No takeoff files. Upload files to get started.
                            </div>
                        }

                        {projectDashboard?.takeoff_files?.pages && projectDashboard?.takeoff_files?.pages?.filter(page => page.is_included).length === 0 &&
                            <div className="projectdashboard-takeofffiles-error">
                                No pages included for takeoff.
                            </div>
                        }

                        {projectDashboard?.takeoff_files?.files?.length > 0 ?
                            <div className="projectdashboard-takeofffiles-container">
                                {projectDashboard?.takeoff_files?.files?.map((file, index) => (
                                    <div key={index} className="projectdashboard-takeofffiles-file">
                                        <div className="projectdashboard-takeofffiles-file-name">
                                            {file.name}
                                        </div>
                                        <div className="projectdashboard-takeofffiles-file-date">
                                            {file.created?.split('T')[0]}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            : null}
                    </div>

                    <div className="projectdashboard-left-section-container">
                        <div className="projectdashboard-projectdetails-header">
                            <div className="projectdashboard-takeofffiles-title">
                                <div className="projectdashboard-projectdetails-title">
                                    <IconPlaneDeparture size={20} />
                                    Takeoff
                                </div>
                                <div className="projectdashboard-takeofffiles-count">
                                    {projectDashboard?.takeoff?.pages_taken_off || 0} page{projectDashboard?.takeoff?.pages_taken_off !== 1 && 's'} taken off
                                </div>
                                <div className="projectdashboard-takeofffiles-count">
                                    {projectDashboard?.takeoff?.measurement_count} measurement{projectDashboard?.takeoff?.measurement_count !== 1 && 's'}
                                </div>
                            </div>
                            {projectDashboard?.takeoff_files?.pages?.filter(p => p.is_included).length > 0
                                ? <a href={`/takeoff/${projectUUID}/${projectDashboard?.takeoff_files?.pages?.filter(p => p.is_included)[0].id}`}>
                                    <div className="projectdashboard-step-action">
                                        {projectDashboard?.takeoff?.measurement_count > 0 ? 'Continue' : 'Takeoff'}
                                    </div>
                                </a>
                                : <div className="projectdashboard-step-action-disabled">
                                    Takeoff
                                </div>

                            }
                        </div>

                        {projectDashboard?.takeoff?.measurements?.length > 0 ?
                            <div className="projectdashboard-takeofffiles-container">
                                {projectDashboard?.takeoff?.measurements?.map((measurement, index) =>
                                    <div key={index} className="projectdashboard-takeofffiles-file">
                                        <div className="projectdashboard-takeofffiles-measurement-name">
                                            <div>
                                                {measurement.type === 'line'
                                                    ? <IconTimeline size={20} style={{ 'color': measurement.color }} />
                                                    : measurement.type === 'polygon'
                                                        ? <IconPolygon size={20} style={{ 'color': measurement.color }} />
                                                        : measurement.type === 'rectangle'
                                                            ? <IconRectangle size={20} style={{ 'color': measurement.color }} />
                                                            : measurement.type === 'circle'
                                                                ? <IconCircleDot size={20} style={{ 'color': measurement.color }} />
                                                                : measurement.type === 'count'
                                                                    ? <IconCircles size={20} style={{ 'color': measurement.color }} />
                                                                    : null}
                                            </div>

                                            <div>
                                                {measurement.ai_created &&
                                                    <IconRobot size={20} style={{ 'color': '#006afe' }} />
                                                }
                                            </div>

                                            <div>
                                                {measurement.name}
                                            </div>
                                        </div>
                                        <div className="projectdashboard-takeofffiles-file-date">
                                            {measurement.date_created?.split('T')[0]}
                                        </div>
                                    </div>
                                )}
                            </div>
                            : null}
                    </div>

                    <div className="projectdashboard-left-section-container">
                        <div className="projectdashboard-projectdetails-header">
                            <div className="projectdashboard-takeofffiles-title">
                                <div className="projectdashboard-projectdetails-title">
                                    <IconCalculator size={20} /> Estimate
                                </div>
                                <div className="projectdashboard-takeofffiles-count">
                                    {projectDashboard?.estimate?.item_count || 0} item{projectDashboard?.estimate?.item_count !== 1 && 's'}
                                </div>
                                <div className="projectdashboard-takeofffiles-count">
                                    {projectDashboard?.estimate?.group_count || 0} group{projectDashboard?.estimate?.group_count !== 1 && 's'}
                                </div>
                            </div>
                            <a href={`/estimate/${projectUUID}`}>
                                <div className="projectdashboard-step-action">
                                    {projectDashboard?.takeoff?.item_count > 0 ? 'Continue' : 'Estimate'}
                                </div>
                            </a>
                        </div>
                    </div>

                    {/*<div className="projectdashboard-left-section-container">
                        <div className="projectdashboard-projectdetails-header">
                            <div className="projectdashboard-takeofffiles-title">
                                <div className="projectdashboard-projectdetails-title">
                                    <IconFileExport size={20} /> Export
                                </div>
                                <div className="projectdashboard-takeofffiles-count">
                                    {projectDashboard?.export?.length || 0} export{projectDashboard?.export?.length !== 1 && 's'}
                                </div>
                            </div>
                            <div className="projectdashboard-step-actions">
                                {projectDashboard?.export?.length > 0 &&
                                    <a href={`/exports/${projectUUID}`}>
                                        <div className="projectdashboard-step-action-default">
                                            View
                                        </div>
                                    </a>
                                }
                                {projectDashboard?.estimate?.item_count > 0
                                    ? <div className="projectdashboard-step-action">
                                        Create
                                    </div>
                                    : <div className="projectdashboard-step-action-disabled">
                                        Create
                                    </div>
                                }
                            </div>
                        </div>

                        {projectDashboard?.export?.length > 0 ?
                            <div className="projectdashboard-takeofffiles-container">
                                {projectDashboard?.export?.map((file, index) => (
                                    <div key={index} className="projectdashboard-takeofffiles-file">
                                        {file.name}
                                    </div>
                                ))}
                            </div>
                            : null}
                    </div>*/}
                </div>
                <div className='projectdashboard-right-container'>
                    <div className='projectdashboard-projectfiles-header'>
                        <div>
                            <div className="projectdashboard-projectdetails-title">
                                <IconMessage size={20} /> Project files & chat <InfoIcon id='projectdashboard-projectfiles-info' location='top'>
                                    <div>
                                        Upload documents, spec sheets, drawings, and more for storage.
                                    </div>
                                    <div>
                                        Chat with an AI that has been trained on your project files.
                                    </div>
                                </InfoIcon>
                            </div>
                        </div>
                        <a href={`/chat/${projectUUID}`}>
                            <div className="projectdashboard-step-action">
                                {projectDashboard?.project_files?.length > 0 ? 'Manage' : 'Upload'}
                            </div>
                        </a>
                    </div>

                    {projectDashboard?.project_files?.length === 0 &&
                        <div className="projectdashboard-projectfiles-error">
                            No project files. Upload files to chat.
                        </div>
                    }

                    <div className='projectdashboard-projectfiles-container'>
                        {projectDashboard?.project_files?.map((file, index) => (
                            <div key={index} className="projectdashboard-projectfiles-file">
                                <div className="projectdashboard-projectfiles-file-header">
                                    <div className="projectdashboard-projectfiles-file-date">
                                        {file.created?.split('T')[0]}
                                    </div>
                                    <div>
                                        {file.chatbot_embeded
                                            ? <IconCircleCheckFilled size={20} style={{ 'color': '#006afe' }} />
                                            : <div className="projectdashboard-projectfiles-file-ai">
                                                AI training...
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="projectdashboard-projectfiles-file-name">
                                    {file.name}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}