import { Arrow, Circle, Group, Rect, Text, Transformer } from "react-konva";
import { useContext, useEffect, useRef, useState } from "react";

import { TakeoffContext } from "../../helper/Context";
import { Html, Portal } from "react-konva-utils";
import ContextMenu from "../../../components/ContextMenu";
import { IconCopy, IconTrashX, IconZoomScan } from "@tabler/icons-react";

export default function AnnotationArrow({ annotation }) {
    const {
        annotations, setAnnotations,
        handleCreateAnnotation,
        handleUpdateAnnotation,
        pages, pageID,
        setEditingMeasurement,
        drawing,
        currentMeasurement, setCurrentMeasurement,
        selectedMeasurements, setSelectedMeasurements,
        currentAnnotation, setCurrentAnnotation,
        selectedAnnotations, setSelectedAnnotations,
        setShowDeleteAnnotationModal,
        handleZoomToAnnotation,
    } = useContext(TakeoffContext);

    const isSelected = (currentAnnotation === annotation.id && !drawing && !currentMeasurement);

    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                setCurrentAnnotation(null);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    return (
        <>
            <Html>
                {showContextMenu && currentAnnotation === annotation.id &&
                    <ContextMenu
                        x={contextMenuPosition.x}
                        y={contextMenuPosition.y}
                        zoom={pages[pageID].zoom}
                        showContextMenu={showContextMenu}
                        setShowContextMenu={setShowContextMenu}
                    >
                        <div
                            className="contextmenu-item"
                            onClick={() => handleCreateAnnotation({
                                type: "arrow",
                                data: {
                                    x1: annotation.arrow.x1 + 10,
                                    y1: annotation.arrow.y1 + 10,
                                    x2: annotation.arrow.x2 + 10,
                                    y2: annotation.arrow.y2 + 10,
                                    size: annotation.size,
                                    stroke_color: annotation.stroke_color,
                                    fill_color: annotation.fill_color,
                                }
                            })}
                        >
                            <IconCopy size={16} stroke={1} />
                            <div>
                                Duplicate
                            </div>
                        </div>

                        <div
                            className="contextmenu-item"
                            onClick={() => handleZoomToAnnotation(annotation.id)}
                        >
                            <IconZoomScan size={16} stroke={1} />
                            <div>
                                Zoom
                            </div>
                        </div>

                        <div
                            className="contextmenu-item contextmenu-item-delete"
                            onClick={() => setShowDeleteAnnotationModal(true)}
                        >
                            <IconTrashX size={16} stroke={1} />
                            <div>
                                Delete
                            </div>
                        </div>
                    </ContextMenu>
                }
            </Html>

            <Portal
                selector={'.top-layer'}
                enabled={isSelected}
            >
                <Arrow
                    onClick={(e) => {
                        if (e.evt.button !== 0) return;

                        if (e.evt.metaKey) {
                            if (selectedAnnotations.includes(annotation.id)) {
                                setSelectedAnnotations(selectedAnnotations.filter((id) => id !== annotation.id));
                            } else {
                                setSelectedAnnotations([...selectedAnnotations, annotation.id]);
                            }
                        } else {
                            setSelectedAnnotations([annotation.id]);
                            setCurrentAnnotation(annotation.id)
                        }

                        setCurrentMeasurement(null);
                        setSelectedMeasurements([]);
                    }}
                    onContextMenu={(e) => {
                        e.evt.preventDefault();
                        setSelectedAnnotations([annotation.id]);
                        setCurrentAnnotation(annotation.id);
                        setCurrentMeasurement(null);

                        setContextMenuPosition({
                            x: (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom,
                            y: (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom,
                        })
                        setShowContextMenu(true);
                    }}

                    x={annotation.arrow.x1}
                    y={annotation.arrow.y1}
                    points={[0, 0, annotation.arrow.x2, annotation.arrow.y2]}
                    fill={annotation.fill_color}
                    stroke={annotation.stroke_color}
                    strokeWidth={annotation.size}
                    opacity={isSelected || selectedAnnotations.includes(annotation.id) ? 0.7 : 0.5}

                    draggable={isSelected && !drawing}
                    onDragMove={(e) => {
                        setAnnotations({
                            ...annotations,
                            [annotation.id]: {
                                ...annotation,
                                arrow: {
                                    ...annotation.arrow,
                                    x1: e.target.x(),
                                    y1: e.target.y(),
                                }
                            }
                        })
                    }}
                    onDragEnd={(e) => {
                        handleUpdateAnnotation(annotations[annotation.id])
                    }}
                    strokeScaleEnabled={false}
                    perfectDrawEnabled={false}
                />

                {isSelected &&
                    <Circle
                        x={annotation.arrow.x1}
                        y={annotation.arrow.y1}
                        radius={10 / pages[pageID].zoom}
                        fill={annotation.fill_color}
                        stroke={annotation.stroke_color}
                        strokeWidth={1}
                        opacity={isSelected ? 0.7 : 0.5}
                        draggable={isSelected && !drawing}
                        onDragMove={(e) => {
                            setAnnotations({
                                ...annotations,
                                [annotation.id]: {
                                    ...annotation,
                                    arrow: {
                                        ...annotation.arrow,
                                        x1: e.target.x(),
                                        y1: e.target.y(),
                                    }
                                }
                            })
                        }}
                        onDragEnd={(e) => {
                            handleUpdateAnnotation(annotations[annotation.id])
                        }}
                    />
                }

                {isSelected &&
                    <Circle
                        x={annotation.arrow.x2 + annotation.arrow.x1}
                        y={annotation.arrow.y2 + annotation.arrow.y1}
                        radius={10 / pages[pageID].zoom}
                        fill={annotation.fill_color}
                        stroke={annotation.stroke_color}
                        strokeWidth={1}
                        opacity={isSelected ? 0.7 : 0.5}
                        draggable={isSelected && !drawing}
                        onDragMove={(e) => {
                            setAnnotations({
                                ...annotations,
                                [annotation.id]: {
                                    ...annotation,
                                    arrow: {
                                        ...annotation.arrow,
                                        x2: e.target.x() - annotation.arrow.x1,
                                        y2: e.target.y() - annotation.arrow.y1,
                                    }
                                }
                            })
                        }}
                        onDragEnd={(e) => {
                            handleUpdateAnnotation(annotations[annotation.id])
                        }}
                    />
                }
            </Portal>
        </>
    )
}