import React, { useContext, useState } from "react";
import { Circle, Rect, Layer, Line, Stage, Image } from "react-konva";
import { useSelector } from "react-redux";

import axios from "axios";

import { API_ROUTE } from "../../..";
import { TakeoffContext } from "../../helper/Context";
import { selectAuth } from "../../../redux/slices/authSlice";

export default function AIAutoMeasureLengthRectangle({ }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages,
        groups, setGroups,
        currentGroup,
        createMeasurement,
        setDrawingRectangle,
        history, setHistory,
        currentHistory, setCurrentHistory,
        setMeasurements,
        tree, setTree,
        handleChangeFilter,
        AIAutoMeasureLengthRect, setAIAutoMeasureLengthRect,
        AIMessages, setAIMessages,
    } = useContext(TakeoffContext);

    const [drawing, setDrawing] = useState(false);

    const handleInference = (points) => {
        setDrawing(false);

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/create-pipe-lengths/`,
            data: {
                'page': pageID,
                'location': 'rectangle',
                'x1': Math.min(points.x1, points.x2),
                'y1': Math.min(points.y1, points.y2),
                'x2': Math.max(points.x1, points.x2),
                'y2': Math.max(points.y1, points.y2),
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });

        setAIMessages(prev => ({
            ...prev,
            [pageID]: {
                ...prev[pageID],
                'autoMeasureLength': {
                    ...prev[pageID]?.autoMeasureLength,
                    'progress': 1 / 100,
                    'show': true,
                    'running': true,
                }
            }
        }));
    }

    return (
        <>
            {AIAutoMeasureLengthRect?.[pageID]?.end && AIAutoMeasureLengthRect?.[pageID]?.start &&
                <Rect
                    x={AIAutoMeasureLengthRect?.[pageID]?.start.x}
                    y={AIAutoMeasureLengthRect?.[pageID]?.start.y}
                    width={AIAutoMeasureLengthRect?.[pageID]?.end.x - AIAutoMeasureLengthRect?.[pageID]?.start.x}
                    height={AIAutoMeasureLengthRect?.[pageID]?.end.y - AIAutoMeasureLengthRect?.[pageID]?.start.y}
                    fill={'#9DD9F3'}
                    stroke={'#006AFE'}
                    opacity={0.5}
                />
            }

            <Rect
                x={0}
                y={0}
                width={pages[pageID].width}
                height={pages[pageID].height}
                onMouseUp={(e) => {
                    if (e.evt.button !== 0) return;

                    if (!AIMessages[pageID]?.autoMeasureLength?.running) {
                        if (drawing) {
                            const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                            const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                            setDrawing(false);
                            setAIAutoMeasureLengthRect(prev => ({
                                ...prev,
                                [pageID]: {
                                    ...prev?.[pageID],
                                    end: { x: x, y: y },
                                    isComplete: true,
                                }
                            }));
                            handleInference({ x1: AIAutoMeasureLengthRect?.[pageID]?.start.x, y1: AIAutoMeasureLengthRect?.[pageID]?.start.y, x2: x, y2: y });
                        } else {
                            setDrawing(true);
                            const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                            const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                            setAIAutoMeasureLengthRect(prev => ({
                                ...prev,
                                [pageID]: {
                                    ...prev?.[pageID],
                                    start: { x: x, y: y },
                                    isComplete: false,
                                }
                            }));
                        }
                    }
                }}
                onMouseMove={(e) => {
                    if (drawing && !AIMessages[pageID]?.autoMeasureLength?.running) {
                        const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                        const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                        setAIAutoMeasureLengthRect(prev => ({
                            ...prev,
                            [pageID]: {
                                ...prev?.[pageID],
                                end: { x: x, y: y }
                            }
                        }));
                    }
                }}
            />
        </>
    );
}