const UOM = [
    'ea',
    'in',
    'ft',
    'yrd',
    'cm',
    'm',
    'ftx2',
    'inx2',
    'yrdx2',
    'cmx2',
    'mx2',
    'in2',
    'ft2',
    'yrd2',
    'cm2',
    'm2',
    'in3',
    'ft3',
    'yrd3',
    'cm3',
    'm3',
    'g',
    'kg',
    'ton',
    'oz',
    'lb'
]

const UOM_Display = {
    'ea': 'ea',
    'in': 'in',
    'ft': 'ft',
    'yrd': 'yrd',
    'cm': 'cm',
    'm': 'm',
    'ftx2': '*ft',
    'inx2': '*in',
    'yrdx2': '*yrd',
    'cmx2': '*cm',
    'mx2': '*m',
    'in2': 'in²',
    'ft2': 'ft²',
    'yrd2': 'yrd²',
    'cm2': 'cm²',
    'm2': 'm²',
    'in3': 'in³',
    'ft3': 'ft³',
    'yrd3': 'yrd³',
    'cm3': 'cm³',
    'm3': 'm³',
    'g': 'g',
    'kg': 'kg',
    'ton': 'ton',
    'oz': 'oz',
    'lb': 'lb',
}

const UOM_Description = {
    'ea': 'Each',
    'in': 'Inch',
    'ft': 'Foot',
    'yrd': 'Yard',
    'cm': 'Centimeter',
    'm': 'Meter',
    'inx2': 'Inch',
    'ftx2': 'Foot',
    'yrdx2': 'Yard',
    'cmx2': 'Centimeter',
    'mx2': 'Meter',
    'in2': 'Square Inch',
    'ft2': 'Square Foot',
    'yrd2': 'Square Yard',
    'cm2': 'Square Centimeter',
    'm2': 'Square Meter',
    'in3': 'Cubic Inch',
    'ft3': 'Cubic Foot',
    'yrd3': 'Cubic Yard',
    'cm3': 'Cubic Centimeter',
    'm3': 'Cubic Meter',
    'g': 'Gram',
    'kg': 'Kilogram',
    'ton': 'Ton',
    'oz': 'Ounce',
    'lb': 'Pound',
}

const UOM_Measured_Default = {
    'count': 'ea',
    'line': 'ft',
    'circle': 'ft2',
    'rectangle': 'ft2',
    'polygon': 'ft2',
}

const UOM_Pitchable = {
    'ea': false,
    'in': true,
    'ft': true,
    'yrd': true,
    'cm': true,
    'm': true,
    'inx2': true,
    'ftx2': true,
    'yrdx2': true,
    'cmx2': true,
    'mx2': true,
    'in2': true,
    'ft2': true,
    'yrd2': true,
    'cm2': true,
    'm2': true,
    'in3': false,
    'ft3': false,
    'yrd3': false,
    'cm3': false,
    'm3': false,
    'g': false,
    'kg': false,
    'ton': false,
    'oz': false,
    'lb': false,
}

const UOM_Dependencies_Description = {
    'ea': {
        'ea': [{ quantity: 'Counted' }],
        'in': [{ quantity: 'Counted' }, { quantity1: 'Length in inches of each' }],
        'ft': [{ quantity: 'Counted' }, { quantity1: 'Length in feet of each' }],
        'yrd': [{ quantity: 'Counted' }, { quantity1: 'Length in yards of each' }],
        'cm': [{ quantity: 'Counted' }, { quantity1: 'Length in centimeters of each' }],
        'm': [{ quantity: 'Counted' }, { quantity1: 'Length in meters of each' }],
        'in2': [{ quantity: 'Counted' }, { quantity1: 'Area in square inches of each' }],
        'ft2': [{ quantity: 'Counted' }, { quantity1: 'Area in square feet of each' }],
        'yrd2': [{ quantity: 'Counted' }, { quantity1: 'Area in square yards of each' }],
        'cm2': [{ quantity: 'Counted' }, { quantity1: 'Area in square centimeters of each' }],
        'm2': [{ quantity: 'Counted' }, { quantity1: 'Area in square meters of each' }],
        'in3': [{ quantity: 'Counted' }, { quantity1: 'Volume in cubic inches of each' }],
        'ft3': [{ quantity: 'Counted' }, { quantity1: 'Volume in cubic feet of each' }],
        'yrd3': [{ quantity: 'Counted' }, { quantity1: 'Volume in cubic yards of each' }],
        'cm3': [{ quantity: 'Counted' }, { quantity1: 'Volume in cubic centimeters of each' }],
        'm3': [{ quantity: 'Counted' }, { quantity1: 'Volume in cubic meters of each' }],
        'g': [{ quantity: 'Counted' }, { quantity1: 'Weight in grams of each' }],
        'kg': [{ quantity: 'Counted' }, { quantity1: 'Weight in kilograms of each' }],
        'ton': [{ quantity: 'Counted' }, { quantity1: 'Weight in tons of each' }],
        'oz': [{ quantity: 'Counted' }, { quantity1: 'Weight in ounces of each' }],
        'lb': [{ quantity: 'Counted' }, { quantity1: 'Weight in pounds of each' }],
    },
    'ft': {
        'ea': [{ quantity: 'Measured' }, { quantity1: 'O.C. in inches' }],
        'in': [{ quantity: 'Measured' }],
        'ft': [{ quantity: 'Measured' }],
        'yrd': [{ quantity: 'Measured' }],
        'cm': [{ quantity: 'Measured' }],
        'm': [{ quantity: 'Measured' }],
        'in2': [{ quantity: 'Measured' }, { quantity1: 'Length of other dimension in inches' }],
        'ft2': [{ quantity: 'Measured' }, { quantity1: 'Length of other dimension in feet' }],
        'yrd2': [{ quantity: 'Measured' }, { quantity1: 'Length of other dimension in yards' }],
        'cm2': [{ quantity: 'Measured' }, { quantity1: 'Length of other dimension in centimeters' }],
        'm2': [{ quantity: 'Measured' }, { quantity1: 'Length of other dimension in meters' }],
        'in3': [{ quantity: 'Measured' }, { quantity1: 'Length of 1st other dimension in inches' }, { quantity2: 'Length of 2nd other dimension in inches' }],
        'ft3': [{ quantity: 'Measured' }, { quantity1: 'Length of 1st other dimension in feet' }, { quantity2: 'Length of 2nd other dimension in feet' }],
        'yrd3': [{ quantity: 'Measured' }, { quantity1: 'Length of 1st other dimension in yards' }, { quantity2: 'Length of 2nd other dimension in yards' }],
        'cm3': [{ quantity: 'Measured' }, { quantity1: 'Length of 1st other dimension in centimeters' }, { quantity2: 'Length of 2nd other dimension in centimeters' }],
        'm3': [{ quantity: 'Measured' }, { quantity1: 'Length of 1st other dimension in meters' }, { quantity2: 'Length of 2nd other dimension in meters' }],
    },
    'ft2': {
        'ea': [{ quantity: 'Measured' }, { quantity1: 'O.C. in inches' }, { quantity2: '0 for square grid, 1 for triangular grid' }],
        'in': [{ quantity: 'Measured' }, { quantity1: 'O.C. in inches' }, { quantity2: 'Angle in degrees' }],
        'ft': [{ quantity: 'Measured' }, { quantity1: 'O.C. in inches' }, { quantity2: 'Angle in degrees' }],
        'yrd': [{ quantity: 'Measured' }, { quantity1: 'O.C. in inches' }, { quantity2: 'Angle in degrees' }],
        'cm': [{ quantity: 'Measured' }, { quantity1: 'O.C. in inches' }, { quantity2: 'Angle in degrees' }],
        'm': [{ quantity: 'Measured' }, { quantity1: 'O.C. in inches' }, { quantity2: 'Angle in degrees' }],
        'inx2': [{ quantity: 'Measured' }, { quantity1: 'O.C. in inches' }, { quantity2: 'Angle in degrees' }],
        'ftx2': [{ quantity: 'Measured' }, { quantity1: 'O.C. in inches' }, { quantity2: 'Angle in degrees' }],
        'yrdx2': [{ quantity: 'Measured' }, { quantity1: 'O.C. in inches' }, { quantity2: 'Angle in degrees' }],
        'cmx2': [{ quantity: 'Measured' }, { quantity1: 'O.C. in inches' }, { quantity2: 'Angle in degrees' }],
        'mx2': [{ quantity: 'Measured' }, { quantity1: 'O.C. in inches' }, { quantity2: 'Angle in degrees' }],
        'in2': [{ quantity: 'Measured' }],
        'ft2': [{ quantity: 'Measured' }],
        'yrd2': [{ quantity: 'Measured' }],
        'cm2': [{ quantity: 'Measured' }],
        'm2': [{ quantity: 'Measured' }],
        'in3': [{ quantity: 'Measured' }, { quantity1: 'Length of other dimension in inches' }],
        'ft3': [{ quantity: 'Measured' }, { quantity1: 'Length of other dimension in feet' }],
        'yrd3': [{ quantity: 'Measured' }, { quantity1: 'Length of other dimension in yards' }],
        'cm3': [{ quantity: 'Measured' }, { quantity1: 'Length of other dimension in centimeters' }],
        'm3': [{ quantity: 'Measured' }, { quantity1: 'Length of other dimension in meters' }],
        'g': [{ quantity: 'Measured' }, { quantity1: 'Length of other dimension in feet' }, { quantity2: 'Density in pounds per cubic foot' }],
        'kg': [{ quantity: 'Measured' }, { quantity1: 'Length of other dimension in feet' }, { quantity2: 'Density in pounds per cubic foot' }],
        'ton': [{ quantity: 'Measured' }, { quantity1: 'Length of other dimension in feet' }, { quantity2: 'Density in pounds per cubic foot' }],
        'oz': [{ quantity: 'Measured' }, { quantity1: 'Length of other dimension in feet' }, { quantity2: 'Density in pounds per cubic foot' }],
        'lb': [{ quantity: 'Measured' }, { quantity1: 'Length of other dimension in feet' }, { quantity2: 'Density in pounds per cubic foot' }],
    },
}

const UOM_Dependencies = {
    'ea': {
        'ea': [{ quantity: 'Counted' }],
        'in': [{ quantity: 'Counted' }, { quantity1: 'Length (in)' }],
        'ft': [{ quantity: 'Counted' }, { quantity1: 'Length (ft)' }],
        'yrd': [{ quantity: 'Counted' }, { quantity1: 'Length (yrd)' }],
        'cm': [{ quantity: 'Counted' }, { quantity1: 'Length (cm)' }],
        'm': [{ quantity: 'Counted' }, { quantity1: 'Length (m)' }],
        'in2': [{ quantity: 'Counted' }, { quantity1: 'Area (in²)' }],
        'ft2': [{ quantity: 'Counted' }, { quantity1: 'Area (ft²)' }],
        'yrd2': [{ quantity: 'Counted' }, { quantity1: 'Area (yrd²)' }],
        'cm2': [{ quantity: 'Counted' }, { quantity1: 'Area (cm²)' }],
        'm2': [{ quantity: 'Counted' }, { quantity1: 'Area (m²)' }],
        'in3': [{ quantity: 'Counted' }, { quantity1: 'Volume (in³)' }],
        'ft3': [{ quantity: 'Counted' }, { quantity1: 'Volume (ft³)' }],
        'yrd3': [{ quantity: 'Counted' }, { quantity1: 'Volume (yrd³)' }],
        'cm3': [{ quantity: 'Counted' }, { quantity1: 'Volume (cm³)' }],
        'm3': [{ quantity: 'Counted' }, { quantity1: 'Volume (m³)' }],
        'g': [{ quantity: 'Counted' }, { quantity1: 'Weight (g)' }],
        'kg': [{ quantity: 'Counted' }, { quantity1: 'Weight (kg)' }],
        'ton': [{ quantity: 'Counted' }, { quantity1: 'Weight (ton)' }],
        'oz': [{ quantity: 'Counted' }, { quantity1: 'Weight (oz)' }],
        'lb': [{ quantity: 'Counted' }, { quantity1: 'Weight (lb)' }],
    },
    'ft': {
        'ea': [{ quantity: 'Measured' }, { quantity1: 'O.C. (in)' }],
        'in': [{ quantity: 'Measured' }],
        'ft': [{ quantity: 'Measured' }],
        'yrd': [{ quantity: 'Measured' }],
        'cm': [{ quantity: 'Measured' }],
        'm': [{ quantity: 'Measured' }],
        'in2': [{ quantity: 'Measured' }, { quantity1: 'Length (in)' }],
        'ft2': [{ quantity: 'Measured' }, { quantity1: 'Length (ft)' }],
        'yrd2': [{ quantity: 'Measured' }, { quantity1: 'Length (yrd)' }],
        'cm2': [{ quantity: 'Measured' }, { quantity1: 'Length (cm)' }],
        'm2': [{ quantity: 'Measured' }, { quantity1: 'Length (m)' }],
        'in3': [{ quantity: 'Measured' }, { quantity1: 'Length1 (in)' }, { quantity2: 'Length2 (in)' }],
        'ft3': [{ quantity: 'Measured' }, { quantity1: 'Length1 (ft)' }, { quantity2: 'Length2 (ft)' }],
        'yrd3': [{ quantity: 'Measured' }, { quantity1: 'Length1 (yrd)' }, { quantity2: 'Length2 (yrd)' }],
        'cm3': [{ quantity: 'Measured' }, { quantity1: 'Length1 (cm)' }, { quantity2: 'Length2 (cm)' }],
        'm3': [{ quantity: 'Measured' }, { quantity1: 'Length1 (m)' }, { quantity2: 'Length2 (m)' }],
    },
    'ft2': {
        'ea': [{ quantity: 'Measured' }, { quantity1: 'O.C. (in)' }, { quantity2: 'Sqr/tri (0/1)' }],
        'in': [{ quantity: 'Measured' }, { quantity1: 'O.C. (in)' }, { quantity2: 'Angle (degrees)' }],
        'ft': [{ quantity: 'Measured' }, { quantity1: 'O.C. (in)' }, { quantity2: 'Angle (degrees)' }],
        'yrd': [{ quantity: 'Measured' }, { quantity1: 'O.C. (in)' }, { quantity2: 'Angle (degrees)' }],
        'cm': [{ quantity: 'Measured' }, { quantity1: 'O.C. (in)' }, { quantity2: 'Angle (degrees)' }],
        'm': [{ quantity: 'Measured' }, { quantity1: 'O.C. (in)' }, { quantity2: 'Angle (degrees)' }],
        'inx2': [{ quantity: 'Measured' }, { quantity1: 'O.C. (in)' }, { quantity2: 'Angle (degrees)' }],
        'ftx2': [{ quantity: 'Measured' }, { quantity1: 'O.C. (in)' }, { quantity2: 'Angle (degrees)' }],
        'yrdx2': [{ quantity: 'Measured' }, { quantity1: 'O.C. (in)' }, { quantity2: 'Angle (degrees)' }],
        'cmx2': [{ quantity: 'Measured' }, { quantity1: 'O.C. (in)' }, { quantity2: 'Angle (degrees)' }],
        'mx2': [{ quantity: 'Measured' }, { quantity1: 'O.C. (in)' }, { quantity2: 'Angle (degrees)' }],
        'in2': [{ quantity: 'Measured' }],
        'ft2': [{ quantity: 'Measured' }],
        'yrd2': [{ quantity: 'Measured' }],
        'cm2': [{ quantity: 'Measured' }],
        'm2': [{ quantity: 'Measured' }],
        'in3': [{ quantity: 'Measured' }, { quantity1: 'Length (in)' }],
        'ft3': [{ quantity: 'Measured' }, { quantity1: 'Length (ft)' }],
        'yrd3': [{ quantity: 'Measured' }, { quantity1: 'Length (yrd)' }],
        'cm3': [{ quantity: 'Measured' }, { quantity1: 'Length (cm)' }],
        'm3': [{ quantity: 'Measured' }, { quantity1: 'Length (m)' }],
        'g': [{ quantity: 'Measured' }, { quantity1: 'Length (ft)' }, { quantity2: 'Density (lb/ft³)' }],
        'kg': [{ quantity: 'Measured' }, { quantity1: 'Length (ft)' }, { quantity2: 'Density (lb/ft³)' }],
        'ton': [{ quantity: 'Measured' }, { quantity1: 'Length (ft)' }, { quantity2: 'Density (lb/ft³)' }],
        'oz': [{ quantity: 'Measured' }, { quantity1: 'Length (ft)' }, { quantity2: 'Density (lb/ft³)' }],
        'lb': [{ quantity: 'Measured' }, { quantity1: 'Length (ft)' }, { quantity2: 'Density (lb/ft³)' }],
    },
}

const UOM_CONVERSIONS = {
    'ea': {
        'ea': ({ quantity }) => quantity,
        'in': ({ quantity, quantity1 }) => quantity * quantity1,
        'ft': ({ quantity, quantity1 }) => quantity * quantity1,
        'yrd': ({ quantity, quantity1 }) => quantity * quantity1,
        //'cm': ({ quantity, quantity1 }) => quantity * quantity1,
        'm': ({ quantity, quantity1 }) => quantity * quantity1,
        //'in2': ({ quantity, quantity1 }) => quantity * quantity1,
        'ft2': ({ quantity, quantity1 }) => quantity * quantity1,
        'yrd2': ({ quantity, quantity1 }) => quantity * quantity1,
        //'cm2': ({ quantity, quantity1 }) => quantity * quantity1,
        'm2': ({ quantity, quantity1 }) => quantity * quantity1,
        //'in3': ({ quantity, quantity1 }) => quantity * quantity1,
        'ft3': ({ quantity, quantity1 }) => quantity * quantity1,
        'yrd3': ({ quantity, quantity1 }) => quantity * quantity1,
        //'cm3': ({ quantity, quantity1 }) => quantity * quantity1,
        'm3': ({ quantity, quantity1 }) => quantity * quantity1,
        //'g': ({ quantity, quantity1 }) => quantity * quantity1,
        'kg': ({ quantity, quantity1 }) => quantity * quantity1,
        'ton': ({ quantity, quantity1 }) => quantity * quantity1,
        //'oz': ({ quantity, quantity1 }) => quantity * quantity1,
        'lb': ({ quantity, quantity1 }) => quantity * quantity1,
    },
    'ft': {
        'ea': ({ quantity, quantity1 }) => quantity / (quantity1 / 12), //asumes inches OC
        'in': ({ quantity }) => quantity * 12,
        'ft': ({ quantity }) => quantity,
        'yrd': ({ quantity }) => quantity / 3,
        //'cm': ({ quantity }) => quantity * 30.48,
        'm': ({ quantity }) => quantity * 0.3048,
        //'in2': ({ quantity, quantity1 }) => quantity * quantity1 * 12,
        'ft2': ({ quantity, quantity1 }) => quantity * quantity1,
        'yrd2': ({ quantity, quantity1 }) => quantity * quantity1 / 3,
        //'cm2': ({ quantity, quantity1 }) => quantity * quantity1 * 30.48,
        'm2': ({ quantity, quantity1 }) => quantity * quantity1 * 0.3048,
        //'in3': ({ quantity, quantity1, quantity2 }) => quantity * quantity1 * quantity2 * 12,
        'ft3': ({ quantity, quantity1, quantity2 }) => quantity * quantity1 * quantity2,
        'yrd3': ({ quantity, quantity1, quantity2 }) => quantity * quantity1 * quantity2 / 3,
        'cm3': ({ quantity, quantity1, quantity2 }) => quantity * quantity1 * quantity2 * 30.48,
        //'m3': ({ quantity, quantity1, quantity2 }) => quantity * quantity1 * quantity2 * 0.3048,
    },
    'ft2': {
        'ea': ({ quantity, quantity1, quantity2 }) => {   //assumes inches OC, q2 is either 0 or 1
            if (quantity2 == 1) {
                return quantity / (((quantity1 / 12) ** 2) * Math.cos(Math.PI / 6))
            } else {
                return quantity / ((quantity1 / 12) ** 2)
            }
        },
        'in': ({ quantity, quantity1 }) => {
            return (quantity / ((quantity1 / 12) ** 2)) * (quantity1 / 12) * 12
        },
        'ft': ({ quantity, quantity1, quantity2 }) => {
            return (quantity / ((quantity1 / 12) ** 2)) * (quantity1 / 12)
        },
        'yrd': ({ quantity, quantity1, quantity2 }) => {
            return (quantity / ((quantity1 / 12) ** 2)) * (quantity1 / 12) / 3
        },
        'cm': ({ quantity, quantity1, quantity2 }) => {
            return (quantity / ((quantity1 / 12) ** 2)) * (quantity1 / 12) * 30.48
        },
        'm': ({ quantity, quantity1, quantity2 }) => {
            return (quantity / ((quantity1 / 12) ** 2)) * (quantity1 / 12) * 0.3048
        },
        'inx2': ({ quantity, quantity1 }) => {
            return (2 * quantity / ((quantity1 / 12) ** 2)) * (quantity1 / 12) * 12
        },
        'ftx2': ({ quantity, quantity1 }) => {
            return (2 * quantity / ((quantity1 / 12) ** 2)) * (quantity1 / 12)
        },
        'yrdx2': ({ quantity, quantity1 }) => {
            return (2 * quantity / ((quantity1 / 12) ** 2)) * (quantity1 / 12) / 3
        },
        'cmx2': ({ quantity, quantity1 }) => {
            return (2 * quantity / ((quantity1 / 12) ** 2)) * (quantity1 / 12) * 30.48
        },
        'mx2': ({ quantity, quantity1 }) => {
            return (2 * quantity / ((quantity1 / 12) ** 2)) * (quantity1 / 12) * 0.3048
        },
        //'in2': ({ quantity }) => quantity * 144,
        'ft2': ({ quantity }) => quantity,
        'yrd2': ({ quantity }) => quantity / 9,
        //'cm2': ({ quantity }) => quantity * 929.03,
        'm2': ({ quantity }) => quantity * 0.092903,
        //'in3': ({ quantity, quantity1 }) => quantity * quantity1 * 12,
        'ft3': ({ quantity, quantity1 }) => quantity * quantity1,
        'yrd3': ({ quantity, quantity1 }) => quantity * quantity1 / 9,
        //'cm3': ({ quantity, quantity1 }) => quantity * quantity1 * 929.03,
        'm3': ({ quantity, quantity1 }) => quantity * quantity1 * 0.092903,
        //assumes ft2, ft, and lb/ft3
        //'g': ({ quantity, quantity1, quantity2 }) => quantity * quantity1 * quantity2 * 453.592,
        'kg': ({ quantity, quantity1, quantity2 }) => quantity * quantity1 * quantity2 * 0.453592,
        'ton': ({ quantity, quantity1, quantity2 }) => quantity * quantity1 * quantity2 * 0.000453592,
        //'oz': ({ quantity, quantity1, quantity2 }) => quantity * quantity1 * quantity2 * 16,
        'lb': ({ quantity, quantity1, quantity2 }) => quantity * quantity1 * quantity2,
    },
}

const calculateValue = (m, scale) => {
    if (!m || !m?.type) return 0;

    let quantity = UOM_Measured_Default[m.type] === 'ea'
        ? m.count
        : UOM_Measured_Default[m.type] === 'ft'
            ? m.length * scale
            : UOM_Measured_Default[m.type] === 'ft2'
                ? m.area * scale ** 2
                : 1

    quantity = (m?.value !== null && m?.value !== undefined) ? m.value : quantity

    let converted = quantity

    let uom_measured = m.uom_measured || UOM_Measured_Default[m.type]

    if (m.uom && m.uom !== uom_measured) {
        if ((UOM_Dependencies[uom_measured][m.uom].length === 1 || UOM_Dependencies[uom_measured][m.uom].length > 1 && (m.quantity1 !== null || m.quantity1 !== undefined))
            && (UOM_Dependencies[uom_measured][m.uom].length === 1 || UOM_Dependencies[uom_measured][m.uom].length > 1 && (m.quantity2 !== null || m.quantity2 !== undefined))) {
            if (UOM_CONVERSIONS[uom_measured][m.uom]) {
                converted = UOM_CONVERSIONS[uom_measured][m.uom]({ quantity: quantity, quantity1: m.quantity1, quantity2: m.quantity2 })
            }
        }
    }

    if (((m.uom && UOM_Pitchable[m.uom]) || !m.uom && UOM_Pitchable[UOM_Measured_Default[m.type]]) && m.pitch) {        
        converted = converted / (Math.cos(Math.atan(m.pitch / 12)))
    }

    return converted
}

export {
    UOM,
    UOM_Display,
    UOM_Pitchable,
    UOM_Description,
    UOM_Dependencies_Description,
    UOM_Measured_Default,
    UOM_Dependencies,
    UOM_CONVERSIONS,
    calculateValue,
}