import { createElement, createRef, useEffect, useId } from 'react';
import {
  IconFolder,
  IconRectangle,
  IconPolygon,
  IconCircleDot,
  IconTimeline,
  IconCircles,
  IconStar,
  IconCaretRight,
  IconCaretDown,
  IconChevronRight,
  IconChevronDown,
  IconFolderOpen,
  IconCube,
  IconAssembly,
  IconSquareCheckFilled,
  IconSquare,
  IconSquareOff,
} from '@tabler/icons-react';
import NumericField from './NumericField';
import TextField from '../../components/TextField';
import { useState } from 'react';
import { isNil } from 'ramda';
import SelectField from './SelectField';
import NumericElement from './NumericElement';
import TextElement from './TextElement';
import InapplicableElement from './InapplicableElement';
import { Item } from 'react-contexify';
import { Tooltip } from 'react-tooltip';

export const ColumnHeader = ({ children, onClick = () => {}, className, style, id, onContextMenu = () => {} }) => (
  <div
    className={`flex flex-row items-center justify-between bg-blue-alice h-10 py-2.5 pl-2.5 pr-0 text-xs font-light mb-0.5 cursor-pointer overflow-clip text-nowrap ${className}`}
    onClick={onClick}
    onContextMenu={onContextMenu}
    style={style}
    id={id}
  >
    {children}
  </div>
);

export const ColumnWrapper = ({ children, className, style }) => (
  <div className={`flex flex-col shrink-0 ${className} `} style={style}>
    {children}
  </div>
);

export const ColumnListElement = ({ onContextMenu = () => {}, onClick = () => {}, onMouseEnter = () => {}, onMouseLeave = () => {}, children, className, style }) => (
  <div
    className={`p-[3px] text-xs h-10 overflow-clip whitespace-nowrap shrink-0 items-center flex flex-row ${className}`}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onContextMenu={onContextMenu}
    style={style}
  >
    {children}
  </div>
);

export const typeIconMap = {
  group: IconFolder, //this is a fallback icon
  assembly: IconAssembly, //this is a fallback icon too
  assembly_entry: IconCube,
  circle: IconCircleDot,
  rectangle: IconRectangle,
  polygon: IconPolygon,
  line: IconTimeline,
  count: IconCircles,
  item: IconStar,
};

export const IconElement = ({ value, isExpanded, className, onClick = () => {}, style }) => {
  if (value == 'group') {
    if (isExpanded)
      return (
        <ToolbarButton className={className} onClick={onClick}>
          <IconFolderOpen size={20} style={style} />
        </ToolbarButton>
      );
    return (
      <ToolbarButton className={className} onClick={onClick}>
        <IconFolder size={20} style={style} />
      </ToolbarButton>
    );
  }
  if (value == 'assembly') {
    if (isExpanded)
      return (
        <ToolbarButton className={className} onClick={onClick}>
          <IconCaretDown size={20} style={style} />
        </ToolbarButton>
      );
    return (
      <ToolbarButton className={className} onClick={onClick}>
        <IconCaretRight size={20} style={style} />
      </ToolbarButton>
    );
  }
  return <div className='flex items-center justify-center w-10 h-10 gap-2 '>{createElement(typeIconMap[value], { className, onClick, size: 20, style })}</div>;
};

export const EmptyCircleIcon = () => (
  <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='4.56592' cy='4.56592' r='4.06592' stroke='#525252' />
  </svg>
);

export const IconCircleRevertHover = () => (
  <svg width='19' height='21' viewBox='0 0 19 21' fill='none' xmlns='http://www.w3.org/2000/svg' className='mt-[-3px] ml-[-1px]'>
    <circle cx='9.56592' cy='11.5659' r='4.06592' fill='#006AFF' />
    <circle cx='9.56592' cy='11.5659' r='4.06592' stroke='#006AFF' />
    <circle cx='9.56592' cy='11.5659' r='4.06592' stroke='#006AFF' />
    <circle cx='9.56592' cy='11.5659' r='4.06592' fill='#006AFF' />
    <circle cx='9.56592' cy='11.5659' r='4.06592' stroke='#006AFF' />
    <circle cx='9.56592' cy='11.5659' r='4.06592' stroke='#006AFF' />
    <path
      d='M3 6.02248L8.75525 5.56373L5.48033 0.808916L3 6.02248ZM17.5 11.5C17.5 15.9183 13.9183 19.5 9.5 19.5V20.5C14.4706 20.5 18.5 16.4706 18.5 11.5H17.5ZM9.5 19.5C5.08172 19.5 1.5 15.9183 1.5 11.5H0.5C0.5 16.4706 4.52944 20.5 9.5 20.5V19.5ZM9.5 3.5C13.9183 3.5 17.5 7.08172 17.5 11.5H18.5C18.5 6.52944 14.4706 2.5 9.5 2.5V3.5ZM6.87049 3.94204C7.6934 3.65578 8.57798 3.5 9.5 3.5V2.5C8.46461 2.5 7.46905 2.67504 6.54193 2.99756L6.87049 3.94204Z'
      fill='#006AFF'
    />
  </svg>
);

export const IconCircleRevert = () => (
  <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='4.56592' cy='4.56592' r='4.06592' fill='#006AFF' />
    <circle cx='4.56592' cy='4.56592' r='4.06592' stroke='#006AFF' />
    <circle cx='4.56592' cy='4.56592' r='4.06592' stroke='#006AFF' />
    <circle cx='4.56592' cy='4.56592' r='4.06592' fill='#006AFF' />
    <circle cx='4.56592' cy='4.56592' r='4.06592' stroke='#006AFF' />
    <circle cx='4.56592' cy='4.56592' r='4.06592' stroke='#006AFF' />
  </svg>
);

export const ToolbarButton = ({ children, onClick, className, id, disabled }) => (
  <button
    id={id}
    className={`${
      disabled
        ? 'flex items-center justify-center w-10 h-10 gap-2 text-sm font-light text-gray-darkish hover:bg-gray-revell hover:cursor-not-allowed'
        : 'flex items-center justify-center w-10 h-10 gap-2 text-sm font-light hover:text-blue-bobyard hover:bg-gray-revell'
    } ${className}`}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);

export const MenuLineItem = ({ children, onClick, className, disabled, id }) => (
  <button
    disabled={disabled}
    onClick={onClick}
    onFocus={(e) => {
      if (e.relatedTarget) {
        // Revert focus back to previous blurring element
        e.relatedTarget.focus();
      } else {
        // No previous focus target, blur instead
        e.currentTarget.blur();
        // Alternatively: event.currentTarget.blur();
      }
    }}
    id={id}
    className={`flex flex-row w-full gap-2.5 p-2.5 text-sm font-light select-none ${
      disabled ? 'cursor-not-allowed text-gray-revell' : 'cursor-pointer'
    } hover:bg-blue-alice border-b border-gray-revell h-10 ${className}`}
  >
    {children}
  </button>
);

export const AdditionalControlsBox = ({ children }) => <div className='relative flex flex-row items-center h-full gap-2 bg-inherit shrink-0'>{children}</div>;

export const EstimateNumericField = ({ onEnter, onBlur, onClick, value, className, placeholder, additionalControls, innerRef }) => (
  <div className='flex flex-row w-full h-full'>
    <NumericField
      ref={innerRef}
      onEnter={onEnter}
      onBlur={onBlur}
      onClick={onClick}
      value={value}
      placeholder={placeholder}
      className={`h-full pl-1 w-full border border-white border-solid bg-white outline-none cursor-text focus:!bg-blue-alice focus:!border-blue-bobyard hover:!border-blue-bobyard rounded-sm  focus:border ${className}`}
    />
    <AdditionalControlsBox>{additionalControls}</AdditionalControlsBox>
  </div>
);

export const EstimateOverridableNumericField = ({ onEnter, onBlur, onClick, value, className, placeholder, additionalControls, innerRef }) => {
  const [isHoveringButton, setIsHoveringButton] = useState(false);
  const tooltipID = useId();

  return (
    <div
      className={`flex flex-row h-full pl-1 w-full border border-white border-solid bg-white outline-none cursor-text has-[:focus]:!bg-blue-alice has-[:focus]:!border-blue-bobyard hover:!border-blue-bobyard rounded-sm ${className}`}
    >
      <NumericField
        ref={innerRef}
        onEnter={onEnter}
        onBlur={onBlur}
        onClick={onClick}
        value={value}
        placeholder={placeholder}
        className={`h-full bg-inherit min-w-0 pl-1 grow outline-none border-none cursor-text`}
      />
      <button
        disabled={isNil(value)}
        className='flex flex-row items-center justify-center w-6'
        onMouseEnter={() => setIsHoveringButton(true)}
        onMouseLeave={() => setIsHoveringButton(false)}
        onClick={() => onBlur(null)}
      >
        {isNil(value) ? (
          <EmptyCircleIcon />
        ) : (
          <a className='content' data-tooltip-id={tooltipID}>
            {isHoveringButton ? <IconCircleRevertHover /> : <IconCircleRevert />}
          </a>
        )}
      </button>
      <Tooltip id={tooltipID}>Revert to default value</Tooltip>
      <AdditionalControlsBox>{additionalControls}</AdditionalControlsBox>
    </div>
  );
};

export const EstimateHintedNumericField = ({ onBlur, onEnter, onClick, value, className, placeholder, children, additionalControls, innerRef }) => {
  const inputRef = createRef();
  if (typeof innerRef == 'object') innerRef.current = inputRef.current;
  return (
    <div
      className={`flex flex-row h-full pl-1 w-full border border-white border-solid bg-white outline-none cursor-text has-[:focus]:!bg-blue-alice has-[:focus]:!border-blue-bobyard hover:!border-blue-bobyard rounded-sm ${className}`}
    >
      <div className='h-full cursor-pointer' onClick={() => inputRef.current.focus()}>
        {children}
      </div>
      <NumericField
        ref={inputRef}
        onEnter={onEnter}
        onBlur={onBlur}
        onClick={onClick}
        value={value}
        placeholder={placeholder}
        className={`h-full bg-inherit min-w-0 pl-1 grow outline-none border-none cursor-text`}
      />
      <AdditionalControlsBox>{additionalControls}</AdditionalControlsBox>
    </div>
  );
};

export const EstimateTextField = ({ onBlur, onEnter, onClick, value, className, placeholder, additionalControls, innerRef }) => (
  <div className='flex flex-row w-full h-full'>
    <TextField
      ref={innerRef}
      onEnter={onEnter}
      onBlur={onBlur}
      onClick={onClick}
      value={value}
      placeholder={placeholder}
      className={`h-full pl-1 w-full border border-white border-solid bg-white outline-none cursor-text focus:!bg-blue-alice focus:!border-blue-bobyard hover:!border-blue-bobyard rounded-sm ${className}`}
    />
    <AdditionalControlsBox>{additionalControls}</AdditionalControlsBox>
  </div>
);

export const EstimateHintedTextField = ({ onBlur, onEnter, onClick, value, className, placeholder, children, additionalControls, innerRef }) => {
  const inputRef = createRef();
  if (typeof innerRef == 'object') innerRef.current = inputRef.current;
  return (
    <div
      className={`flex flex-row h-full pl-1 w-full border border-white border-solid bg-white outline-none cursor-text has-[:focus]:!bg-blue-alice has-[:focus]:!border-blue-bobyard hover:!border-blue-bobyard rounded-sm ${className}`}
    >
      <div className='h-full cursor-pointer' onClick={() => inputRef.current.focus()}>
        {children}
      </div>
      <TextField
        ref={inputRef}
        onEnter={onEnter}
        onBlur={onBlur}
        onClick={onClick}
        value={value}
        placeholder={placeholder}
        className={`h-full bg-inherit min-w-0 pl-1 grow outline-none border-none cursor-text text-xs ${className}`}
      />
      <AdditionalControlsBox>{additionalControls}</AdditionalControlsBox>
    </div>
  );
};

export const EstimateSelectField = ({ onBlur, value, className, children, additionalControls, innerRef }) => (
  <div className='flex flex-row w-full h-full'>
    <SelectField
      ref={innerRef}
      onBlur={onBlur}
      value={value}
      className={`h-full pl-1 w-full border-white border-solid bg-white outline-none focus:!bg-blue-alice focus:!border-blue-bobyard hover:!border-blue-bobyard rounded-sm ${className}`}
    >
      {children}
    </SelectField>
    <AdditionalControlsBox>{additionalControls}</AdditionalControlsBox>
  </div>
);

export const EstimateCheckboxField = ({ onBlur = () => {}, onClick, value, className, disabled }) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    isNil(value) || value == false ? setIsChecked(false) : setIsChecked(true);
  }, [value]);

  return (
    <button
      onClick={() => {
        if (disabled) return;
        onClick ? onClick(!isChecked) : onBlur(!isChecked);
      }}
      className={className}
      disabled={disabled}
    >
      {!disabled && (isChecked ? <IconSquareCheckFilled size={20} /> : <IconSquare size={20} />)}
      {disabled && <IconSquareOff size={20} style={{ color: 'silver' }} />}
    </button>
  );
};

export const EstimateLabeledCheckboxField = ({ className, onBlur = () => {}, onClick, value, disabled, children }) => (
  <div className={`overflow-hidden flex flex-row ${className}`}>
    <div className='min-w-0 overflow-hidden grow text-ellipsis'>{children}</div>
    <EstimateCheckboxField onBlur={onBlur} onClick={onClick} value={value} disabled={disabled} className='shrink-0' />
  </div>
);

export const EstimateTextElement = ({ value, className, onClick, additionalControls }) => (
  <div className='flex flex-row w-full h-full'>
    <TextElement value={value} className={`w-full h-full p-2 text-xs ${className}`} onClick={onClick} />
    <AdditionalControlsBox>{additionalControls}</AdditionalControlsBox>
  </div>
);

export const EstimateNumericElement = ({ value, className, onClick, additionalControls }) => (
  <div className='flex flex-row w-full h-full'>
    <NumericElement value={value} className={`w-full h-full p-2 text-xs ${className}`} onClick={onClick} />
    <AdditionalControlsBox>{additionalControls}</AdditionalControlsBox>
  </div>
);

export const EstimateInapplicableElement = ({ className, onClick, additionalControls }) => (
  <div className='flex flex-row w-full h-full'>
    <InapplicableElement className={`p-2 w-full h-full text-xs ${className}`} onClick={onClick} />
    <AdditionalControlsBox>{additionalControls}</AdditionalControlsBox>
  </div>
);

export const EstimateContextMenuItem = ({ children, onClick = () => {}, className }) => (
  <Item onClick={onClick}>
    <div className={`flex flex-row items-center justify-start w-full gap-1 p-1 rounded cursor-pointer text-gray-darkish hover:bg-blue-alice hover:text-blue-bobyard ${className}`}>{children}</div>
  </Item>
);

export const NestedMenu = ({ getChildren = () => {}, getIsCurrent = () => {}, getItemElement = () => {}, handleAction = () => {}, nodeID }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isCurrent = getIsCurrent(nodeID);
  const children = isExpanded ? getChildren(nodeID) : [];
  return (
    <div className={`flex flex-col p-1 border border-solid rounded border-gray-revell ${isCurrent ? 'bg-blue-alice' : 'bg-white'}`} onClick={(e) => e.stopPropagation()}>
      <div className='flex flex-row items-center justify-between gap-2 text-sm font-light' onClick={() => setIsExpanded(!isExpanded)}>
        {isExpanded ? <IconChevronDown size={20} /> : <IconChevronRight size={20} />}
        {getItemElement(nodeID)}
        {isCurrent ? (
          <span className='py-0.5 px-2.5'>Current</span>
        ) : (
          <button onClick={() => handleAction(nodeID)} className='border border-solid border-gray-revell rounded py-0.5 px-2.5 hover:bg-gray-whitesmoke hover:!border-gray-darkish'>
            Select
          </button>
        )}
      </div>
      <div className={isExpanded && children.length ? 'mt-2' : ''}>
        {isExpanded &&
          children.map((childID) => <NestedMenu key={childID} getChildren={getChildren} getIsCurrent={getIsCurrent} getItemElement={getItemElement} handleAction={handleAction} nodeID={childID} />)}
      </div>
    </div>
  );
};

export const CellTriangle = ({ onClick, width, className }) => (
  <div
    size={20}
    className={className}
    style={{
      width: `${width}px`,
      aspectRatio: 1,
      clipPath: 'polygon(0 0,100% 0,100% 100%)',
      background: 'linear-gradient(45deg,#FFAA1D,#FFAA1D)',
    }}
    onClick={onClick}
  />
);

export const IconCheckSquareBroken = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.5 9.16667L10 11.6667L18.3333 3.33333M13.3333 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V10'
      stroke='#151515'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const IconClockRewind = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.99984 3.33329C6.31794 3.33329 3.33317 6.31806 3.33317 9.99996C3.33317 13.6819 6.31794 16.6666 9.99984 16.6666C13.1592 16.6666 15.8052 14.469 16.4927 11.519L16.1723 11.8393C15.8468 12.1647 15.3192 12.1646 14.9938 11.8391C14.6684 11.5136 14.6685 10.986 14.994 10.6606L16.6611 8.99395C16.8174 8.83769 17.0294 8.74993 17.2504 8.74996C17.4714 8.74999 17.6834 8.83782 17.8396 8.99412L19.5058 10.6608C19.8312 10.9863 19.8311 11.5139 19.5056 11.8393C19.1801 12.1647 18.6525 12.1646 18.3271 11.8391L18.1645 11.6764C17.3886 15.4753 14.028 18.3333 9.99984 18.3333C5.39746 18.3333 1.6665 14.6023 1.6665 9.99996C1.6665 5.39759 5.39746 1.66663 9.99984 1.66663C13.0582 1.66663 15.7307 3.31462 17.1794 5.76702C17.4135 6.16329 17.282 6.67428 16.8858 6.90837C16.4895 7.14245 15.9785 7.01097 15.7444 6.61471C14.5832 4.64886 12.4447 3.33329 9.99984 3.33329Z'
      fill='black'
    />
    <path
      d='M10.8332 5.83329C10.8332 5.37306 10.4601 4.99996 9.99984 4.99996C9.5396 4.99996 9.1665 5.37306 9.1665 5.83329V9.99996C9.1665 10.2786 9.30576 10.5388 9.53759 10.6933L12.0376 12.36C12.4205 12.6153 12.9379 12.5118 13.1932 12.1289C13.4485 11.7459 13.345 11.2285 12.9621 10.9733L10.8332 9.55397V5.83329Z'
      fill='black'
    />
  </svg>
);

export const IconColumnEdit = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.5 2.5V17.5M12.5 2.5V17.5M6.5 2.5H13.5C14.9001 2.5 15.6002 2.5 16.135 2.77248C16.6054 3.01217 16.9878 3.39462 17.2275 3.86502C17.5 4.3998 17.5 5.09987 17.5 6.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V6.5C2.5 5.09987 2.5 4.3998 2.77248 3.86502C3.01217 3.39462 3.39462 3.01217 3.86502 2.77248C4.3998 2.5 5.09987 2.5 6.5 2.5Z'
      stroke='#151515'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <circle cx='15' cy='15' r='5' fill='white' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.1615 12.3154C16.5821 11.8949 17.264 11.8949 17.6846 12.3154C18.1051 12.736 18.1051 13.418 17.6846 13.8385L13.9694 17.5537C13.9647 17.5584 13.9601 17.5631 13.9554 17.5677C13.8865 17.6367 13.8258 17.6976 13.7535 17.7452C13.6901 17.787 13.6212 17.8201 13.5489 17.8436C13.4666 17.8702 13.3811 17.8796 13.2842 17.8903C13.2777 17.891 13.2711 17.8918 13.2645 17.8925L12.3124 17.9983C12.2274 18.0077 12.1428 17.978 12.0824 17.9176C12.022 17.8572 11.9923 17.7726 12.0017 17.6876L12.1075 16.7355C12.1082 16.7289 12.109 16.7223 12.1097 16.7158C12.1204 16.6189 12.1298 16.5334 12.1564 16.4511C12.1799 16.3788 12.213 16.3099 12.2548 16.2465C12.3024 16.1742 12.3633 16.1135 12.4323 16.0446C12.4369 16.0399 12.4416 16.0353 12.4463 16.0306L16.1615 12.3154Z'
      fill='black'
    />
  </svg>
);

export const IconZoomIn = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.5 17.5L13.875 13.875M9.16667 6.66667V11.6667M6.66667 9.16667H11.6667M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z'
      stroke='#898990'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const IconPackagePlus = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.0833 6.06478L9.99997 9.99996M9.99997 9.99996L2.91664 6.06478M9.99997 9.99996L10 17.9167M11.6667 17.4074L10.6475 17.9736C10.4112 18.1049 10.293 18.1705 10.1679 18.1963C10.0571 18.2191 9.94288 18.2191 9.83213 18.1963C9.70698 18.1705 9.58881 18.1049 9.35248 17.9736L3.18581 14.5477C2.93621 14.409 2.8114 14.3397 2.72053 14.241C2.64013 14.1538 2.57929 14.0504 2.54207 13.9378C2.5 13.8104 2.5 13.6677 2.5 13.3821V6.61786C2.5 6.33233 2.5 6.18956 2.54207 6.06223C2.57929 5.94958 2.64013 5.84618 2.72053 5.75894C2.8114 5.66032 2.93621 5.59099 3.18581 5.45232L9.35248 2.02639C9.58881 1.8951 9.70698 1.82944 9.83213 1.80371C9.94288 1.78093 10.0571 1.78093 10.1679 1.80371C10.293 1.82944 10.4112 1.89509 10.6475 2.02639L16.8142 5.45232C17.0638 5.59099 17.1886 5.66032 17.2795 5.75893C17.3599 5.84618 17.4207 5.94958 17.4579 6.06222C17.5 6.18956 17.5 6.33232 17.5 6.61786L17.5 10.4167M6.25 3.74999L13.75 7.91665M15.8333 17.5V12.5M13.3333 15H18.3333'
      stroke='black'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const IconSearchSm = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.33317 1.66663C4.65127 1.66663 1.6665 4.65139 1.6665 8.33329C1.6665 12.0152 4.65127 15 8.33317 15C9.87376 15 11.2923 14.4774 12.4212 13.5998L16.9106 18.0892C17.236 18.4147 17.7637 18.4147 18.0891 18.0892C18.4145 17.7638 18.4145 17.2361 18.0891 16.9107L13.5997 12.4213C14.4773 11.2924 14.9998 9.87389 14.9998 8.33329C14.9998 4.65139 12.0151 1.66663 8.33317 1.66663ZM3.33317 8.33329C3.33317 5.57187 5.57175 3.33329 8.33317 3.33329C11.0946 3.33329 13.3332 5.57187 13.3332 8.33329C13.3332 11.0947 11.0946 13.3333 8.33317 13.3333C5.57175 13.3333 3.33317 11.0947 3.33317 8.33329Z'
      fill='black'
    />
  </svg>
);

export const IconPlus = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.8335 4.16671C10.8335 3.70647 10.4604 3.33337 10.0002 3.33337C9.53993 3.33337 9.16683 3.70647 9.16683 4.16671V9.16671H4.16683C3.70659 9.16671 3.3335 9.5398 3.3335 10C3.3335 10.4603 3.70659 10.8334 4.16683 10.8334H9.16683V15.8334C9.16683 16.2936 9.53993 16.6667 10.0002 16.6667C10.4604 16.6667 10.8335 16.2936 10.8335 15.8334V10.8334H15.8335C16.2937 10.8334 16.6668 10.4603 16.6668 10C16.6668 9.5398 16.2937 9.16671 15.8335 9.16671H10.8335V4.16671Z'
      fill='black'
    />
  </svg>
);

export const IconMessagePlusSquare = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10 11.25V6.25M7.5 8.75H12.5M5.83333 15V16.9463C5.83333 17.3903 5.83333 17.6123 5.92436 17.7263C6.00352 17.8255 6.12356 17.8832 6.25045 17.8831C6.39636 17.8829 6.56973 17.7442 6.91646 17.4668L8.90434 15.8765C9.31043 15.5517 9.51347 15.3892 9.73957 15.2737C9.94017 15.1712 10.1537 15.0963 10.3743 15.051C10.6231 15 10.8831 15 11.4031 15H13.5C14.9001 15 15.6002 15 16.135 14.7275C16.6054 14.4878 16.9878 14.1054 17.2275 13.635C17.5 13.1002 17.5 12.4001 17.5 11V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V11.6667C2.5 12.4416 2.5 12.8291 2.58519 13.147C2.81635 14.0098 3.49022 14.6836 4.35295 14.9148C4.67087 15 5.05836 15 5.83333 15Z'
      stroke='#151515'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const IconContract = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.5303 3.03033C17.8232 2.73744 17.8232 2.26256 17.5303 1.96967C17.2374 1.67678 16.7626 1.67678 16.4697 1.96967L17.5303 3.03033ZM16.4697 1.96967L11.9697 6.46967L13.0303 7.53033L17.5303 3.03033L16.4697 1.96967Z'
      fill='#151515'
    />
    <path d='M17 7L12.6 7C12.5448 7 12.5 6.95523 12.5 6.9L12.5 2.5' stroke='#151515' strokeWidth='1.5' strokeLinecap='round' />
    <path
      d='M2.53033 1.96967C2.23744 1.67678 1.76256 1.67678 1.46967 1.96967C1.17678 2.26256 1.17678 2.73744 1.46967 3.03033L2.53033 1.96967ZM1.46967 3.03033L5.96967 7.53033L7.03033 6.46967L2.53033 1.96967L1.46967 3.03033Z'
      fill='#151515'
    />
    <path d='M6.5 2.5L6.5 6.9C6.5 6.95523 6.45523 7 6.4 7L2 7' stroke='#151515' strokeWidth='1.5' strokeLinecap='round' />
    <path
      d='M1.46967 16.9697C1.17678 17.2626 1.17678 17.7374 1.46967 18.0303C1.76256 18.3232 2.23744 18.3232 2.53033 18.0303L1.46967 16.9697ZM2.53033 18.0303L7.03033 13.5303L5.96967 12.4697L1.46967 16.9697L2.53033 18.0303Z'
      fill='#151515'
    />
    <path d='M2 13L6.4 13C6.45523 13 6.5 13.0448 6.5 13.1L6.5 17.5' stroke='#151515' strokeWidth='1.5' strokeLinecap='round' />
    <path
      d='M16.4697 18.0303C16.7626 18.3232 17.2374 18.3232 17.5303 18.0303C17.8232 17.7374 17.8232 17.2626 17.5303 16.9697L16.4697 18.0303ZM17.5303 16.9697L13.0303 12.4697L11.9697 13.5303L16.4697 18.0303L17.5303 16.9697Z'
      fill='#151515'
    />
    <path d='M12.5 17.5L12.5 13.1C12.5 13.0448 12.5448 13 12.6 13L17 13' stroke='#151515' strokeWidth='1.5' strokeLinecap='round' />
  </svg>
);

export const IconFolderPlus = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.8332 5.83333L9.90355 3.9741C9.63601 3.439 9.50222 3.17144 9.30265 2.97597C9.12615 2.80311 8.91344 2.67164 8.6799 2.59109C8.41581 2.5 8.11668 2.5 7.51841 2.5H4.33317C3.39975 2.5 2.93304 2.5 2.57652 2.68166C2.26292 2.84144 2.00795 3.09641 1.84816 3.41002C1.6665 3.76654 1.6665 4.23325 1.6665 5.16667V5.83333M1.6665 5.83333H14.3332C15.7333 5.83333 16.4334 5.83333 16.9681 6.10582C17.4386 6.3455 17.821 6.72795 18.0607 7.19836C18.3332 7.73314 18.3332 8.4332 18.3332 9.83333V13.5C18.3332 14.9001 18.3332 15.6002 18.0607 16.135C17.821 16.6054 17.4386 16.9878 16.9681 17.2275C16.4334 17.5 15.7333 17.5 14.3332 17.5H5.6665C4.26637 17.5 3.56631 17.5 3.03153 17.2275C2.56112 16.9878 2.17867 16.6054 1.93899 16.135C1.6665 15.6002 1.6665 14.9001 1.6665 13.5V5.83333ZM9.99984 14.1667V9.16667M7.49984 11.6667H12.4998'
      stroke='#151515'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const IconFileSearch = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.6668 9.16663H6.66683M8.3335 12.5H6.66683M13.3335 5.83329H6.66683M16.6668 8.74996V5.66663C16.6668 4.26649 16.6668 3.56643 16.3943 3.03165C16.1547 2.56124 15.7722 2.17879 15.3018 1.93911C14.767 1.66663 14.067 1.66663 12.6668 1.66663H7.3335C5.93336 1.66663 5.2333 1.66663 4.69852 1.93911C4.22811 2.17879 3.84566 2.56124 3.60598 3.03165C3.3335 3.56643 3.3335 4.26649 3.3335 5.66663V14.3333C3.3335 15.7334 3.3335 16.4335 3.60598 16.9683C3.84566 17.4387 4.22811 17.8211 4.69852 18.0608C5.2333 18.3333 5.93336 18.3333 7.3335 18.3333H9.5835M18.3335 18.3333L17.0835 17.0833M17.9168 15C17.9168 16.6108 16.611 17.9166 15.0002 17.9166C13.3893 17.9166 12.0835 16.6108 12.0835 15C12.0835 13.3891 13.3893 12.0833 15.0002 12.0833C16.611 12.0833 17.9168 13.3891 17.9168 15Z'
      stroke='#151515'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const IconExpand = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.3333 6.66667L17.5 2.5M17.5 2.5H13.3333M17.5 2.5V6.66667M6.66667 6.66667L2.5 2.5M2.5 2.5L2.5 6.66667M2.5 2.5L6.66667 2.5M6.66667 13.3333L2.5 17.5M2.5 17.5H6.66667M2.5 17.5L2.5 13.3333M13.3333 13.3333L17.5 17.5M17.5 17.5V13.3333M17.5 17.5H13.3333'
      stroke='#151515'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const IconDatabaseEdit = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.5 4.16663C17.5 5.54734 14.1421 6.66663 10 6.66663C5.85786 6.66663 2.5 5.54734 2.5 4.16663M17.5 4.16663C17.5 2.78591 14.1421 1.66663 10 1.66663C5.85786 1.66663 2.5 2.78591 2.5 4.16663M17.5 4.16663V15.8333C17.5 17.2166 14.1667 18.3333 10 18.3333C5.83333 18.3333 2.5 17.2166 2.5 15.8333V4.16663M17.5 9.99996C17.5 11.3833 14.1667 12.5 10 12.5C5.83333 12.5 2.5 11.3833 2.5 9.99996'
      stroke='#151515'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <circle cx='15' cy='15' r='5' fill='white' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.1615 12.3154C16.5821 11.8949 17.264 11.8949 17.6846 12.3154C18.1051 12.736 18.1051 13.418 17.6846 13.8385L13.9694 17.5537C13.9647 17.5584 13.9601 17.5631 13.9554 17.5677C13.8865 17.6367 13.8258 17.6976 13.7535 17.7452C13.6901 17.787 13.6212 17.8201 13.5489 17.8436C13.4666 17.8702 13.3811 17.8796 13.2842 17.8903C13.2777 17.891 13.2711 17.8918 13.2645 17.8925L12.3124 17.9983C12.2274 18.0077 12.1428 17.978 12.0824 17.9176C12.022 17.8572 11.9923 17.7726 12.0017 17.6876L12.1075 16.7355C12.1082 16.7289 12.109 16.7223 12.1097 16.7158C12.1204 16.6189 12.1298 16.5334 12.1564 16.4511C12.1799 16.3788 12.213 16.3099 12.2548 16.2465C12.3024 16.1742 12.3633 16.1135 12.4323 16.0446C12.4369 16.0399 12.4416 16.0353 12.4463 16.0306L16.1615 12.3154Z'
      fill='black'
    />
  </svg>
);

export const IconDatabasePlus = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.5 4.16663C17.5 5.54734 14.1421 6.66663 10 6.66663C5.85786 6.66663 2.5 5.54734 2.5 4.16663M17.5 4.16663C17.5 2.78591 14.1421 1.66663 10 1.66663C5.85786 1.66663 2.5 2.78591 2.5 4.16663M17.5 4.16663V15.8333C17.5 17.2166 14.1667 18.3333 10 18.3333C5.83333 18.3333 2.5 17.2166 2.5 15.8333V4.16663M17.5 9.99996C17.5 11.3833 14.1667 12.5 10 12.5C5.83333 12.5 2.5 11.3833 2.5 9.99996'
      stroke='#151515'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <circle cx='15' cy='15' r='5' fill='white' />
    <path d='M15 12V18M12 15H18' stroke='black' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
