import { useState } from "react";

export default function handleKeyBind({
    e,
    pageID,
    measurements,
    project,
    keybinds,
    shiftDown, setShiftDown,
    controlDown, setControlDown,
    optionDown, setOptionDown,
    tabDown, setTabDown,
    metaDown, setMetaDown,
    handleZoom, handleResetZoom, handleZoomToMeasurement,
    setShowMiniMap,
    setDrawingCount, setDrawingLine, setDrawingPolygon, setDrawingRectangle, setDrawingScale, setDrawingCircle,
    setAddingCount, setDeletingCount, setCuttingPolygon, setCuttingRectangle, setSplittingPolygon, setSplittingRectangle, setCuttingPolygonRect, setCuttingRectangleRect, setErasingCount,
    currentMeasurement, setCurrentMeasurement,
    takeoffSettings, setTakeoffSettings,
    handleChangeTakeoffSettings,
    handleUndo, handleRedo,
    changingHistory,
    stopWatch,
    setDrawingAICountRectangle, setHintingSAM,
    setAIAutoClassifying, setAIAutoFinding,
    setAIAutoCountRect, setAIAutoCountPoly,
    handleTurnOffDrawing,
    setDrawingAnnotationArrow, setDrawingAnnotationText, setDrawingAnnotationRectangle, setDrawingAnnotationRuler,
    setSelectionCalculator,
    PrevPage, NextPage,
}) {
    console.log(e);

    if (e.key === 'Shift') {
        e.preventDefault();
        e.stopPropagation();
        setShiftDown(true);
        return;
    }
    if (e.key === 'Meta' || e.key === 'Control') {
        e.preventDefault();
        e.stopPropagation();
        setControlDown(true);
        return;
    }
    if (e.key === 'Option' || e.key === 'Alt') {
        e.preventDefault();
        e.stopPropagation();
        setOptionDown(true);
        return;
    }
    if (e.key === 'Tab') {
        e.preventDefault();
        e.stopPropagation();
        setTabDown(true);
        return;
    }

    const checkMatch = (e, action) => {
        if (!keybinds || !keybinds[action]) return false;

        return ((e.key === keybinds[action].key
            && shiftDown === keybinds[action].shift
            && controlDown === keybinds[action].control
            && optionDown === keybinds[action].option
            && tabDown === keybinds[action].tab
        ) || (e.key === keybinds[action].alt_key
            && shiftDown === keybinds[action].alt_shift
            && controlDown === keybinds[action].alt_control
            && optionDown === keybinds[action].alt_option
            && tabDown === keybinds[action].alt_tab
            ));
    }

    // Zoom in
    if (checkMatch(e, 'ZoomIn')) {
        e.preventDefault();
        e.stopPropagation();

        handleZoom(1.2);
    }

    // Zoom out
    else if (checkMatch(e, 'ZoomOut')) {
        e.preventDefault();
        e.stopPropagation();

        handleZoom(0.8);
    }

    // Reset zoom
    else if (checkMatch(e, 'ZoomReset')) {
        e.preventDefault();
        e.stopPropagation();

        handleResetZoom();
    }

    // Zoom to measurement
    else if (checkMatch(e, 'ZoomToMeasurement')) {
        e.preventDefault();
        e.stopPropagation();

        handleZoomToMeasurement();
    }

    // toggle minimap
    else if (checkMatch(e, 'MinimapToggle')) {
        e.preventDefault();
        e.stopPropagation();

        setShowMiniMap((prev) => !prev);
    }

    // Drawing scale
    else if (checkMatch(e, 'DrawScale')) {
        e.preventDefault();
        e.stopPropagation();

        handleTurnOffDrawing();

        setCurrentMeasurement(null);

        setDrawingScale(prev => !prev);
    }

    // Drawing rectangle
    else if (checkMatch(e, 'DrawRectangle')) {
        e.preventDefault();
        e.stopPropagation();

        handleTurnOffDrawing();

        setCurrentMeasurement(null);

        setDrawingRectangle(prev => !prev);
    }

    //Drawing circle
    else if (checkMatch(e, 'DrawCircle')) {
        e.preventDefault();
        e.stopPropagation();

        handleTurnOffDrawing();

        setCurrentMeasurement(null);

        setDrawingCircle(prev => !prev);
    }

    // Drawing polygon
    else if (checkMatch(e, 'DrawPolygon')) {
        e.preventDefault();
        e.stopPropagation();

        handleTurnOffDrawing();
        setCurrentMeasurement(null);

        setDrawingPolygon(prev => !prev);
    }

    // Drawing length
    else if (checkMatch(e, 'DrawLength')) {
        e.preventDefault();
        e.stopPropagation();

        handleTurnOffDrawing();
        setCurrentMeasurement(null);

        setDrawingLine(prev => !prev);
    }

    // Drawing count
    else if (checkMatch(e, 'DrawCount')) {
        e.preventDefault();
        e.stopPropagation();

        handleTurnOffDrawing();
        setCurrentMeasurement(null);

        setDrawingCount(prev => !prev);
        stopWatch.reset();
    }

    //cut polygon
    else if (currentMeasurement && measurements[currentMeasurement].type === 'polygon' && checkMatch(e, 'CutPolygon')) {
        e.preventDefault();
        e.stopPropagation();

        handleTurnOffDrawing();
        setCuttingPolygon(prev => !prev);
    }

    //cut polygon rect
    else if (currentMeasurement && measurements[currentMeasurement].type === 'polygon' && checkMatch(e, 'CutPolygonRect')) {
        console.log('cut polygon rect');

        e.preventDefault();
        e.stopPropagation();

        handleTurnOffDrawing();
        setCuttingPolygonRect(prev => !prev);
    }

    //split polygon
    else if (currentMeasurement && measurements[currentMeasurement].type === 'polygon' && checkMatch(e, 'SplitPolygon')) {
        e.preventDefault();
        e.stopPropagation();

        handleTurnOffDrawing();
        setSplittingPolygon(prev => !prev);
    }

    //cut rectangle
    else if (currentMeasurement && measurements[currentMeasurement].type === 'rectangle' && checkMatch(e, 'CutRectangle')) {
        e.preventDefault();
        e.stopPropagation();

        handleTurnOffDrawing();
        setCuttingRectangle(prev => !prev);
    }

    //cut rectangle rect
    else if (currentMeasurement && measurements[currentMeasurement].type === 'rectangle' && checkMatch(e, 'CutRectangleRect')) {
        e.preventDefault();
        e.stopPropagation();

        handleTurnOffDrawing();
        setCuttingRectangleRect(prev => !prev);
    }

    //split rectangle
    else if (currentMeasurement && measurements[currentMeasurement].type === 'rectangle' && checkMatch(e, 'SplitRectangle')) {
        e.preventDefault();
        e.stopPropagation();

        handleTurnOffDrawing();
        setSplittingRectangle(prev => !prev);
    }

    // Adding count
    else if (currentMeasurement && measurements[currentMeasurement].type === 'count' && checkMatch(e, 'AddCount')) {
        e.preventDefault();
        e.stopPropagation();

        handleTurnOffDrawing();
        setAddingCount(prev => !prev);
    }

    // Deleting count
    else if (currentMeasurement && measurements[currentMeasurement].type === 'count' && checkMatch(e, 'DeleteCount')) {
        e.preventDefault();
        e.stopPropagation();

        handleTurnOffDrawing();
        setDeletingCount(prev => !prev);
    }

    // Erasing count
    else if (checkMatch(e, 'EraseCount')) {
        e.preventDefault();
        e.stopPropagation();

        handleTurnOffDrawing();
        setErasingCount(prev => !prev);
    }

    // Draw annotation text
    else if (checkMatch(e, 'DrawAnnotationText')) {
        e.preventDefault();
        e.stopPropagation();

        handleTurnOffDrawing();
        setDrawingAnnotationText(prev => !prev);
    }

    // Draw annotation arrow
    else if (checkMatch(e, 'DrawAnnotationArrow')) {
        e.preventDefault();
        e.stopPropagation();

        handleTurnOffDrawing();
        setDrawingAnnotationArrow(prev => !prev);
    }

    // Draw annotation rectangle
    else if (checkMatch(e, 'DrawAnnotationRectangle')) {
        e.preventDefault();
        e.stopPropagation();

        handleTurnOffDrawing();
        setDrawingAnnotationRectangle(prev => !prev);
    }

    // Draw annotation ruler
    else if (checkMatch(e, 'DrawAnnotationRuler')) {
        e.preventDefault();
        e.stopPropagation();

        handleTurnOffDrawing();
        setDrawingAnnotationRuler(prev => !prev);
    }

    // selection calculator
    else if (checkMatch(e, 'SelectionCalculator')) {
        e.preventDefault();
        e.stopPropagation();

        handleTurnOffDrawing();
        setSelectionCalculator(prev => !prev);
    }

    // toggle sidebar
    else if (checkMatch(e, 'ToggleMeasurementSidebar')) {
        e.preventDefault();
        e.stopPropagation();

        handleChangeTakeoffSettings({
            ...takeoffSettings,
            show_measurement_sidebar: !takeoffSettings?.show_measurement_sidebar,
            show_pages_sidebar: takeoffSettings?.show_measurement_sidebar
                ? takeoffSettings?.show_pages_sidebar
                : takeoffSettings?.pages_sidebar_location === takeoffSettings?.measurement_sidebar_location
                    ? false : takeoffSettings?.show_pages_sidebar,
            show_ai_sidebar: takeoffSettings?.show_measurement_sidebar
                ? takeoffSettings?.show_ai_sidebar
                : takeoffSettings?.ai_sidebar_location === takeoffSettings?.measurement_sidebar_location
                    ? false : takeoffSettings?.show_ai_sidebar,
        })
    }

    // toggle pages sidebar
    else if (checkMatch(e, 'TogglePagesSidebar')) {
        e.preventDefault();
        e.stopPropagation();

        handleChangeTakeoffSettings({
            ...takeoffSettings,
            show_pages_sidebar: !takeoffSettings?.show_pages_sidebar,
            show_ai_sidebar: takeoffSettings?.show_pages_sidebar
                ? takeoffSettings?.show_ai_sidebar
                : takeoffSettings?.ai_sidebar_location === takeoffSettings?.pages_sidebar_location
                    ? false : takeoffSettings?.show_ai_sidebar,
            show_measurement_sidebar: takeoffSettings?.show_pages_sidebar
                ? takeoffSettings?.show_measurement_sidebar
                : takeoffSettings?.measurement_sidebar_location === takeoffSettings?.pages_sidebar_location
                    ? false : takeoffSettings?.show_measurement_sidebar,
        })
    }

    // toggle AI sidebar
    else if (checkMatch(e, 'ToggleAISidebar')) {
        e.preventDefault();
        e.stopPropagation();

        handleChangeTakeoffSettings({
            ...takeoffSettings,
            show_ai_sidebar: !takeoffSettings?.show_ai_sidebar,
            show_pages_sidebar: takeoffSettings?.show_ai_sidebar
                ? takeoffSettings?.show_pages_sidebar
                : takeoffSettings?.pages_sidebar_location === takeoffSettings?.ai_sidebar_location
                    ? false : takeoffSettings?.show_pages_sidebar,
            show_measurement_sidebar: takeoffSettings?.show_ai_sidebar
                ? takeoffSettings?.show_measurement_sidebar
                : takeoffSettings?.measurement_sidebar_location === takeoffSettings?.ai_sidebar_location
                    ? false : takeoffSettings?.show_measurement_sidebar,
        })
    }

    // search measurements
    else if (checkMatch(e, 'SearchMeasurement')) {
        e.preventDefault();
        e.stopPropagation();

        handleChangeTakeoffSettings({
            ...takeoffSettings,
            show_measurement_sidebar: true,
            show_pages_sidebar: takeoffSettings?.pages_sidebar_location === takeoffSettings?.measurement_sidebar_location
                ? false : takeoffSettings?.show_pages_sidebar,
            show_ai_sidebar: takeoffSettings?.ai_sidebar_location === takeoffSettings?.measurement_sidebar_location
                ? false : takeoffSettings?.show_ai_sidebar,
        });

        setTimeout(() => {
            if (document.getElementById("search-measurements-input")) {
                const SearchMeasurementInput = document.getElementById("search-measurements-input");
                SearchMeasurementInput.focus();
            } else if (document.getElementById("measurementsidebar-header-button-search")) {
                const SearchMeasurement = document.getElementById("measurementsidebar-header-button-search");
                SearchMeasurement.click();

                setTimeout(() => {
                    if (document.getElementById("search-measurements-input")) {
                        const SearchMeasurementInput = document.getElementById("search-measurements-input");
                        SearchMeasurementInput.focus();
                    }
                }, 100);
            }
        }, 100);
    }

    // search pages
    else if (checkMatch(e, 'SearchPage')) {
        e.preventDefault();
        e.stopPropagation();

        handleChangeTakeoffSettings({
            ...takeoffSettings,
            show_pages_sidebar: true,
            show_measurement_sidebar: takeoffSettings?.measurement_sidebar_location === takeoffSettings?.pages_sidebar_location
                ? false : takeoffSettings?.show_measurement_sidebar,
            show_ai_sidebar: takeoffSettings?.ai_sidebar_location === takeoffSettings?.pages_sidebar_location
                ? false : takeoffSettings?.show_ai_sidebar,
        })

        setTimeout(() => {
            if (document.getElementById("search-pages-input")) {
                const SearchMeasurementInput = document.getElementById("search-pages-input");
                SearchMeasurementInput.focus();
            } else if (document.getElementById("pagessidebar-search-button")) {
                const SearchMeasurement = document.getElementById("pagessidebar-search-button");
                SearchMeasurement.click();

                setTimeout(() => {
                    if (document.getElementById("search-pages-input")) {
                        const SearchMeasurementInput = document.getElementById("search-pages-input");
                        SearchMeasurementInput.focus();
                    }
                }, 100);
            }
        }, 100);
    }

    // prev page
    else if (checkMatch(e, 'PreviousPage')) {
        e.preventDefault();
        e.stopPropagation();

        PrevPage();
    }

    // next page
    else if (checkMatch(e, 'NextPage')) {
        e.preventDefault();
        e.stopPropagation();

        NextPage();
    }

    else if (checkMatch(e, 'Undo') && !changingHistory) {
        e.preventDefault();
        e.stopPropagation();

        handleUndo();
    }

    else if (checkMatch(e, 'Redo') && !changingHistory) {
        e.preventDefault();
        e.stopPropagation();

        handleRedo();
    }
}