import { useSelector } from 'react-redux';
import { selectAuth } from '../redux/slices/authSlice';
import { useMemo, useState, useEffect } from 'react';
import {
    axiosCurry, formDataFromObj,
    mergeFromLens,
    repsonseLens,
    responseDataLens, reverseView,
    reverseSet,
    reverseOver,
    reverseProp,
    reverseIncludes,
    mapIndexed,
    reduceIndexed,
    getMimeType
} from '../utilities/utilities';
import { API_ROUTE, WEBSOCKET_ROUTE } from '..';
import {
    add,
    all,
    always, andThen,
    compose,
    concat,
    defaultTo,
    equals,
    filter,
    flatten,
    fromPairs,
    head,
    identity,
    ifElse,
    includes,
    indexOf,
    init,
    insert,
    isNil,
    keys,
    last,
    length,
    lensPath,
    lensProp,
    map,
    max,
    mergeDeepLeft,
    min,
    multiply,
    not,
    omit, otherwise,
    over,
    pick,
    pipe,
    prop,
    reduce,
    reject,
    reverse,
    set,
    slice,
    sortBy, tail,
    tap,
    toLower,
    view,
    zip,
    zipObj,
    replace
} from 'ramda';
import { Card, Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import DefaultButton from '../components/DefaultButton';
import { useParams } from 'react-router-dom';
import { closestCenter, DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { StyledRowContainer, StyledSortableRow, LargeTextElement, StyledTreeRow } from './Components';
import {
    IconArrowLeft,
    IconArrowRight,
    IconCircle,
    IconCircleFilled,
    IconCopy,
    IconCursorText,
    IconDeselect,
    IconDownload,
    IconEye, IconFolderPlus,
    IconMenu2, IconRobot,
    IconSearch,
    IconSelectAll,
    IconSortDescending, IconTrashX,
    IconUpload,
    IconX,
    IconZoomIn,
    IconZoomOut
} from '@tabler/icons-react';
import { Menu, useContextMenu } from 'react-contexify';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import { fullGetDndTreeItems, fullDFS, ProjectFileNodeTreeContext, getNewOrder, fullFindInTree, generateDummyID, getBlobURLFromURL, updateOrCreateToast } from './Utilities';
import ContextMenuItem from '../components/ContextMenuItem';
import DeleteButton from '../components/DeleteButton';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import TextField from '../components/TextField';

import FilesNavbar from './FilesNavbar';
import Popup from 'reactjs-popup';
import InfoIcon from '../components/InfoIcon';
import { Tooltip } from 'react-tooltip';
import useWebSocket from 'react-use-websocket';
import { ToastContainer } from 'react-toastify';
import { Blocks } from 'react-loader-spinner';
import ChatNavbar from '../chat/components/ChatNavbar';

const nameLens = lensProp('name');
const fileKeyLens = lensProp('file_key');
const fileURLLens = lensProp('file_url');
const projectLens = lensProp('project');
const createdLens = lensProp('created');
const ownerLens = lensProp('owner');
const indexLens = lensProp('index');
const groupLens = lensProp('group');

const getTakeoffFileLens = (id) => compose(takeoffFilesLens, lensProp(id));
const getTakeoffFileNameLens = (id) => compose(getTakeoffFileLens(id), nameLens);
const getTakeoffFileFileKeyLens = (id) => compose(getTakeoffFileLens(id), fileKeyLens);
const getTakeoffFileFileURLLens = (id) => compose(getTakeoffFileLens(id), fileURLLens);
const getTakeoffFileCreatedLens = (id) => compose(getTakeoffFileLens(id), createdLens);
const getTakeoffFileOwnerLens = (id) => compose(getTakeoffFileLens(id), ownerLens);
const getTakeoffFileIndexLens = (id) => compose(getTakeoffFileLens(id), indexLens);
const getTakeoffFilePagesLens = (id) => compose(getTakeoffFileLens(id), lensProp('pages'));
const getTakeoffFileIsUploadCompleteLens = (id) => compose(getTakeoffFileLens(id), lensProp('is_upload_complete'));

const getPageLens = (id) => compose(pagesLens, lensProp(id));
const getPageFileKeyLens = (id) => compose(getPageLens(id), fileKeyLens);
const getPageFileURLLens = (id) => compose(getPageLens(id), fileURLLens);
const getPageIndexLens = (id) => compose(getPageLens(id), indexLens);
const getPageTitleLens = (id) => compose(getPageLens(id), lensProp('title'));
const getPageAIPreppedLens = (id) => compose(getPageLens(id), lensProp('ai_prepped'));
const getPageCreatedLens = (id) => compose(getPageLens(id), lensProp('date_created'));
const getPageThumbnailURLLens = (id) => compose(getPageLens(id), lensProp('thumbnail_url'));
const getPageIsIncludedLens = (id) => compose(getPageLens(id), lensProp('is_included'));
const getPageParentFileLens = (id) => compose(getPageLens(id), lensProp('parent_file'));
const getPagePageNumberLens = (id) => compose(getPageLens(id), lensProp('page_number'));
const getPageMeasurementsCountLens = (id) => compose(getPageLens(id), lensProp('measurements_count'));
const getPageMeasurementsLens = (id) => compose(pageMeasurementsLens, lensProp(id));

const getProjectFileNodeLens = (id) => compose(projectFileNodesLens, lensProp(id));
const getProjectFileNodeFileKeyLens = (id) => compose(getProjectFileNodeLens(id), fileKeyLens);
const getProjectFileNodeFileURLLens = (id) => compose(getProjectFileNodeLens(id), fileURLLens);
const getProjectFileNodeNameLens = (id) => compose(getProjectFileNodeLens(id), nameLens);
const getProjectFileNodeIndexLens = (id) => compose(getProjectFileNodeLens(id), indexLens);
const getProjectFileNodeChildrenLens = (id) => compose(getProjectFileNodeLens(id), lensProp('children'));
const getProjectFileNodeIsFolderLens = (id) => compose(getProjectFileNodeLens(id), lensProp('is_folder'));
const getProjectFileNodeParentLens = (id) => compose(getProjectFileNodeLens(id), lensProp('parent'));
const getProjectFileNodeCollapsedLens = (id) => compose(lensProp('collapsed'), lensProp(id));

const getExportFileLens = (id) => compose(exportFilesLens, lensProp(id));
const getExportFileNameLens = (id) => compose(getExportFileLens(id), nameLens);
const getExportFileFileKeyLens = (id) => compose(getExportFileLens(id), fileKeyLens);
const getExportFileFileURLLens = (id) => compose(getExportFileLens(id), fileURLLens);
const getExportFileCreatedLens = (id) => compose(getExportFileLens(id), createdLens);
const getExportFileOwnerLens = (id) => compose(getExportFileLens(id), ownerLens);
const getExportFileIndexLens = (id) => compose(getExportFileLens(id), indexLens);

const getProjectFileNodeUploadLens = (id) => compose(uploadProjectFileNodesLens, lensProp(id));
const getProjectFileNodeUploadProgressLens = (id) => compose(getProjectFileNodeUploadLens(id), lensProp('progress'));
const getProjectFileNodeUploadNameLens = (id) => compose(getProjectFileNodeUploadLens(id), lensProp('name'));

const getTakeoffFileUploadLens = (id) => compose(uploadTakeoffFilesLens, lensProp(id));
const getTakeoffFileUploadProgressLens = (id) => compose(getTakeoffFileUploadLens(id), lensProp('upload'));
const getTakeoffProcessingProgressLens = (id) => compose(getTakeoffFileUploadLens(id), lensProp('processing'));
const getTakeoffFileUploadNameLens = (id) => compose(getTakeoffFileUploadLens(id), lensProp('name'));

const getTakeoffFileWebsocketUploadLens = (id) => compose(websocketUploadLens, lensProp(id));
const getTakeoffFileWebsocketUploadProgressLens = (id) => compose(getTakeoffFileWebsocketUploadLens(id), lensProp('progress'));
const getTakeoffFileWebsocketUploadMessageLens = (id) => compose(getTakeoffFileWebsocketUploadLens(id), lensProp('message'));

const sortingLens = lensProp('sorting');
const takeoffFilesSortingLens = compose(sortingLens, lensProp('takeoffFiles'));
const takeoffFilesSortingAttributeLens = compose(takeoffFilesSortingLens, lensProp('attribute'));
const takeoffFilesSortingIsAscendingLens = compose(takeoffFilesSortingLens, lensProp('isAscending'));
const exportFilesSortingLens = compose(sortingLens, lensProp('exportFiles'));
const exportFilesSortingAttributeLens = compose(exportFilesSortingLens, lensProp('attribute'));
const exportFilesSortingIsAscendingLens = compose(exportFilesSortingLens, lensProp('isAscending'));
const projectFileNodesSortingLens = compose(sortingLens, lensProp('projectFileNodes'));
const projectFileNodesSortingAttributeLens = compose(projectFileNodesSortingLens, lensProp('attribute'));
const projectFileNodesSortingIsAscendingLens = compose(projectFileNodesSortingLens, lensProp('isAscending'));

const remoteLens = lensProp('remote');
const dataLens = compose(remoteLens, lensProp('data'));
const statusLens = compose(remoteLens, lensProp('status'));
const takeoffFilesLens = compose(dataLens, lensProp('files'));
const pagesLens = compose(dataLens, lensProp('pages'));
const projectFileNodesLens = compose(dataLens, lensProp('project_file_nodes'));
const exportFilesLens = compose(dataLens, lensProp('export_files'));

const modalLens = lensProp('modal');
const pageViewerModalLens = compose(modalLens, lensProp('pageViewer'));
const deletionModalLens = compose(modalLens, lensProp('deletion'));
const deletionProjectFileNodeModalLens = compose(deletionModalLens, lensProp('projectFileNode'));
const deletionPageModalLens = compose(deletionModalLens, lensProp('page'));
const deletionTakeoffFilesModalLens = compose(deletionModalLens, lensProp('takeoffFiles'));
const renameModalLens = compose(modalLens, lensProp('rename'));
const renameTakeoffFileModalObjLens = compose(renameModalLens, lensProp('takeoffFile'));
const renameTakeoffFileModalLens = compose(renameTakeoffFileModalObjLens, lensProp('id'));
const renameTakeoffFileModalTextLens = compose(renameTakeoffFileModalObjLens, lensProp('text'));
const renameProjectFileNodeModalObjLens = compose(renameModalLens, lensProp('projectFileNode'));
const renameProjectFileNodeModalLens = compose(renameProjectFileNodeModalObjLens, lensProp('id'));
const renameProjectFileNodeModalTextLens = compose(renameProjectFileNodeModalObjLens, lensProp('text'));
const renamePageModalObjLens = compose(renameModalLens, lensProp('page'));
const renamePageModalLens = compose(renamePageModalObjLens, lensProp('id'));
const renamePageModalTextLens = compose(renamePageModalObjLens, lensProp('text'));

const activeTabLens = lensProp('activeTab');

const draggingLens = lensProp('dragging');
const draggingTakeoffFileLens = compose(draggingLens, lensProp('takeoffFile'));
const draggingExportFileLens = compose(draggingLens, lensProp('exportFile'));
const draggingProjectFileNodeLens = compose(draggingLens, lensProp('projectFileNode'));
const draggingProjectFileNodeContainerLens = compose(draggingLens, lensProp('projectFileNodeContainer'));

const currentFileLens = lensProp('currentFile');
const currentTakeoffFileLens = compose(currentFileLens, lensProp('takeoffFile'));
const currentProjectFileNodeObjLens = compose(currentFileLens, lensProp('projectFileNode'));
const currentProjectFileNodeLens = compose(currentProjectFileNodeObjLens, lensProp('id'));
const currentProjectFileNodeIsLoadingLens = compose(currentProjectFileNodeObjLens, lensProp('isLoading'));
const currentProjectFileNodeURLLens = compose(currentProjectFileNodeObjLens, lensProp('url'));
const currentExportFileObjLens = compose(currentFileLens, lensProp('exportFile'));
const currentExportFileLens = compose(currentExportFileObjLens, lensProp('id'));
const currentExportFileIsLoadingLens = compose(currentExportFileObjLens, lensProp('isLoading'));
const currentExportFileURLLens = compose(currentExportFileObjLens, lensProp('url'));

const TAKEOFF_FILE_CONTEXT_MENU_ID = 'takeoffFileContextMenu';
const PROJECT_FILE_NODE_CONTEXT_MENU_ID = 'projectFileNodeContextMenu';
const PAGE_CONTEXT_MENU_ID = 'pageContextMenu';
const contextMenuLens = lensProp('contextMenu');
const contextMenuProjectFileNodeLens = compose(contextMenuLens, lensProp('projectFileNode'));
const contextMenuPageLens = compose(contextMenuLens, lensProp('page'));
const contextMenuTakeoffFileLens = compose(contextMenuLens, lensProp('takeoffFile'));

const uploadLens = lensProp('upload');
const uploadProjectFileNodesLens = compose(uploadLens, lensProp('projectFileNodes'));
const uploadTakeoffFilesLens = compose(uploadLens, lensProp('takeoffFiles'));

const websocketLens = lensProp('websocket');
const websocketUploadLens = compose(websocketLens, lensProp('upload'));

const zoomLevelLens = lensProp('zoomLevel');

const searchLens = lensProp('search');
const takeoffFilesSearchLens = compose(searchLens, lensProp('takeoffFiles'));
const projectFileNodesSearchLens = compose(searchLens, lensProp('projectFileNodes'));
const exportFilesSearchLens = compose(searchLens, lensProp('exportFiles'));

const pageShiftSelectIDLens = lensProp('pageShiftSelectID');

const initialState = {
    sorting: { takeoffFiles: { attribute: 'index', isAscending: true }, exportFiles: { attribute: 'index', isAscending: true }, projectFileNodes: { attribute: 'index', isAscending: true } },
    remote: {
        status: null,
        data: { project_file_nodes: {}, files: {}, export_files: {} },
    },
    modal: {
        pageViewer: null,
        deletion: { takeoffFiles: null, projectFileNode: null, page: null },
        rename: { takeoffFile: { id: null, text: '' }, projectFileNode: { id: null, text: '' }, page: { id: null, text: '' } },
    },
    activeTab: 'takeoffFiles', // 'takeoffFiles, 'exportFiles', or 'projectFiles'
    dragging: { takeoffFile: null, exportFile: null, projectFileNode: null, projectFileNodeContainer: null },
    currentFile: { takeoffFile: null, exportFile: null, projectFileNode: { id: null, isLoading: false, url: null }, exportFile: { id: null, isLoading: false, url: null } },
    contextMenu: { projectFileNode: null, page: null, takeoffFile: null },
    upload: { projectFileNodes: {}, takeoffFiles: {} }, // projectFileNode:{ [id]: {name: '', progress: 0} }, takeoffFiles: { [id]: {name: '', upload: 0, processing: 0} }
    collapsed: {}, // { [id]: false }
    websocket: { upload: {} },
    zoomLevel: 200,
    search: { takeoffFiles: '', projectFileNodes: '', exportFiles: '' },
    pageShiftSelectID: null,
};

const getTakeoffFiles = (state) => pipe(view(takeoffFilesLens), keys)(state);
const getTakeoffFileIndex = (state) => pipe(getTakeoffFileIndexLens, reverseView(state));
const getProjectFileNodes = (state) => pipe(view(projectFileNodesLens), keys)(state);
const getTopLevelProjectFileNodes = (state) => pipe(getProjectFileNodes, filter(pipe(getProjectFileNodeParentLens, reverseView(state), isNil)))(state);
const getProjectFileNodeChildren = (state) => pipe(getProjectFileNodeChildrenLens, reverseView(state));
const getProjectFileNodeIsFolder = (state) => pipe(getProjectFileNodeIsFolderLens, reverseView(state));
const getProjectFileNodeIndex = (state) => pipe(getProjectFileNodeIndexLens, reverseView(state));
const getExportFiles = (state) => pipe(view(exportFilesLens), keys)(state);
const getExportFileIndex = (state) => pipe(getExportFileIndexLens, reverseView(state));

const clearCurrentFile = pipe(
    set(currentTakeoffFileLens)(null),
    set(currentExportFileLens)(null),
    set(currentExportFileIsLoadingLens)(false),
    set(currentExportFileURLLens)(null),
    set(currentProjectFileNodeLens)(null),
    set(currentProjectFileNodeIsLoadingLens)(false),
    set(currentProjectFileNodeURLLens)(null),
    set(pageShiftSelectIDLens)(null)
);
const setCurrentTakeoffFile = (id) => pipe(clearCurrentFile, set(currentTakeoffFileLens)(id));
const setCurrentExportFile = (id) => pipe(clearCurrentFile, set(currentExportFileLens)(id), set(currentExportFileIsLoadingLens)(true));
const setCurrentProjectFileNode = (id) => pipe(clearCurrentFile, set(currentProjectFileNodeLens)(id), set(currentProjectFileNodeIsLoadingLens)(true));

const openRenameTakeoffFileModal = (id) => (state) => pipe(set(renameTakeoffFileModalLens)(id), set(renameTakeoffFileModalTextLens)(view(getTakeoffFileNameLens(id))(state)))(state);
const openRenameProjectFileNodeModal = (id) => (state) => pipe(set(renameProjectFileNodeModalLens)(id), set(renameProjectFileNodeModalTextLens)(view(getProjectFileNodeNameLens(id))(state)))(state);
const openRenamePageModal = (id) => (state) => pipe(set(renamePageModalLens)(id), set(renamePageModalTextLens)(view(getPageTitleLens(id))(state)))(state);

const closeAllModals = pipe(
    set(pageViewerModalLens)(null),
    set(deletionProjectFileNodeModalLens)(null),
    set(deletionPageModalLens)(null),
    set(deletionTakeoffFilesModalLens)(null),
    set(renameTakeoffFileModalLens)(null),
    set(renameTakeoffFileModalTextLens)(''),
    set(renameProjectFileNodeModalLens)(null),
    set(renameProjectFileNodeModalTextLens)(''),
    set(renamePageModalLens)(null),
    set(renamePageModalTextLens)('')
);

const changePageNumberBy = (change) => (state) => {
    const pageID = view(pageViewerModalLens)(state);
    if (isNil(pageID)) return state;
    const fileID = view(getPageParentFileLens(pageID))(state);
    const pagesList = view(getTakeoffFilePagesLens(fileID))(state);
    const pageViewerModalIndex = indexOf(pageID)(pagesList);
    const numPages = pagesList.length;
    const newPageIndex = (pageViewerModalIndex + change + numPages) % numPages;
    const newPageID = pagesList[newPageIndex];
    return pipe(set(pageViewerModalLens)(newPageID))(state);
};

// takeoff file state methods
const fixTakeoffFileIndices = (state) =>
    pipe(view(takeoffFilesLens), keys, sortBy(getTakeoffFileIndex(state)), reduceIndexed((accState, id, index) => set(getTakeoffFileIndexLens(id))(index)(accState))(state))(state);

const removeTakeoffFile = (id) => (state) => {
    const pagesToRemove = view(getTakeoffFilePagesLens(id))(state);
    return pipe(
        reduce((accState, pageID) => removePage(pageID)(accState))(state),
        over(takeoffFilesLens)(omit([id])),
        over(currentTakeoffFileLens)(ifElse(equals(id), always(null), identity)),
        fixTakeoffFileIndices
    )(pagesToRemove);
};

//page state methods
const unmountPage = (id) => (state) => pipe(getPageParentFileLens, reverseView(state), getTakeoffFilePagesLens, reverseOver(state)(reject(equals(id))))(id);

const fixPageIndices = (id) => (state) =>
    pipe(
        getPageParentFileLens,
        reverseView(state),
        getTakeoffFilePagesLens,
        reverseView(state),
        reduceIndexed((accState, pageID, index) => set(getPagePageNumberLens(pageID))(index)(accState))(state)
    )(id);
const removePage = (id) => pipe(over(pagesLens)(omit([id])), over(pageViewerModalLens)(ifElse(equals(id), always(null), identity)));

// project file node state methods
const unmountProjectFileNode = (id) => (state) => {
    const parentID = view(getProjectFileNodeParentLens(id))(state);
    if (isNil(parentID)) return state;
    return pipe(getProjectFileNodeChildrenLens, reverseOver(state)(reject(equals(id))))(parentID);
};

const removeProjectFileNode = (id) => (state) => {
    const toRemove = fullDFS(getProjectFileNodeIsFolder(state))(getProjectFileNodeChildren(state))(id);
    return pipe(over(currentProjectFileNodeLens)(ifElse(reverseIncludes(toRemove), always(null), identity)), over(projectFileNodesLens)(omit(toRemove)))(state);
};

const insertProjectFileNode = (newParentID) => (index) => (id) => (state) => {
    if (isNil(newParentID)) {
        // because there is no top level id array to insert into, we need to make sure that the indices are correct here and now
        return pipe(
            getTopLevelProjectFileNodes,
            sortBy(pipe(getProjectFileNodeIndexLens, reverseView(state))),
            reject(equals(id)),
            insert(index)(id),
            reduceIndexed((accState, id, index) => set(getProjectFileNodeIndexLens(id))(index)(accState))(state),
            set(getProjectFileNodeParentLens(id))(newParentID)
        )(state);
    }
    const newChildren = pipe(getProjectFileNodeChildrenLens, reverseView(state), reject(equals(id)), insert(index)(id))(newParentID);
    return pipe(set(getProjectFileNodeChildrenLens(newParentID))(newChildren), set(getProjectFileNodeParentLens(id))(newParentID))(state);
};

const fixProjectFileNodeIndices = (state) => {
    // assuming top level indices are correct
    const sortedTopLevelProjectFileNodeIDs = pipe(getTopLevelProjectFileNodes, sortBy(getProjectFileNodeIndex(state)))(state);
    const orderedProjectFileNodeIDs = pipe(map(fullDFS(getProjectFileNodeIsFolder(state))(getProjectFileNodeChildren(state))), flatten)(sortedTopLevelProjectFileNodeIDs);
    return reduceIndexed((accState, id, index) => set(getProjectFileNodeIndexLens(id))(index)(accState))(state)(orderedProjectFileNodeIDs);
};

const reparentProjectFileNode = (index) => (newParentID) => (id) => pipe(unmountProjectFileNode(id), insertProjectFileNode(newParentID)(index)(id), fixProjectFileNodeIndices);

//upload state methods
const removeTakeoffFileUpload = (id) => over(uploadTakeoffFilesLens)(omit([id]));
const removeProjectFileNodeUpload = (id) => over(uploadProjectFileNodesLens)(omit([id]));

//websocket state method
const websocketUpdate =
    ({ type, path, val }) =>
        (state) => {
            if (type == 'merge') return mergeFromLens(compose(websocketLens, lensPath(path)))(val)(state);
            if (type == 'add') return over(compose(websocketLens, lensPath(path)))(pipe(add(val), defaultTo(0.5), min(0.99)))(state);
            if (type == 'remove') return over(compose(websocketLens, lensPath(init(path))))(omit([last(path)]))(state);
            return state;
        };

const Exports = () => {
    const params = useParams();
    const projectUUID = params.projectUUID;
    const auth = useSelector(selectAuth);
    const [state, setState] = useState(initialState);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    const { show: showProjectFileNodeContextMenu } = useContextMenu({ id: PROJECT_FILE_NODE_CONTEXT_MENU_ID });
    const { show: showPageContextMenu } = useContextMenu({ id: PAGE_CONTEXT_MENU_ID });
    const { show: showTakeoffFileContextMenu } = useContextMenu({ id: TAKEOFF_FILE_CONTEXT_MENU_ID });
    const viewState = (lens) => view(lens)(state);

    const filesAPI = useMemo(() => axiosCurry(`${API_ROUTE}/api/files-api/${projectUUID}/`)({ Authorization: `Token ${auth.token}`, 'Content-Type': 'application/json' }), [API_ROUTE, auth.token]);
    const fetchFiles = pipe(filesAPI('get'), otherwise(view(repsonseLens)), andThen(mergeFromLens(remoteLens)), andThen(setState));

    useEffect(() => {
        fetchFiles();
    }, []);

    useEffect(() => {
        pipe(
            viewState,
            keys,
            map((id) => updateOrCreateToast(viewState(getTakeoffFileWebsocketUploadMessageLens(id)))({ progress: viewState(getTakeoffFileWebsocketUploadProgressLens(id)), autoClose: 10000 })(id))
        )(websocketUploadLens);
    }, [viewState(websocketUploadLens)]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key == 'Escape' || e.key == 'Enter') {
                if (document.activeElement.tagName == 'INPUT' || document.activeElement.tagName == 'TEXTAREA') document.activeElement.blur();
            }
            //if right arrow and modal is open, go to next page
            if (e.key == 'ArrowRight' && viewState(pageViewerModalLens)) setState(changePageNumberBy(1));
            //if left arrow and modal is open, go to previous page
            if (e.key == 'ArrowLeft' && viewState(pageViewerModalLens)) setState(changePageNumberBy(-1));
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [viewState(pageViewerModalLens)]);

    const { sendMessage, lastMessage, readyState } = useWebSocket(`${WEBSOCKET_ROUTE}/file-upload-consumer/${projectUUID}/`, {
        heartbeat: {
            message: 'ping',
            returnMessage: 'pong',
            timeout: 60000, // 1 minute, if no response is received, the connection will be closed
            interval: 25000, // every 25 seconds, a ping message will be sent
        },
        onMessage: (e) => {
            const message = pipe(view(responseDataLens), JSON.parse)(e);
            console.log(message);

            if (message.type == 'refresh') fetchFiles();
            else setState(websocketUpdate(message));
        },
        onClose: (e) => {
            console.log(e);
        },
        shouldReconnect: always(true),
        onOpen: (e) => {
            console.log(e);
        },
    });

    //file upload related code
    const uploadFileToS3 = (file) => (onUploadProgress) => {
        const filename = file.name.replace(/[^a-zA-Z0-9.]/g, '');
        const presignedHeaders = andThen(view(responseDataLens))(axiosCurry(`${API_ROUTE}/api/aws-presigned-headers-api/`)({ Authorization: `Token ${auth.token}` })('post')({ filename }));
        const urlPromise = andThen(prop('url'))(presignedHeaders);
        const fieldsPromise = andThen(prop('fields'))(presignedHeaders);
        const keyPromise = andThen(prop('key'))(fieldsPromise);
        const formDataPromise = pipe(
            andThen((fields) => ({ ...fields, file })),
            andThen(formDataFromObj)
        )(fieldsPromise);
        return pipe(
            (promises) => Promise.all(promises),
            andThen(([url, formData]) => axios.post(url, formData, { onUploadProgress })),
            andThen(always(keyPromise))
        )([urlPromise, formDataPromise]);
    };

    const handleProjectFileNodeUpload = (parentID) => (acceptedFiles) => {
        const ids = map(generateDummyID)(acceptedFiles);
        const getFile = reverseProp(zipObj(ids)(acceptedFiles));
        const getFileName = pipe(map(prop('name')), zipObj(ids), reverseProp)(acceptedFiles);
        const getOnUploadProgress =
            (id) =>
                ({ progress }) =>
                    updateOrCreateToast(`Uploading ${getFileName(id)}...`)({
                        progress,
                        autoClose: 10000,
                        theme: 'light',
                    })(id);
        // setState(pipe(set(getProjectFileNodeUploadNameLens(id))(getFileName(id)), set(getProjectFileNodeUploadProgressLens(id))(progress)));
        const fileKeyPromises = map((id) => uploadFileToS3(getFile(id))(getOnUploadProgress(id)))(ids);
        // const getFileKeyPromise = reverseProp(zipObj(ids)(fileKeyPromises));
        // const uploadRemovalPromises = map((id) => andThen(() => pipe(tap(toast.dismiss), removeProjectFileNodeUpload, setState)(id))(getFileKeyPromise(id)))(ids);
        pipe(
            (promises) => Promise.all(promises),
            andThen(zip(ids)),
            andThen(
                mapIndexed(([id, key], index) =>
                    pipe(
                        set(getProjectFileNodeFileKeyLens(id))(key),
                        set(getProjectFileNodeNameLens(id))(getFileName(id)),
                        set(getProjectFileNodeIndexLens(id))(-1 - index),
                        set(getProjectFileNodeParentLens(id))(parentID)
                    )({})
                )
            ),
            andThen(reduce(mergeDeepLeft)(state)),
            andThen(fixProjectFileNodeIndices),
            andThen(view(dataLens)),
            andThen(filesAPI('patch')),
            andThen(mergeFromLens(remoteLens)),
            andThen(setState)
        )(fileKeyPromises);
    };

    const handleTakeoffFileUpload = (acceptedFiles) => {
        const ids = map(generateDummyID)(acceptedFiles);
        const getIndex = pipe(
            mapIndexed((id, index) => [id, -1 - index]),
            fromPairs,
            reverseProp
        )(ids);
        const getFile = reverseProp(zipObj(ids)(acceptedFiles));
        const getFileName = pipe(map(prop('name')), map(replace(/[^a-zA-Z0-9.]/g)('')), zipObj(ids), reverseProp)(acceptedFiles);
        const getOnUploadProgress =
            (id) =>
                ({ progress }) =>
                    updateOrCreateToast(`Uploading ${getFileName(id)}...`)({ progress: progress / 2 })(id);
        // setState(pipe(set(getTakeoffFileUploadNameLens(id))(getFileName(id)), set(getTakeoffFileUploadProgressLens(id))(progress)));
        const fileKeyPromises = map((id) => uploadFileToS3(getFile(id))(getOnUploadProgress(id)))(ids);
        const getFileKeyPromise = reverseProp(zipObj(ids)(fileKeyPromises));
        const updatedToastKeyPromises = map((id) =>
            pipe(
                getFileKeyPromise,
                andThen((fileKey) => updateOrCreateToast(`Splitting for ${getFileName(id)} started`)({ toastId: fileKey })(id))
            )(id)
        )(ids);
        return andThen((fileKeys) => {
            const getFileKey = reverseProp(zipObj(ids)(fileKeys));
            return pipe(
                reduce((accState, id) =>
                    pipe(
                        set(getTakeoffFileIndexLens(id))(getIndex(id)),
                        set(getTakeoffFileNameLens(id))(pipe(getFileName, replace(/[^a-zA-Z0-9.]/g, ''))(id)),
                        set(getTakeoffFileFileKeyLens(id))(getFileKey(id)),
                        set(getTakeoffFileIsUploadCompleteLens(id))(false)
                    )(accState)
                )(state),
                fixTakeoffFileIndices,
                view(dataLens),
                filesAPI('patch'),
                andThen(mergeFromLens(remoteLens)),
                andThen(setState)
            )(ids);
        })(Promise.all(fileKeyPromises));
    };

    //memoized values for takeoff files
    const sortedFilteredTakeoffFileIDS = useMemo(() => {
        const attribute = viewState(takeoffFilesSortingAttributeLens);
        const isAscending = viewState(takeoffFilesSortingIsAscendingLens);
        const getSortingValue = (id) => viewState(compose(getTakeoffFileLens(id), lensProp(attribute)));
        const formattedSearchString = toLower(viewState(takeoffFilesSearchLens));
        return pipe(getTakeoffFiles, sortBy(getSortingValue), isAscending ? identity : reverse, filter(pipe(getTakeoffFileNameLens, viewState, toLower, includes(formattedSearchString))))(state);
    }, [viewState(takeoffFilesSortingLens), viewState(takeoffFilesLens), viewState(takeoffFilesSearchLens)]);

    //memoized values for project file nodes
    const getSortedProjectFileNodeChildrenIDs = (id) => {
        if (!viewState(getProjectFileNodeIsFolderLens(id))) return null;
        const attribute = viewState(projectFileNodesSortingAttributeLens);
        const isAscending = viewState(projectFileNodesSortingIsAscendingLens);
        const getSortingValue = (id) => viewState(compose(getProjectFileNodeLens(id), lensProp(attribute)));
        return pipe(getProjectFileNodeChildrenLens, viewState, sortBy(getSortingValue), isAscending ? identity : reverse)(id);
    };

    const matchedSearchProjectFileNodes = useMemo(() => {
        const formattedSearchString = toLower(viewState(projectFileNodesSearchLens));
        return pipe(getProjectFileNodes, filter(pipe(getProjectFileNodeNameLens, viewState, toLower, includes(formattedSearchString))))(state);
    }, [viewState(projectFileNodesLens), viewState(projectFileNodesSearchLens)]);

    const matchedSearchProjectFileNodeDescendants = useMemo(
        () => map(fullDFS(getProjectFileNodeIsFolder(state))(getProjectFileNodeChildren(state)))(matchedSearchProjectFileNodes),
        [matchedSearchProjectFileNodes, viewState(projectFileNodesLens)]
    );

    const matchedSearchProjectFileNodeAncestors = useMemo(() => {
        const getParent = (id) => defaultTo([])([pipe(getProjectFileNodeParentLens, viewState)(id)]);
        const hasParent = pipe(getParent, head, isNil, not);
        return map(fullDFS(hasParent)(getParent))(matchedSearchProjectFileNodes);
    }, [matchedSearchProjectFileNodes, viewState(projectFileNodesLens)]);

    const getDoesProjectNodeMatchSearch = useMemo(
        () =>
            pipe(
                concat(matchedSearchProjectFileNodeDescendants),
                flatten,
                (arr) => new Set(arr),
                (set) => (id) => set.has(id)
            )(matchedSearchProjectFileNodeAncestors),
        [matchedSearchProjectFileNodeDescendants, matchedSearchProjectFileNodeAncestors]
    );

    const sortedTopLevelProjectFileNodeIDs = useMemo(() => {
        const unsortedTopLevelIDs = getTopLevelProjectFileNodes(state);
        const attribute = viewState(projectFileNodesSortingAttributeLens);
        const isAscending = viewState(projectFileNodesSortingIsAscendingLens);
        const getSortingValue = (id) => viewState(compose(getProjectFileNodeLens(id), lensProp(attribute)));
        return pipe(sortBy(getSortingValue), isAscending ? identity : reverse, filter(getDoesProjectNodeMatchSearch))(unsortedTopLevelIDs);
    });

    const projectFileNodeTreeItems = map(
        fullGetDndTreeItems(pipe(getProjectFileNodeCollapsedLens, viewState))(pipe(getProjectFileNodeIsFolderLens, viewState))(
            pipe(getSortedProjectFileNodeChildrenIDs, filter(getDoesProjectNodeMatchSearch))
        )
    )(sortedTopLevelProjectFileNodeIDs);

    //memoized values for export files
    const sortedFilteredExportFileIDs = useMemo(() => {
        const attribute = viewState(exportFilesSortingAttributeLens);
        const isAscending = viewState(exportFilesSortingIsAscendingLens);
        const getSortingValue = (id) => viewState(compose(getExportFileLens(id), lensProp(attribute)));
        const formattedSearchString = toLower(viewState(exportFilesSearchLens));
        return pipe(getExportFiles, sortBy(getSortingValue), isAscending ? identity : reverse, filter(pipe(getExportFileNameLens, viewState, toLower, includes(formattedSearchString))))(state);
    }, [viewState(exportFilesSortingLens), viewState(exportFilesLens), view(exportFilesSearchLens)]);

    const PROJECT_FILE_NODE_UPLOAD_CONTEXT_ID = useMemo(() => `project-file-node-upload-${viewState(contextMenuProjectFileNodeLens)}`, [viewState(contextMenuProjectFileNodeLens)]);

    const getProjectFileTreeRowChildren = (id) => (
        <div
            className={`files-sidebar-tree-body`}
            onContextMenu={(event) => {
                showProjectFileNodeContextMenu({ event });
                setState(set(contextMenuProjectFileNodeLens)(id));
            }}
            onClick={() => {
                setState(setCurrentProjectFileNode(id));
                if (!getProjectFileNodeIsFolder(state)(id)) {
                    const mimeType = pipe(getProjectFileNodeFileKeyLens, viewState, getMimeType)(id);
                    pipe(
                        getProjectFileNodeFileURLLens,
                        viewState,
                        getBlobURLFromURL(mimeType),
                        andThen((url) => setState(pipe(set(currentProjectFileNodeURLLens)(url), set(currentProjectFileNodeIsLoadingLens)(false))))
                    )(id);
                }
            }}
        >
            {viewState(getProjectFileNodeNameLens(id))}
        </div>
    );

    return (
        <>
            <ChatNavbar projectUUID={projectUUID} />
            <div className='files-container'>
                <div className='files-sidebar'>
                    <div className='files-sidebar-content'>
                        <div className='files-sidebar-content-header'>
                            <Popup
                                trigger={(open) => (
                                    <div className={'files-sidebar-content-header-item ' + (open && 'files-sidebar-content-header-item-active')}>
                                        <IconSortDescending size={20} stroke={1} />
                                    </div>
                                )}
                                on='click'
                                position='bottom left'
                                closeOnDocumentClick
                                mouseLeaveDelay={300}
                                mouseEnterDelay={0}
                                contentStyle={{ width: '150px' }}
                            >
                                <div className='files-sidebar-sort-container'>
                                    <div
                                        className={'files-sidebar-sort-item ' + (viewState(exportFilesSortingAttributeLens) == 'index' && 'files-sidebar-sort-item-active')}
                                        onClick={() => setState(set(exportFilesSortingAttributeLens)('index'))}
                                    >
                                        Custom
                                    </div>
                                    <div
                                        className={'files-sidebar-sort-item ' + (viewState(exportFilesSortingAttributeLens) == 'name' && 'files-sidebar-sort-item-active')}
                                        onClick={() => setState(set(exportFilesSortingAttributeLens)('name'))}
                                    >
                                        Name
                                    </div>
                                    <div
                                        className={'files-sidebar-sort-item ' + (viewState(exportFilesSortingAttributeLens) == 'created' && 'files-sidebar-sort-item-active')}
                                        onClick={() => setState(set(exportFilesSortingAttributeLens)('created'))}
                                    >
                                        Date
                                    </div>
                                </div>
                            </Popup>

                            <div className='files-sidebar-content-header-search'>
                                <IconSearch size={20} stroke={1} />

                                <TextField
                                    className='files-sidebar-content-header-search-input'
                                    placeholder='Search export files'
                                    value={viewState(exportFilesSearchLens)}
                                    onBlur={(text) => setState(set(exportFilesSearchLens)(text))}
                                />

                                <div className='files-sidebar-content-header-search-clear' onClick={() => setState(set(exportFilesSearchLens)(''))}>
                                    <IconX size={20} stroke={1} />
                                </div>
                            </div>

                            <div>&nbsp;</div>
                        </div>

                        <div className='files-sidebar-content-body'>
                            {/*if no exprots, display a message */}
                            {sortedFilteredExportFileIDs.length == 0 && <div className='files-sidebar-content-placeholder'>No export files found</div>}

                            <DndContext
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                onDragStart={({ active: { id } }) => setState(set(draggingExportFileLens)(id))}
                                onDragEnd={pipe(
                                    getNewOrder(pipe(getExportFiles, sortBy(getExportFileIndex(state)))(state)),
                                    mapIndexed((id, index) => set(getExportFileIndexLens(id))(index)({})),
                                    reduce(mergeDeepLeft)({}),
                                    tap(pipe(set(draggingExportFileLens)(null), mergeDeepLeft, setState)),
                                    view(dataLens),
                                    filesAPI('patch')
                                )}
                            >
                                <SortableContext items={sortedFilteredExportFileIDs}>
                                    {map((id) => (
                                        <StyledSortableRow
                                            className={viewState(currentExportFileLens) == id ? 'files-sidebar-content-row files-sidebar-content-row-active' : 'files-sidebar-content-row'}
                                            key={id}
                                            id={id}
                                            onClick={() => {
                                                setState(setCurrentExportFile(id));
                                                const mimeType = pipe(getExportFileFileKeyLens, viewState, getMimeType)(id);
                                                pipe(
                                                    getExportFileFileURLLens,
                                                    viewState,
                                                    getBlobURLFromURL(mimeType),
                                                    andThen((url) => setState(pipe(set(currentExportFileURLLens)(url), set(currentExportFileIsLoadingLens)(false))))
                                                )(id);
                                            }}
                                            disabled={viewState(exportFilesSortingAttributeLens) != 'index' || viewState(exportFilesSearchLens) != ''}
                                        >
                                            <LargeTextElement>{viewState(getExportFileNameLens(id))}</LargeTextElement>
                                            <a href={pipe(getExportFileFileURLLens, viewState)(id)} target='_blank'>
                                                <IconDownload size={20} stroke={1} />
                                            </a>
                                        </StyledSortableRow>
                                    ))(sortedFilteredExportFileIDs)}
                                </SortableContext>
                                <DragOverlay>
                                    {viewState(draggingExportFileLens) && (
                                        <StyledRowContainer id={viewState(draggingExportFileLens)} style={{ pointerEvents: 'none' }} className='bg-white shadow'>
                                            <IconMenu2 size={20} stroke={1} />
                                            <LargeTextElement>{viewState(getExportFileNameLens(viewState(draggingExportFileLens)))}</LargeTextElement>
                                        </StyledRowContainer>
                                    )}
                                </DragOverlay>
                            </DndContext>
                        </div>
                    </div>
                </div>

                <div>
                    {pipe(viewState, equals('exportFiles'))(activeTabLens) && pipe(viewState, isNil)(currentExportFileLens) && <div className='files-export-placeholder'>Click on an export file to view it</div>}
                    {((id) => {
                        if (isNil(id)) return (
                            <div className='files-export-placeholder'>Click on an export file to view it</div>
                        );
                        if (viewState(currentExportFileIsLoadingLens))
                            return (
                                <div className='flex flex-col items-center justify-center h-full hover:bg-gray-100'>
                                    <Blocks visible={true} height='40' width='40' color='#006AFE' ariaLabel='blocks-loading' radius='5' wrapperStyle={{}} wrapperClass='blocks-wrapper' />
                                </div>
                            );
                        if (pipe(getExportFileFileKeyLens, viewState, getMimeType, includes('image'))(id)) {
                            return (
                                <div className='files-projectfiles-image-container'>
                                    <ReactPanZoom alt={null} image={viewState(currentExportFileURLLens)} />;
                                </div>
                            );
                        }

                        if (pipe(getExportFileFileKeyLens, viewState, getMimeType, includes('pdf'))(id)) {
                            return <iframe className='w-full h-full' src={viewState(currentExportFileURLLens)} />;
                        }

                        return (
                            <a href={viewState(currentExportFileURLLens)} target='_blank'>
                                <div className='flex flex-col items-center justify-center h-full hover:bg-gray-100'>
                                    <div className='flex flex-row gap-2'>
                                        <IconDownload size={60} />
                                    </div>

                                    <div className='text-xl font-light'>Can't preview this file, click to download.</div>
                                </div>
                            </a>
                        );
                    })(viewState(currentExportFileLens))}
                </div>
            </div>
            {pipe(viewState, isNil)(statusLens) && <Skeleton count={10} className='h-full grow-1' />}
            {pipe(viewState, (status) => !isNil(status) && status != 200)(statusLens) && (
                <div className='flex flex-col items-center justify-center h-full grow-1'>
                    <div className='text-xl font-light'>Error: {viewState(statusLens)}</div>
                </div>
            )}

            {((id) => {
                const isIncluded = viewState(getPageIsIncludedLens(id));
                return (
                    <Modal show={!isNil(id)} onHide={() => setState(closeAllModals)} size='xl'>
                        <Modal.Header>
                            <div className='flex flex-row justify-between w-full'>
                                <div className='flex flex-row gap-2 fs-4 fw-500'>{viewState(getPageTitleLens(id))}</div>

                                <div className='flex flex-row gap-2'>
                                    <DefaultButton handleClick={() => setState(changePageNumberBy(-1))}>
                                        <IconArrowLeft />
                                    </DefaultButton>
                                    <DefaultButton handleClick={() => setState(changePageNumberBy(1))}>
                                        <IconArrowRight />
                                    </DefaultButton>

                                    <DefaultButton
                                        className='flex flex-row gap-2'
                                        handleClick={() => {
                                            const pageIsIncludedLens = getPageIsIncludedLens(id);
                                            pipe(over(pageIsIncludedLens), setState)(not);
                                            pipe(not, (val) => set(pageIsIncludedLens)(val)({}), view(dataLens), filesAPI('patch'))(isIncluded);
                                        }}
                                    >
                                        {isIncluded ? (
                                            <>
                                                Included <IconCircleFilled className='text-blue-bobyard' />
                                            </>
                                        ) : (
                                            <>
                                                Excluded <IconCircle className='text-blue-bobyard' />
                                            </>
                                        )}
                                    </DefaultButton>
                                </div>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='overflow-hidden'>{!isNil(id) && <ReactPanZoom alt={null} image={pipe(getPageThumbnailURLLens, viewState)(id)} />}</div>
                        </Modal.Body>
                    </Modal>
                );
            })(viewState(pageViewerModalLens))}
            {((id) => {
                const isFolder = viewState(getProjectFileNodeIsFolderLens(id));
                return (
                    <Modal show={!isNil(id)} onHide={() => setState(closeAllModals)} size='md'>
                        <Modal.Header>Delete {isFolder ? 'Folder' : 'File'}</Modal.Header>
                        <Modal.Body>
                            Are you sure you want to delete <span className='font-bold'>{viewState(getProjectFileNodeNameLens(id))}</span>
                            {isFolder && ' and its contents'}?
                        </Modal.Body>
                        <Modal.Footer>
                            <DeleteButton
                                handleClick={() => {
                                    setState(pipe(unmountProjectFileNode(id), removeProjectFileNode(id), closeAllModals));
                                    pipe(getProjectFileNodeLens, reverseSet({})({}), view(dataLens), filesAPI('delete'))(id);
                                }}
                            >
                                Delete
                            </DeleteButton>
                            <DefaultButton handleClick={() => setState(closeAllModals)}>Cancel</DefaultButton>
                        </Modal.Footer>
                    </Modal>
                );
            })(viewState(deletionProjectFileNodeModalLens))}
            {((id) => {
                const pageTitle = viewState(getPageTitleLens(id));
                const shouldClearShiftSelect = pipe(viewState, equals(id))(pageShiftSelectIDLens);
                return (
                    <Modal show={id} onHide={() => setState(closeAllModals)} size='md'>
                        <Modal.Header>Delete {pageTitle}</Modal.Header>
                        <Modal.Body>
                            Are you sure you want to delete <span className='font-bold'>{pageTitle}</span> and all its measurements?
                        </Modal.Body>
                        <Modal.Footer>
                            <DeleteButton
                                handleClick={() => {
                                    setState(pipe(unmountPage(id), fixPageIndices(id), removePage(id), shouldClearShiftSelect ? set(pageShiftSelectIDLens)(null) : identity, closeAllModals));
                                    pipe(getPageLens, reverseSet({})({}), view(dataLens), filesAPI('delete'))(id);
                                }}
                            >
                                Delete
                            </DeleteButton>
                            <DefaultButton handleClick={() => setState(closeAllModals)}>Cancel</DefaultButton>
                        </Modal.Footer>
                    </Modal>
                );
            })(viewState(deletionPageModalLens))}
            {((id) => {
                const fileName = viewState(getTakeoffFileNameLens(id));
                return (
                    <Modal show={id} onHide={() => setState(closeAllModals)} size='md'>
                        <Modal.Header>Delete {fileName}</Modal.Header>
                        <Modal.Body>
                            Are you sure you want to delete <span className='font-bold'>{fileName}</span> and all of its associated pages and measurements?
                        </Modal.Body>
                        <Modal.Footer>
                            <DeleteButton
                                handleClick={() => {
                                    setState(pipe(removeTakeoffFile(id), set(pageShiftSelectIDLens)(null), closeAllModals));
                                    pipe(getTakeoffFileLens, reverseSet({})({}), view(dataLens), filesAPI('delete'))(id);
                                }}
                            >
                                Delete
                            </DeleteButton>
                            <DefaultButton handleClick={() => setState(closeAllModals)}>Cancel</DefaultButton>
                        </Modal.Footer>
                    </Modal>
                );
            })(viewState(deletionTakeoffFilesModalLens))}
            {((id) => {
                const fileName = viewState(getTakeoffFileNameLens(id));
                const currentText = viewState(renameTakeoffFileModalTextLens);
                return (
                    <Modal show={id} onHide={() => setState(closeAllModals)} size='md'>
                        <Modal.Header>Rename File</Modal.Header>
                        <Modal.Body>
                            <div>
                                <input
                                    id='files-rename-modal-input'
                                    className='files-rename-modal-input'
                                    value={currentText}
                                    onChange={(e) => setState(set(renameTakeoffFileModalTextLens)(e.target.value))}
                                    onBlur={(e) => setState(set(renameTakeoffFileModalTextLens)(e.target.value))}
                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <DefaultButton handleClick={() => setState(closeAllModals)}>Cancel</DefaultButton>
                            <DefaultButton
                                handleClick={() => {
                                    pipe(set(getTakeoffFileNameLens(id))(currentText), view(dataLens), tap(filesAPI('patch')))({});
                                    setState(pipe(set(getTakeoffFileNameLens(id))(currentText), closeAllModals));
                                }}
                            >
                                Save
                            </DefaultButton>
                        </Modal.Footer>
                    </Modal>
                );
            })(viewState(renameTakeoffFileModalLens))}
            {((id) => {
                const fileName = viewState(getPageTitleLens(id));
                const currentText = viewState(renamePageModalTextLens);
                return (
                    <Modal show={id} onHide={() => setState(closeAllModals)} size='md' contentClassName='files-rename-modal'>
                        <Modal.Header>Rename Page</Modal.Header>
                        <Modal.Body>
                            <div>
                                <input
                                    id='files-rename-modal-input'
                                    className='files-rename-modal-input'
                                    value={currentText}
                                    onChange={(e) => setState(set(renamePageModalTextLens)(e.target.value))}
                                    onBlur={(e) => setState(set(renamePageModalTextLens)(e.target.value))}
                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <DefaultButton handleClick={() => setState(closeAllModals)}>Cancel</DefaultButton>
                            <DefaultButton
                                handleClick={() => {
                                    pipe(set(getPageTitleLens(id))(currentText), view(dataLens), tap(filesAPI('patch')))({});
                                    setState(pipe(set(getPageTitleLens(id))(currentText), closeAllModals));
                                }}
                            >
                                Save
                            </DefaultButton>
                        </Modal.Footer>
                    </Modal>
                );
            })(viewState(renamePageModalLens))}
            {((id) => {
                const fileName = viewState(getProjectFileNodeNameLens(id));
                const currentText = viewState(renameProjectFileNodeModalTextLens);
                return (
                    <Modal show={id} onHide={() => setState(closeAllModals)} size='md'>
                        <Modal.Header>Rename File</Modal.Header>
                        <Modal.Body>
                            <div>
                                <input
                                    id='files-rename-modal-input'
                                    className='files-rename-modal-input'
                                    value={currentText}
                                    onChange={(e) => setState(set(renameProjectFileNodeModalTextLens)(e.target.value))}
                                    onBlur={(e) => setState(set(renameProjectFileNodeModalTextLens)(e.target.value))}
                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <DefaultButton handleClick={() => setState(closeAllModals)}>Cancel</DefaultButton>
                            <DefaultButton
                                handleClick={() => {
                                    pipe(set(getProjectFileNodeNameLens(id))(currentText), view(dataLens), tap(filesAPI('patch')))({});
                                    setState(pipe(set(getProjectFileNodeNameLens(id))(currentText), closeAllModals));
                                }}
                            >
                                Save
                            </DefaultButton>
                        </Modal.Footer>
                    </Modal>
                );
            })(viewState(renameProjectFileNodeModalLens))}
            <Menu
                id={PROJECT_FILE_NODE_CONTEXT_MENU_ID}
                theme='bobyard-light'
                preventDefaultOnKeydown={false}
                onVisibilityChange={ifElse(equals(false), () => setState(set(contextMenuProjectFileNodeLens)(null)), identity)}
            >
                {((id) => {
                    if (isNil(id)) return;
                    const isFolder = viewState(getProjectFileNodeIsFolderLens(id));
                    return (
                        <>
                            <ContextMenuItem onClick={() => setState(openRenameProjectFileNodeModal(id))}>
                                <IconCursorText size={20} stroke={1} />
                                Rename
                            </ContextMenuItem>
                            {isFolder && (
                                <ContextMenuItem
                                    onClick={() =>
                                        pipe(
                                            insertProjectFileNode(null)(0)('-1'),
                                            set(getProjectFileNodeIsFolderLens('-1'))(true),
                                            set(getProjectFileNodeNameLens('-1'))('New Group'),
                                            set(getProjectFileNodeParentLens('-1'))(id),
                                            fixProjectFileNodeIndices,
                                            view(dataLens),
                                            filesAPI('patch'),
                                            andThen(mergeFromLens(remoteLens)),
                                            andThen(setState)
                                        )(state)
                                    }
                                >
                                    <IconFolderPlus size={20} stroke={1} />
                                    Add group here
                                </ContextMenuItem>
                            )}
                            {isFolder && (
                                <ContextMenuItem>
                                    <label htmlFor={PROJECT_FILE_NODE_UPLOAD_CONTEXT_ID} className='contents'>
                                        <IconUpload size={20} stroke={1} />
                                        Upload project files here
                                    </label>
                                </ContextMenuItem>
                            )}
                            {!isFolder && (
                                <ContextMenuItem>
                                    <a href={pipe(getProjectFileNodeFileURLLens, viewState)(id)} target='_blank' className='contents'>
                                        <IconDownload size={20} stroke={1} />
                                        Download
                                    </a>
                                </ContextMenuItem>
                            )}
                            {!isFolder && pipe(getProjectFileNodeFileKeyLens, viewState, getMimeType, (type) => includes('pdf')(type) || includes('image')(type))(id) && (
                                <ContextMenuItem
                                    onClick={() => {
                                        const fileName = viewState(getProjectFileNodeNameLens(id));
                                        const fileKey = viewState(getProjectFileNodeFileKeyLens(id));
                                        pipe(
                                            set(getTakeoffFileNameLens('-1'))(fileName),
                                            set(getTakeoffFileFileKeyLens('-1'))(fileKey),
                                            set(getTakeoffFileIndexLens('-1'))(-1),
                                            fixTakeoffFileIndices,
                                            view(dataLens),
                                            filesAPI('patch'),
                                            andThen(mergeFromLens(remoteLens)),
                                            andThen(setState)
                                        )(state);
                                    }}
                                >
                                    <IconCopy size={20} stroke={1} />
                                    Copy to Takeoff Files
                                </ContextMenuItem>
                            )}
                            <ContextMenuItem onClick={() => setState(set(deletionProjectFileNodeModalLens)(id))} className='hover:!text-red-normal hover:bg-pink-light'>
                                <IconTrashX size={20} stroke={1} />
                                Delete
                            </ContextMenuItem>
                        </>
                    );
                })(viewState(contextMenuProjectFileNodeLens))}
            </Menu>

            <Menu id={PAGE_CONTEXT_MENU_ID} theme='bobyard-light' onVisibilityChange={ifElse(equals(false), () => setState(set(contextMenuPageLens)(null)), identity)}>
                {((id) => {
                    return (
                        <>
                            <ContextMenuItem onClick={() => setState(openRenamePageModal(id))}>
                                <IconCursorText size={20} stroke={1} />
                                Rename
                            </ContextMenuItem>
                            <ContextMenuItem
                                onClick={() => {
                                    const numPages = pipe(getPageParentFileLens, viewState, getTakeoffFilePagesLens, viewState, length)(id);
                                    pipe(
                                        getPageLens,
                                        viewState,
                                        pick(['page_number', 'title', 'file_key', 'thumbnail_key', 'desc', 'width_inches', 'height_inches', 'width', 'height', 'parent_file', 'project']),
                                        (cloned) => set(getPageLens('-1'))(cloned)({}),
                                        over(getPageTitleLens('-1'))((name) => `[Duplicate] ${name}`),
                                        set(getPagePageNumberLens('-1'))(numPages + 1),
                                        view(dataLens),
                                        filesAPI('patch'),
                                        andThen(mergeFromLens(remoteLens)),
                                        andThen(setState)
                                    )(id);
                                }}
                            >
                                <IconCopy size={20} stroke={1} />
                                Duplicate
                            </ContextMenuItem>
                            <ContextMenuItem onClick={() => setState(set(deletionPageModalLens)(id))} className='hover:!text-red-normal hover:bg-pink-light'>
                                <IconTrashX size={20} stroke={1} />
                                Delete
                            </ContextMenuItem>
                        </>
                    );
                })(viewState(contextMenuPageLens))}
            </Menu>
            <Menu id={TAKEOFF_FILE_CONTEXT_MENU_ID} theme='bobyard-light' onVisibilityChange={ifElse(equals(false), () => setState(set(contextMenuTakeoffFileLens)(null)), identity)}>
                {((id) => {
                    const pageIDs = viewState(getTakeoffFilePagesLens(id));
                    const areAllPagesIncluded = pipe(defaultTo([]), map(getPageIsIncludedLens), map(viewState), all(identity))(pageIDs);
                    return (
                        <>
                            <ContextMenuItem
                                onClick={() => {
                                    if (areAllPagesIncluded) {
                                        pipe(reduce((accState, id) => set(getPageIsIncludedLens(id))(false)(accState))({}), tap(pipe(mergeDeepLeft, setState)), view(dataLens), filesAPI('patch'))(pageIDs);
                                    } else {
                                        pipe(reduce((accState, id) => set(getPageIsIncludedLens(id))(true)(accState))({}), tap(pipe(mergeDeepLeft, setState)), view(dataLens), filesAPI('patch'))(pageIDs);
                                    }
                                }}
                            >
                                {areAllPagesIncluded ? (
                                    <>
                                        <IconDeselect size={20} stroke={1} />
                                        Deselect all pages
                                        <IconCircleFilled className='text-blue-bobyard' />
                                    </>
                                ) : (
                                    <>
                                        <IconSelectAll size={20} stroke={1} />
                                        Select all pages
                                        <IconCircle className='text-blue-bobyard' />
                                    </>
                                )}
                            </ContextMenuItem>
                            <ContextMenuItem onClick={() => setState(openRenameTakeoffFileModal(id))}>
                                <IconCursorText size={20} stroke={1} />
                                Rename
                            </ContextMenuItem>
                            <ContextMenuItem onClick={() => { }}>
                                <a href={pipe(getTakeoffFileFileURLLens, viewState)(id)} target='_blank' className='contents'>
                                    <IconDownload size={20} stroke={1} />
                                    Download
                                </a>
                            </ContextMenuItem>
                            <ContextMenuItem onClick={() => setState(set(deletionTakeoffFilesModalLens)(id))} className='hover:!text-red-normal hover:bg-pink-light'>
                                <IconTrashX size={20} stroke={1} />
                                Delete
                            </ContextMenuItem>
                        </>
                    );
                })(viewState(contextMenuTakeoffFileLens))}
            </Menu>

            <ToastContainer style={{ '--toastify-color-progress-light': 'var(--bobyard-blue)' }} className='mt-14' />
        </>
    );
};

export default Exports;
export { getExportFileNameLens, getExportFileFileKeyLens, getExportFileIndexLens, dataLens };
