import { useContext, useEffect, useState } from 'react';

import { IconDimensions, IconAngle, IconX, IconFolderOff, IconRestore, IconHighlight, IconEyeOff, IconEye, IconTrashX, IconArrowAutofitHeight, IconChevronDown, IconRuler2, IconLineDashed, IconBorderStyle2, IconBaselineDensityMedium, IconShare3, IconCircleHalf, IconRotate360, IconLineHeight, IconEdit, IconComponents, IconPointFilled, IconRuler, IconCube, IconWeight, IconFrame } from '@tabler/icons-react';
import { Tooltip } from 'react-tooltip';

import DetailItem from './DetailItem';
import GroupMenu from './GroupMenu';
import ColorPicker from '../../../../components/ColorPicker';
import { TakeoffContext } from '../../../helper/Context';
import DetailsNavbarInput from './DetailsNavbarInput';
import Popup from 'reactjs-popup';
import axios from 'axios';
import { API_ROUTE } from '../../../..';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../../redux/slices/authSlice';
import { IconAdjustmentsAlt } from '@tabler/icons-react';
import { UOM_CONVERSIONS, UOM_Dependencies, UOM_Dependencies_Description, UOM_Description, UOM_Display, UOM_Measured_Default } from '../../../helper/UnitConversions';

export default function MultiDetails({ left, width }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        project, setProject,
        pages, setPages,
        groups, setGroups,
        measurements, setMeasurements,
        drawing,
        handleUpdateProject,
        handleLineTransform,
        setEditingMeasurement,
        setShowDetails,
        setShowDeleteMeasurementModal,
        groupMeasurement,
        optionDown, setOptionDown,
        handleZoomToMeasurement,
        selectedMeasurements, setSelectedMeasurements,
        currentMeasurement, setCurrentMeasurement,
        setTree,
    } = useContext(TakeoffContext);

    const [showColorPicker, setShowColorPicker] = useState(false);

    useEffect(() => {
        const handleClick = e => {
            //if the click doe snot contain the color picker
            if (!document.getElementById('color-picker-container').contains(e.target)) {
                setShowColorPicker(false);
            }
        }

        //listen for escape
        const handleKeyDown = e => {
            if (e.key === 'Escape' && showColorPicker) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                setShowColorPicker(false);
            } else if (e.key === 'Enter' && showColorPicker || e.key === 'Return' && showColorPicker) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                setShowColorPicker(false);
            }
        }

        window.addEventListener('click', handleClick);
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('click', handleClick);
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, [showColorPicker])

    const selectedMeasurementsUnitConvertable = () => {
        let units = [];

        for (let i = 0; i < selectedMeasurements.length; i++) {
            if (measurements[selectedMeasurements[i]]?.uom_measured) {
                units.push(measurements[selectedMeasurements[i]]?.uom_measured);
            } else {
                units.push(UOM_Measured_Default[measurements[selectedMeasurements[i]]?.type]);
            }
        }

        if (new Set(units).size === 1) {
            return true;
        }

        return false;
    }

    const selectedMeasurementsContains = (types) => {
        for (let i = 0; i < selectedMeasurements.length; i++) {
            if (!types.includes(measurements[selectedMeasurements[i]]?.type)) {
                return false;
            }
        }

        return true;
    }

    const getValues = (key) => {
        let values = [];

        for (let i = 0; i < selectedMeasurements?.length; i++) {
            values.push(measurements?.[selectedMeasurements[i]]?.[key]);
        }

        if (new Set(values).size === 1) {
            return values[0] || '';
        }

        return '';
    }

    const updateValues = (key, value) => {
        setMeasurements(prev => {
            let newMeasurements = { ...prev };

            for (let i = 0; i < selectedMeasurements.length; i++) {
                newMeasurements[selectedMeasurements[i]][key] = value;
            }

            return { ...newMeasurements };
        })
    }

    const handleSaveValues = (key, value) => {
        setMeasurements(prev => {
            let newMeasurements = { ...prev };

            for (let i = 0; i < selectedMeasurements.length; i++) {
                newMeasurements[selectedMeasurements[i]][key] = value;
            }

            return { ...newMeasurements };
        })

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/multiedit-measurements/`,
            data: {
                'selectedMeasurements': selectedMeasurements,
                'key': key,
                'value': value,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then(res => {
                console.log(res);

                if (key === 'use_group_color') {
                    setMeasurements(res.data.measurements);
                }

                if (key === 'group') {
                    setTree(res.data.tree);
                    setMeasurements(res.data.measurements);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <>
            <div className='detailsnavbar-buttons-container'>
                {/*<div
                    className='detailsnavbar-title'
                    id='detailsnavbar-multiselect-title'
                >
                    <div>Group edit</div>
                </div>

                <Tooltip anchorSelect={'#detailsnavbar-multiselect-title'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='bottom-start'>
                    <div>
                        Edit all selected measurements.
                    </div>
                </Tooltip>

                <div className='detailsnavbar-divider detailsnavbar-divider-multiselect'>
                    &nbsp;  
                </div>*/}

                <Popup
                    trigger={open => (
                        <div
                            id='detailsnavbar-groupedit-name'
                            className={'detailsnavbar-button'}
                            style={{
                                color: open ? '#006AFE' : '#525252'
                            }}
                        >
                            {width < 900
                                ? <>
                                    <IconEdit size={20} stroke={1} />
                                </>
                                : <>
                                    <IconEdit size={20} stroke={1} />
                                    Names
                                </>
                            }
                        </div>
                    )}
                    on='click'
                    position="bottom left"
                    closeOnDocumentClick
                    mouseLeaveDelay={300}
                    mouseEnterDelay={0}
                >
                    <div>
                        <input
                            className='detailsnavbar-groupedit-name-input'
                            type='text'
                            onFocus={() => setEditingMeasurement(true)}
                            value={getValues('name')}
                            onChange={(e) => updateValues('name', e.target.value)}
                            placeholder='Name...'
                            onBlur={() => {
                                handleSaveValues('name', getValues('name'));
                                setEditingMeasurement(false);
                            }}
                        />
                    </div>
                </Popup>

                <Tooltip anchorSelect={'#detailsnavbar-groupedit-name'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-start'>
                    <div>
                        Edit name of selected measurements.
                    </div>
                </Tooltip>

                {/*<div className='detailsnavbar-divider detailsnavbar-divider-multiselect'>
                    &nbsp;
                </div>*/}

                <div
                    className='detailsnavbar-button'
                    id={`detailsnavbar-line-hide`}
                    onClick={(e) => {
                        handleSaveValues('hide', !getValues('hide'));
                    }}
                    size={'small'}
                >
                    {getValues('hide') === true
                        ? <IconEyeOff size={20} stroke={1} />
                        : <IconEye size={20} stroke={1} />
                    }
                </div>

                <div className='tooltip-container'>
                    <Tooltip anchorSelect={'#detailsnavbar-line-hide'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='bottom-start'>
                        <div>
                            {getValues('hide') ? 'Show selected measurements' : 'Hide selected measurements'}
                        </div>
                    </Tooltip>
                </div>

                <div
                    className='detailsnavbar-color-container'
                    id='color-picker-container'
                >
                    {showColorPicker
                        ? <ColorPicker
                            color={getValues('color') || '#9DD9F3'}
                            onChange={(color) => {
                                updateValues('color', color.hex);
                            }}
                            onChangeComplete={(color) => {
                                handleSaveValues('color', color.hex);
                                //handleSaveValues('use_group_color', false);
                                setShowColorPicker(false);
                            }}
                            top={70}
                            left={left + 50}
                        />
                        : null}

                    <div
                        className='detailsnavbar-button'
                        id={`detailsnavbar-line-color`}
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowColorPicker(true);
                        }}
                    >
                        <div
                            className='detailsnavbar-option-color-placeholder'
                            style={{
                                backgroundColor: getValues('color') || '#9DD9F3',
                            }}
                        ></div>
                    </div>

                    <Tooltip anchorSelect={'#detailsnavbar-line-color'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 99999 }} place='top-start'>
                        {getValues('use_group_color')
                            ? <>
                                <div><b>Choose color for selected measurements</b></div>
                                <div>Currently using group's color.</div>
                            </>
                            : <>
                                <div>Choose color for selected measurements</div>
                            </>}
                    </Tooltip>
                </div>

                <div
                    className='detailsnavbar-button'
                    id={`detailsnavbar-line-group-color`}
                    onClick={() => {
                        if (!getValues('use_group_color')) {
                            handleSaveValues('use_group_color', true);
                        }
                    }}
                    size={'small'}
                    disabled={getValues('use_group_color') ? true : false}
                >
                    <IconRestore size={20} stroke={1} style={{ color: getValues('use_group_color') ? 'lightgray' : 'black' }} />
                </div>

                <Tooltip anchorSelect={'#detailsnavbar-line-group-color'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-start'>
                    {getValues('use_group_color')
                        ? <>
                            <div>
                                Reset to group color for selected measurements
                            </div>
                        </>
                        : <>
                            <div>
                                Reset to group color for selected measurements
                            </div>
                        </>
                    }
                </Tooltip>

                <div className='detailsnavbar-divider detailsnavbar-divider-multiselect'>
                    &nbsp;
                </div>

                {selectedMeasurementsContains(['count']) &&
                    <>
                        <DetailsNavbarInput
                            keyID={'detailsnavbar-countdetails-size'}
                        >
                            {width < 1000
                                ? <div className='detailsnavbar-row-name'>
                                    <IconCircleHalf size={20} stroke={1} />
                                </div>
                                : <div className='detailsnavbar-row-name'>
                                    <IconCircleHalf size={20} stroke={1} />
                                    Size
                                </div>
                            }
                            <input
                                className='detailsnavbar-input'
                                id='detailsnavbar-countdetails-size'
                                type='number'
                                onFocus={() => setEditingMeasurement(true)}
                                value={getValues('size')}
                                onChange={(e) => updateValues('size', e.target.value ? e.target.value : null)}
                                placeholder='--'
                                onBlur={() => {
                                    handleSaveValues('size', getValues('size'));
                                    setEditingMeasurement(false);
                                }}
                            />
                        </DetailsNavbarInput>

                        <Tooltip anchorSelect={'#detailsnavbar-countdetails-size-container'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-start'>
                            <div>
                                Size of the count dot. (Visual)
                            </div>
                        </Tooltip>

                        <DetailsNavbarInput
                            keyID={'detailsnavbar-countdetails-shape'}
                        >
                            <div className='detailsnavbar-row-name'>
                                <IconAdjustmentsAlt size={20} stroke={1} />
                            </div>
                            <select
                                className='detailsnavbar-input-select'
                                id='detailsnavbar-countdetails-shape'
                                value={getValues('shape')}
                                onChange={(e) => {
                                    updateValues('shape', e.target.value);
                                    handleSaveValues('shape', e.target.value);
                                }}
                            >
                                <option value='circle'>Circle</option>
                                <option value='square'>Square</option>
                                <option value='diamond'>Diamond</option>
                                <option value='triangle'>Triangle</option>
                                <option value='pentagon'>Pentagon</option>
                                <option value='hexagon'>Hexagon</option>
                                <option value='octagon'>Octagon</option>
                                <option value='star'>Star</option>
                                <option value='cross'>Cross</option>
                            </select>
                        </DetailsNavbarInput>

                        <Tooltip anchorSelect={'#detailsnavbar-countdetails-shape-container'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-start'>
                            <div>
                                Shape of the count dot. (Visual)
                            </div>
                        </Tooltip>
                    </>
                }

                {selectedMeasurementsContains(['line']) &&
                    <>
                        <DetailsNavbarInput
                            keyID={'detailsnavbar-linedetails-size'}
                        >
                            {width < 1000
                                ? <div className='detailsnavbar-row-name'>
                                    <IconBaselineDensityMedium size={20} stroke={1} />
                                </div>
                                : <div className='detailsnavbar-row-name'>
                                    <IconBaselineDensityMedium size={20} stroke={1} />
                                    Line&nbsp;width
                                </div>
                            }
                            <input
                                className='detailsnavbar-input'
                                id='detailsnavbar-linedetails-size'
                                type='number'
                                onFocus={() => setEditingMeasurement(true)}
                                value={getValues('size')}
                                onChange={(e) => updateValues('size', e.target.value ? e.target.value : null)}
                                placeholder='--'
                                onBlur={() => {
                                    handleSaveValues('size', getValues('size'));
                                    setEditingMeasurement(false);
                                }}
                            />
                        </DetailsNavbarInput>

                        <Tooltip anchorSelect={'#detailsnavbar-linedetails-size-container'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-start'>
                            <div>
                                Line width (Visual)
                            </div>
                        </Tooltip>

                        <DetailsNavbarInput
                            keyID={'detailsnavbar-linedetails-gap'}
                        >
                            {width < 1000
                                ? <div className='detailsnavbar-row-name'>
                                    <IconBorderStyle2 size={20} stroke={1} />
                                </div>
                                : <div className='detailsnavbar-row-name'>
                                    <IconBorderStyle2 size={20} stroke={1} />
                                    Dash&nbsp;gap
                                </div>
                            }
                            <input
                                className='detailsnavbar-input'
                                id='detailsnavbar-linedetails-gap'
                                type='number'
                                onFocus={() => setEditingMeasurement(true)}
                                value={getValues('gap')}
                                onChange={(e) => updateValues('gap', e.target.value ? e.target.value : null)}
                                placeholder='--'
                                onBlur={() => {
                                    handleSaveValues('gap', getValues('gap'));
                                    setEditingMeasurement(false);
                                }}
                            />
                        </DetailsNavbarInput>

                        <Tooltip anchorSelect={'#detailsnavbar-linedetails-gap-container'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-start'>
                            <div>
                                Dash gap (Visual)
                            </div>
                        </Tooltip>

                        <div className='detailsnavbar-divider detailsnavbar-divider-multiselect'>
                            &nbsp;
                        </div>

                        {/*<DetailsNavbarInput
                            keyID={'detailsnavbar-linedetails-height'}
                        >
                            {width < 900
                                ? <div className='detailsnavbar-row-name'>
                                    <IconArrowAutofitHeight size={20} stroke={1} />
                                </div>
                                : <div className={'detailsnavbar-row-name'}>
                                    <IconArrowAutofitHeight size={20} stroke={1} />
                                    Height&nbsp;(ft)
                                </div>
                            }
                            <input
                                className='detailsnavbar-input'
                                id='detailsnavbar-linedetails-height'
                                type='number'
                                value={getValues('height')}
                                onFocus={() => setEditingMeasurement(true)}
                                onChange={e => updateValues('height', e.target.value ? e.target.value : null)}
                                onBlur={() => {
                                    handleSaveValues('height', getValues('height'));
                                    setEditingMeasurement(false);
                                }}
                                placeholder='--'
                            />
                        </DetailsNavbarInput>

                        <Tooltip anchorSelect={'#detailsnavbar-linedetails-height-container'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-start'>
                            <div>
                                <div><b>Height (ft)</b></div>
                                <div>Converts to area measurements.</div>
                            </div>
                            </Tooltip>*/}
                    </>
                }

                {selectedMeasurementsContains(['rectangle', 'polygon', 'circle']) &&
                    <>
                        {/*<DetailsNavbarInput
                            keyID={'detailsnavbar-polygondetails-gap'}
                        >
                            {width < 1000
                                ? <div className='detailsnavbar-row-name'>
                                    <IconLineHeight size={20} stroke={1} />
                                </div>
                                : <div className='detailsnavbar-row-name'>
                                    <IconLineHeight size={20} stroke={1} />
                                    Stripe&nbsp;gap
                                </div>
                            }
                            <input
                                className='detailsnavbar-input'
                                id='detailsnavbar-polygondetails-gap'
                                type='number'
                                onFocus={() => setEditingMeasurement(true)}
                                value={getValues('gap')}
                                onChange={(e) => updateValues('gap', e.target.value ? e.target.value : null)}
                                placeholder='--'
                                onBlur={() => {
                                    handleSaveValues('gap', getValues('gap'));
                                    setEditingMeasurement(false);
                                }}
                            />
                        </DetailsNavbarInput>*/}

                        <Tooltip anchorSelect={'#detailsnavbar-polygondetails-gap-container'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-start'>
                            <div>
                                <b>Stripe gap (visual)</b>
                            </div>
                            <div>
                                Gap between stripes for fill pattern.
                            </div>
                        </Tooltip>

                        {/*<DetailsNavbarInput
                            keyID={'detailsnavbar-polygondetails-size'}
                        >
                            {width < 1000
                                ? <div className='detailsnavbar-row-name'>
                                    <IconRotate360 size={20} stroke={1} />
                                </div>
                                : <div className='detailsnavbar-row-name'>
                                    <IconRotate360 size={20} stroke={1} />
                                    Stripe angle
                                </div>
                            }
                            <input
                                className='detailsnavbar-input'
                                id='detailsnavbar-polygondetails-size'
                                type='number'
                                onFocus={() => setEditingMeasurement(true)}
                                value={getValues('size')}
                                onChange={(e) => updateValues('size', e.target.value ? e.target.value : null)}
                                placeholder='--'
                                onBlur={() => {
                                    handleSaveValues('size', getValues('size'));
                                    setEditingMeasurement(false);
                                }}
                            />
                        </DetailsNavbarInput>*/}

                        <Tooltip anchorSelect={'#detailsnavbar-polygondetails-size-container'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-start'>
                            <div>
                                <b>Stripe angle (visual)</b>
                            </div>
                            <div>
                                Angle of stripes for fill pattern.
                            </div>
                        </Tooltip>

                        <div className='detailsnavbar-divider detailsnavbar-divider-multiselect'>
                            &nbsp;
                        </div>

                        {/*<DetailsNavbarInput
                            keyID={'detailsnavbar-polygondetails-depth'}
                        >
                            {width < 900
                                ? <div className='detailsnavbar-row-name'>
                                    <IconArrowAutofitHeight size={20} stroke={1} />
                                </div>
                                : <div className={'detailsnavbar-row-name'}>
                                    <IconArrowAutofitHeight size={20} stroke={1} />
                                    Depth&nbsp;(ft)
                                </div>
                            }
                            <input
                                className='detailsnavbar-input'
                                id='detailsnavbar-polygondetails-depth'
                                type='number'
                                value={getValues('depth')}
                                onFocus={() => setEditingMeasurement(true)}
                                onChange={e => updateValues('depth', e.target.value ? e.target.value : null)}
                                onBlur={() => {
                                    handleSaveValues('depth', getValues('depth'));
                                    setEditingMeasurement(false);
                                }}
                                placeholder='--'
                            />
                        </DetailsNavbarInput>

                        <Tooltip anchorSelect={'#detailsnavbar-polygondetails-depth-container'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-start'>
                            <div>
                                <b>Depth (ft)</b>
                            </div>
                            <div>
                                Depth of measurements.
                            </div>
                            </Tooltip>*/}
                    </>
                }

                {/*selectedMeasurementsContains(['rectangle', 'polygon', 'circle', 'line']) &&
                    <>
                        <DetailsNavbarInput
                            keyID={'detailsnavbar-polygondetails-pitch'}
                        >
                            {width < 900
                                ? <div className='detailsnavbar-row-name'>
                                    <IconAngle size={20} stroke={1} />
                                </div>
                                : <div className='detailsnavbar-row-name'>
                                    <IconAngle size={20} stroke={1} />
                                    Pitch (<div className='detailsnavbar-row-fraction'><div className='detailsnavbar-row-fraction-sup'>in</div><div></div><div>12 in</div></div>)
                                </div>
                            }
                            <input
                                className='detailsnavbar-input'
                                id='detailsnavbar-polygondetails-pitch'
                                type='number'
                                value={getValues('pitch')}
                                onFocus={() => setEditingMeasurement(true)}
                                onChange={e => updateValues('pitch', e.target.value ? e.target.value : null)}
                                onBlur={() => {
                                    handleSaveValues('pitch', getValues('pitch'));
                                    setEditingMeasurement(false);
                                }}
                                placeholder='--'
                            />
                        </DetailsNavbarInput>

                        <Tooltip anchorSelect={'#detailsnavbar-polygondetails-pitch-container'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-end'>
                            <div>
                                <b>Pitch (in)</b>
                            </div>
                            <div>
                                Number of inches of rise per 12 inches of run.
                            </div>
                        </Tooltip>
                    </>
                            */}

                {/*if every selected measurement has the same uom_measured or UOM_Measured_Default[type] */}
                {selectedMeasurementsUnitConvertable() &&
                    <>
                        <DetailsNavbarInput
                            keyID={'detailsnavbar-polygondetails-offset'}
                        >
                            {width < 900
                                ? <div className='detailsnavbar-row-name'>
                                    <IconFrame size={20} stroke={1} />
                                </div>
                                : <div className='detailsnavbar-row-name'>
                                    <IconFrame size={20} stroke={1} />
                                    Offset (ft)
                                </div>
                            }
                            <input
                                className='detailsnavbar-input'
                                id='detailsnavbar-polygondetails-offset'
                                type='number'
                                value={getValues('offset')}
                                onFocus={() => setEditingMeasurement(true)}
                                onChange={e => updateValues('offset', e.target.value ? e.target.value : null)}
                                onBlur={() => {
                                    handleSaveValues('offset', getValues('offset'));
                                    setEditingMeasurement(false);
                                }}
                                placeholder='--'
                            />
                        </DetailsNavbarInput>

                        <div
                            style={{
                                zIndex: 10000,
                            }}
                        >
                            <Popup
                                trigger={open => (
                                    <div
                                        id='detailsnavbar-circledetails-uom'
                                        className={'detailsnavbar-button'}
                                        style={{
                                            color: open ? '#006AFE' : '#525252'
                                        }}
                                    >
                                        <IconDimensions size={20} stroke={1} />
                                        {UOM_Display[measurements[selectedMeasurements[0]]?.uom || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]]}
                                        <IconChevronDown size={20} stroke={1} />
                                    </div>
                                )}
                                on='click'
                                position="bottom center"
                                closeOnDocumentClick
                                mouseLeaveDelay={300}
                                mouseEnterDelay={0}
                                contentStyle={{
                                    width: '250px',
                                }}
                            >
                                <div className="detailsnavbar-uom-items">
                                    {Object.keys(UOM_CONVERSIONS[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]]).map((uom, i) => (
                                        <div
                                            key={i}
                                            className={'detailsnavbar-uom-item ' + ((measurements[selectedMeasurements[0]]?.uom || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]) === uom ? 'detailsnavbar-uom-item-active' : '')}
                                            onClick={() => {
                                                updateValues('uom', uom);
                                                handleSaveValues('uom', uom);
                                            }}
                                        >
                                            <div>{UOM_Display[uom]}</div>
                                            <div>({UOM_Description[uom]})</div>
                                            <div>
                                                {UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type] === uom
                                                    ? <><IconPointFilled size={20} /></>
                                                    : <>&nbsp;</>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Popup>
                        </div>

                        <Tooltip anchorSelect={'#detailsnavbar-circledetails-uom'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top'>
                            <div>
                                <b>Unit of measurement</b>
                            </div>
                            <div>
                                Select a different unit of measurement to convert to
                            </div>
                        </Tooltip>

                        {measurements[selectedMeasurements[0]]?.uom && UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom].length > 1
                            ?
                            <>
                                <DetailsNavbarInput keyID={'detailsnavbar-circledetails-quantity1'}>
                                    {width < 900
                                        ? <div className='detailsnavbar-row-name'>
                                            {UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][1]['quantity1']?.toLowerCase().includes('length')
                                                ? <IconRuler2 size={20} stroke={1} />
                                                : UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][1]['quantity1']?.toLowerCase().includes('area')
                                                    ? <IconRuler size={20} stroke={1} />
                                                    : UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][1]['quantity1']?.toLowerCase().includes('volume')
                                                        ? <IconCube size={20} stroke={1} />
                                                        : UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][1]['quantity1']?.toLowerCase().includes('tri')
                                                            || UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][1]['quantity1']?.toLowerCase().includes('o.c.')
                                                            ? <IconComponents size={20} stroke={1} />
                                                            : <IconWeight size={20} stroke={1} />
                                            }
                                            {UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][1]['quantity1']?.split('(')?.pop()?.split(')')[0]}
                                        </div>
                                        : <div className={'detailsnavbar-row-name'}>
                                            {UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][1]['quantity1']?.toLowerCase().includes('length')
                                                ? <IconRuler2 size={20} stroke={1} />
                                                : UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][1]['quantity1']?.toLowerCase().includes('area')
                                                    ? <IconRuler size={20} stroke={1} />
                                                    : UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][1]['quantity1']?.toLowerCase().includes('volume')
                                                        ? <IconCube size={20} stroke={1} />
                                                        : UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][1]['quantity1']?.toLowerCase().includes('tri')
                                                            || UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][1]['quantity1']?.toLowerCase().includes('o.c.')
                                                            ? <IconComponents size={20} stroke={1} />
                                                            : <IconWeight size={20} stroke={1} />
                                            }
                                            {UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][1]['quantity1']}
                                        </div>
                                    }
                                    <input
                                        className='detailsnavbar-input'
                                        id='detailsnavbar-circledetails-quantity1'
                                        type='number'
                                        value={measurements[selectedMeasurements[0]]?.quantity1 ? measurements[selectedMeasurements[0]]?.quantity1 : ''}
                                        onFocus={() => setEditingMeasurement(true)}
                                        onChange={e => updateValues('quantity1', e.target.value ? e.target.value : null)}
                                        onBlur={() => {
                                            handleSaveValues('quantity1', getValues('quantity1'));
                                            setEditingMeasurement(false);
                                        }}
                                        placeholder='--'
                                    />
                                </DetailsNavbarInput>

                                <Tooltip anchorSelect={'#detailsnavbar-circledetails-quantity1-container'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-end'>
                                    <div>
                                        <b>{UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][1]['quantity1']}</b>
                                    </div>
                                    <div>
                                        {UOM_Dependencies_Description[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][1]['quantity1']}
                                    </div>
                                </Tooltip>
                            </>
                            : null
                        }

                        {measurements[selectedMeasurements[0]]?.uom && UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom].length > 2
                            ? UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][2]['quantity2'].toLowerCase().includes('tri')
                                ? <>
                                    <Popup
                                        trigger={open => (
                                            <div
                                                id='detailsnavbar-rectangledetails-quantity2'
                                                className={'detailsnavbar-button'}
                                                style={{
                                                    color: open ? '#006AFE' : '#525252'
                                                }}
                                            >
                                                <IconComponents size={20} stroke={1} />
                                                {measurements[selectedMeasurements[0]]?.quantity2 === 1 ? 'Tri' : 'Sqr'}
                                                <IconChevronDown size={20} stroke={1} />
                                            </div>
                                        )}
                                        on='click'
                                        position="bottom right"
                                        closeOnDocumentClick
                                        mouseLeaveDelay={300}
                                        mouseEnterDelay={0}
                                        contentStyle={{
                                            width: '250px',
                                        }}
                                    >
                                        <div className="detailsnavbar-uom-items">
                                            {[{ name: 'Square', value: 0 }, { name: 'Triangle', value: 1 }].map((fillpattern, i) => (
                                                <div
                                                    key={i}
                                                    className={'detailsnavbar-uom-item ' + (measurements[selectedMeasurements[0]]?.quantity2 === fillpattern.value ? 'detailsnavbar-uom-item-active' : '')}
                                                    onClick={() => {
                                                        updateValues('quantity2', fillpattern.value);
                                                        handleSaveValues('quantity2', fillpattern.value);
                                                    }}
                                                >
                                                    <div>Fill with {fillpattern.name.toLowerCase()}s</div>
                                                </div>
                                            ))}
                                        </div>
                                    </Popup>

                                    <Tooltip anchorSelect={'#detailsnavbar-rectangledetails-quantity2'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-end'>
                                        <div>
                                            <b>O.C. fill pattern</b>
                                        </div>
                                        <div>
                                            Select fill pattern for triangle or square
                                        </div>
                                    </Tooltip>
                                </>
                                : <>
                                    <DetailsNavbarInput keyID={'detailsnavbar-rectangledetails-quantity2'}>
                                        {width < 900
                                            ? <div className='detailsnavbar-row-name'>
                                                {UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][2]['quantity2'].toLowerCase().includes('length')
                                                    ? <IconRuler2 size={20} stroke={1} />
                                                    : UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][2]['quantity2'].toLowerCase().includes('area')
                                                        ? <IconRuler size={20} stroke={1} />
                                                        : UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][2]['quantity2'].toLowerCase().includes('volume')
                                                            ? <IconCube size={20} stroke={1} />
                                                            : UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][2]['quantity2'].toLowerCase().includes('tri')
                                                                || UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][2]['quantity2'].toLowerCase().includes('o.c.')
                                                                ? <IconComponents size={20} stroke={1} />
                                                                : <IconWeight size={20} stroke={1} />
                                                }
                                                {UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][2]['quantity2']?.split('(')?.pop()?.split(')')[0]}
                                            </div>
                                            : <div className={'detailsnavbar-row-name'}>
                                                {UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][2]['quantity2'].toLowerCase().includes('length')
                                                    ? <IconRuler2 size={20} stroke={1} />
                                                    : UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][2]['quantity2'].toLowerCase().includes('area')
                                                        ? <IconRuler size={20} stroke={1} />
                                                        : UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][2]['quantity2'].toLowerCase().includes('volume')
                                                            ? <IconCube size={20} stroke={1} />
                                                            : UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][2]['quantity2'].toLowerCase().includes('tri')
                                                                || UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][2]['quantity2'].toLowerCase().includes('o.c.')
                                                                ? <IconComponents size={20} stroke={1} />
                                                                : <IconWeight size={20} stroke={1} />
                                                }
                                                {UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][2]['quantity2']}
                                            </div>
                                        }
                                        <input
                                            className='detailsnavbar-input'
                                            id='detailsnavbar-rectangledetails-quantity2'
                                            type='number'
                                            value={measurements[selectedMeasurements[0]]?.quantity2 ? measurements[selectedMeasurements[0]]?.quantity2 : ''}
                                            onFocus={() => setEditingMeasurement(true)}
                                            onChange={e => updateValues('quantity2', e.target.value ? e.target.value : null)}
                                            onBlur={() => {
                                                handleSaveValues('quantity2', getValues('quantity2'));
                                                setEditingMeasurement(false);
                                            }}
                                            placeholder='--'
                                        />

                                    </DetailsNavbarInput>

                                    <Tooltip anchorSelect={'#detailsnavbar-rectangledetails-quantity2-container'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-end'>
                                        <div>
                                            <b>{UOM_Dependencies[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][2]['quantity2']}</b>
                                        </div>
                                        <div>
                                            {UOM_Dependencies_Description[measurements[selectedMeasurements[0]].uom_measured || UOM_Measured_Default[measurements[selectedMeasurements[0]]?.type]][measurements[selectedMeasurements[0]]?.uom][2]['quantity2']}
                                        </div>
                                    </Tooltip>
                                </>
                            : null
                        }

                        <div className='detailsnavbar-divider detailsnavbar-divider-multiselect'>
                            &nbsp;
                        </div>
                    </>
                }

                <GroupMenu
                    handleClick={(groupID) => {
                        handleSaveValues('group', groupID);
                    }}
                    measurement={getValues('group') || {
                        'id': null,
                        'group': null,
                    }}
                />

                <div
                    className='detailsnavbar-button'
                    id={`detailsnavbar-polygon-ungroup`}
                    onClick={() => {
                        for (let i = 0; i < selectedMeasurements.length; i++) {
                            if (measurements[selectedMeasurements[i]]?.group) {
                                handleSaveValues('group', null);
                                break
                            }
                        }
                    }}
                    style={{
                        color: selectedMeasurements.some(m => measurements[m]?.group) ? 'black' : 'lightgray',
                    }}
                    size={'small'}
                    disabled={selectedMeasurements.some(m => measurements[m]?.group) ? false : true}
                >
                    <IconFolderOff size={20} stroke={1} style={{ color: selectedMeasurements.some(m => measurements[m]?.group) ? 'black' : 'lightgray' }} />
                </div>

                <Tooltip anchorSelect={'#detailsnavbar-polygon-ungroup'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='bottom-end'>
                    {selectedMeasurements.some(m => measurements[m]?.group)
                        ? <>
                            <div>Ungroup measurements.</div>
                        </>
                        : <>
                            <div><b>
                                Ungroup measurements.
                            </b></div>
                            <div>
                                Measurements are not grouped.
                            </div>
                        </>
                    }
                </Tooltip>

                <div className='detailsnavbar-divider detailsnavbar-divider-multiselect'>
                    &nbsp;
                </div>

                <div
                    className='detailsnavbar-button'
                    id={`detailsnavbar-polygon-delete`}
                    onClick={() => setShowDeleteMeasurementModal(true)}
                    size={'small'}
                >
                    <IconTrashX size={20} stroke={1} style={{ color: 'red' }} />
                </div>

                <Tooltip anchorSelect={'#detailsnavbar-polygon-delete'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-end'>
                    Delete current selection
                </Tooltip>
            </div>

            <div
                className='detailsnavbar-button'
                id={`detailsnavbar-circle-close`}
                onClick={() => {
                    setSelectedMeasurements([]);
                    setCurrentMeasurement(null);
                    setShowDetails(false);
                }}
            >
                <IconX size={20} stroke={1} />
            </div>

            <Tooltip anchorSelect={'#detailsnavbar-circle-close'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-end'>
                Close (deselect all)
            </Tooltip>
        </>
    )
}
