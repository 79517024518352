import { useCallback, useContext, useMemo, useState } from 'react';
import { TakeoffContext } from '../helper/Context';
import '../styles/SelectedTotals.css';
import { IconCaretDownFilled, IconCaretRightFilled, IconChevronDown, IconChevronLeft, IconChevronRight, IconCircle, IconCircleArrowUpRight, IconCircleDot, IconCircles, IconPolygon, IconRectangle, IconSearch, IconTableExport, IconTimeline, IconTrashX, IconWindowMinimize, IconX } from '@tabler/icons-react';
import { Tooltip } from 'react-tooltip';
import { UOM, UOM_Display, UOM_Measured_Default, calculateValue } from '../helper/UnitConversions';
import Popup from 'reactjs-popup';
import axios from 'axios';
import { API_ROUTE } from '../..';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../redux/slices/authSlice';
import { Blocks } from 'react-loader-spinner';

export default function SelectedCalculatedTotals({ }) {
    const {
        project,
        takeoffSettings,
        measurements,
        selectedMeasurements,
        pages,
        showSelectedTotal, setShowSelectedTotal,
        currentMeasurement,
        selectedCalculatedTotals,
        groups,
    } = useContext(TakeoffContext);

    const [updating, setUpdating] = useState(false);

    let right = takeoffSettings?.show_measurement_sidebar && takeoffSettings?.measurement_sidebar_location === 'right' && document.getElementById('measurementsidebar')
        ? document.getElementById('measurementsidebar').offsetWidth * 1.01 + 5
        : takeoffSettings?.show_pages_sidebar && takeoffSettings?.pages_sidebar_location === 'right' && document.getElementById('pagessidebar')
            ? document.getElementById('pagessidebar').offsetWidth * 1.01 + 5
            : takeoffSettings?.show_ai_sidebar && takeoffSettings?.ai_sidebar_location === 'right' && document.getElementById('aisidebar')
                ? document.getElementById('aisidebar').offsetWidth * 1.01 + 5
                : '5px'

    const handleExportTable = () => {
        //export csv of selectedCalculatedTotals
        const csv = [];
        const headers = ['Name', 'Value', 'Total Value'];
        csv.push(headers.join(','));

        const data = selectedCalculatedTotals?.tree;
        const measurements_values = selectedCalculatedTotals?.measurements;

        console.log(selectedCalculatedTotals);

        const most_common_uom = (group) => {
            let uoms = {}
            Object.values(measurements).filter(m => m?.path?.includes('group-' + group?.id)).forEach(m => {
                if (m?.uom) {
                    if (!uoms[m?.uom]) uoms[m?.uom] = 0
                    uoms[m?.uom] += 1
                } else {
                    if (!uoms[UOM_Measured_Default[m?.type]]) uoms[UOM_Measured_Default[m?.type]] = 0
                    uoms[UOM_Measured_Default[m?.type]] += 1
                }
            })

            if (Object.keys(uoms).length === 0) return []
            return Object.keys(uoms)?.reduce((a, b) => uoms[a] > uoms[b] ? a : b)
        }

        const get_uom_total = (group, uom) => {
            let total = 0
            Object.values(measurements).filter(m => m?.path?.includes('group-' + group?.id)).forEach(m => {
                if (m?.uom === uom || !m?.uom && UOM_Measured_Default[m?.type] === uom) {
                    total += calculateValue({
                        ...m,
                        value: measurements_values?.[m.id],
                    }, pages[m?.page]?.scale)
                }
            })

            return total;
        }

        //recursively go through tree and add to csv, for each level nested, the name with have a '>' prepended. the value of a measurement will be the value of the measurement, the value of a group will be in the total values column
        const add_to_csv = (data) => {
            data.forEach(item => {
                if (item.id.includes('group')) {
                    let name = ''
                    for (let i = 0; i < item.path.split('/').length - 2; i++) {
                        name += '>';
                    }
                    name += item.name;

                    let group = groups[item.id.split('-')[1]];
                    let uom = group.uom || most_common_uom(group);
                    let group_value = get_uom_total(group, uom).toFixed(2) + ' ' + UOM_Display[uom];

                    csv.push(([name, '', group_value]).join(','));
                    add_to_csv(item.children);
                } else if (item.id.includes('measurement') && item.value) {
                    let measurement = item.id.split('-')[1];
                    let name = '';
                    for (let i = 0; i < item.path.split('/').length - 2; i++) {
                        name += '>';
                    }
                    name += measurements[measurement]?.name;

                    let value = calculateValue({
                        ...measurements[measurement],
                        value: item.value,
                    }, pages[measurements[measurement]?.page]?.scale).toFixed(2) + ' ' + (UOM_Display[measurements[measurement]?.uom || UOM_Measured_Default[measurements[measurement]?.type]])

                    csv.push([name, value, ''].join(','));
                }
            })
        }

        add_to_csv(data);

        const csvData = new Blob([csv.join('\n')], { type: 'text/csv' });
        const csvUrl = URL.createObjectURL(csvData);
        const a = document.createElement('a');
        a.href = csvUrl;
        a.download = 'selected_calculated_totals.csv';
        a.click();
    }

    const handleExportCountBreakdown = () => {
        const csv = [];
        const headers = ['Name', 'Value'];
        csv.push(headers.join(','));

        const count_breakdown = selectedCalculatedTotals?.count_breakdown;

        Object.keys(count_breakdown).forEach(item => {
            csv.push([measurements[item]?.name, count_breakdown[item].length].join(','));
        })

        const csvData = new Blob([csv.join('\n')], { type: 'text/csv' });
        const csvUrl = URL.createObjectURL(csvData);
        const a = document.createElement('a');
        a.href = csvUrl;
        a.download = 'selected_calculated_totals_count_breakdown.csv';
        a.click();
    }

    return (
        <>
            <div
                className="selectedtotals-container"
                style={{
                    right: right,
                    top: (currentMeasurement || selectedMeasurements.length > 1) && '45px',
                }}
            >
                <div className="selectedtotals-header">
                    <div className="selectedtotals-title">
                        Calculated selection
                    </div>

                    {selectedCalculatedTotals &&
                        <div>
                            <div
                                className='selectedtotals-export-button'
                                onClick={() => handleExportTable()}
                            >
                                <IconTableExport size={16} stroke={1} />
                                Export
                            </div>
                        </div>
                    }
                </div>

                <div className="selectedtotals-content">
                    {selectedCalculatedTotals
                        ?
                        <>
                            <RenderTree data={selectedCalculatedTotals.tree} measurements_values={selectedCalculatedTotals.measurements} />
                        </>
                        : <div>
                            Waiting for selection
                        </div>
                    }

                    {/*<>
                        <div className="selectedtotals-content-row">
                            <div className="selectedtotals-content-row-title">
                                ea
                            </div>
                            <div className="selectedtotals-content-row-value">
                                {selectedCalculatedTotals?.count}
                            </div>
                        </div>
                        <div className="selectedtotals-content-row">
                            <div className="selectedtotals-content-row-title">
                                ft
                            </div>
                            <div className="selectedtotals-content-row-value">
                                {selectedCalculatedTotals?.length?.toFixed(2)}
                            </div>
                        </div>
                        <div className="selectedtotals-content-row">
                            <div className="selectedtotals-content-row-title">
                                ft²
                            </div>
                            <div className="selectedtotals-content-row-value">
                                {selectedCalculatedTotals?.area?.toFixed(2)}
                            </div>
                        </div>
                    </>*/}
                </div>

                {updating ?
                    <div className="selectedtotals-count-breakdown-container">
                        <div className="selectedtotals-count-breakdown-title">
                            Count breakdown
                        </div>

                        <div className="selectedtotals-updating">
                            <div>
                                <Blocks
                                    visible={true}
                                    height="20"
                                    width="20"
                                    color="#006AFE"
                                    ariaLabel="blocks-loading"
                                    radius="10"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                />
                            </div>
                        </div>
                    </div>
                    : null}

                {selectedCalculatedTotals?.count && !updating ?
                    <div className="selectedtotals-count-breakdown-container">
                        <div className="selectedtotals-header">
                            <div className="selectedtotals-count-breakdown-title">
                                Count breakdown
                            </div>

                            <div>
                                <div
                                    className='selectedtotals-export-button'
                                    onClick={() => handleExportCountBreakdown()}
                                >
                                    <IconTableExport size={16} stroke={1} />
                                    Export
                                </div>
                            </div>
                        </div>
                        <div>
                            {Object.keys(selectedCalculatedTotals?.count_breakdown || {}).map((item, index) =>
                                <CountBreakdownItem item={item} index={index} setUpdating={setUpdating} />
                            )}
                        </div>
                    </div>
                    : null}
            </div>

        </>
    )
}

const RenderTree = ({ data, measurements_values }) => {
    const {
        project,
        takeoffSettings,
        measurements,
        groups,
        pages,
        showSelectedTotal, setShowSelectedTotal,
        setCurrentMeasurement,
    } = useContext(TakeoffContext);

    return (
        <>
            {data.map((item, index) => {
                if (item.id.includes('group')) {
                    let group = groups[item.id.split('-')[1]];

                    if (group) {
                        return (
                            <Group group={group} item={item} measurements_values={measurements_values} />
                        )
                    }
                } else if (item.id.includes('measurement')) {
                    let measurement = measurements[item.id.split('-')[1]];

                    if (item.value) {
                        return (
                            <div
                                className="selectedtotals-content-row"
                                onMouseEnter={() => {
                                    setCurrentMeasurement(measurement.id);
                                }}
                                style={{
                                }}
                            >
                                <div className="selectedtotals-content-row-left">
                                    {item.path.split('/').slice(0, -2).map((i, index) =>
                                        <div
                                            key={index}
                                            style={{
                                                height: '30px',
                                                width: '2px',
                                                marginLeft: '7px',
                                                borderLeft: '1px solid',
                                                borderLeftColor: groups[i.split('-')[1]]?.color,
                                            }}
                                        >
                                            &nbsp;
                                        </div>
                                    )}
                                    <div>
                                        {measurement?.type === 'count'
                                            ? <IconCircles size={12} color={measurement?.color} stroke={1.5} />
                                            : measurement?.type === 'line'
                                                ? <IconTimeline size={12} color={measurement?.color} stroke={1.5} />
                                                : measurement?.type === 'circle'
                                                    ? <IconCircleDot size={12} color={measurement?.color} stroke={1.5} />
                                                    : measurement?.type === 'polygon'
                                                        ? <IconPolygon size={12} color={measurement?.color} stroke={1.5} />
                                                        : measurement?.type === 'rectangle'
                                                            ? <IconRectangle size={12} color={measurement?.color} stroke={1.5} />
                                                            : null}
                                    </div>
                                    <div className="selectedtotals-content-row-title"
                                        style={{
                                            width: 0.2 * window.innerWidth - 10 * item.path.split('-').length - 10 - 120 + 'px',
                                            minWidth: '0px'
                                        }}
                                    >
                                        {measurement?.name}
                                    </div>
                                </div>
                                <div className="selectedtotals-content-row-value">
                                    {calculateValue({
                                        ...measurement,
                                        value: item.value,
                                    }, pages[measurement.page]?.scale)?.toFixed(2)} {UOM_Display[measurement?.uom || UOM_Measured_Default[measurement?.type]]}
                                </div>
                            </div>
                        )
                    }
                }
            })}
        </>
    )
}

const Group = ({ group, item, measurements_values }) => {
    const {
        project,
        takeoffSettings,
        measurements,
        groups,
        pages,
        showSelectedTotal, setShowSelectedTotal,
    } = useContext(TakeoffContext);

    const [showChildren, setShowChildren] = useState(true);

    const most_common_uom = useMemo(() => {
        //loop through all measurements and add their uom to set
        let uoms = {}
        Object.values(measurements).filter(m => m?.path?.includes('group-' + group?.id)).forEach(m => {
            if (m?.uom) {
                if (!uoms[m?.uom]) uoms[m?.uom] = 0
                uoms[m?.uom] += 1
            } else {
                if (!uoms[UOM_Measured_Default[m?.type]]) uoms[UOM_Measured_Default[m?.type]] = 0
                uoms[UOM_Measured_Default[m?.type]] += 1
            }
        })

        //console.log(uoms)

        if (Object.keys(uoms).length === 0) return []

        //return the 1 uom that appears the most
        return Object.keys(uoms)?.reduce((a, b) => uoms[a] > uoms[b] ? a : b)
    }, [measurements, group])

    const get_uom_total = (uom) => {
        let total = 0
        Object.values(measurements).filter(m => m?.path?.includes('group-' + group?.id)).forEach(m => {
            if (m?.uom === uom || !m?.uom && UOM_Measured_Default[m?.type] === uom) {
                total += calculateValue({
                    ...m,
                    value: measurements_values?.[m.id],
                }, pages[m?.page]?.scale)
            }
        })

        return total;
    }

    console.log(group)

    return (
        <>
            <div className="selectedtotals-content-row"
                onClick={() => setShowChildren(prev => !prev)}
            >
                <div className="selectedtotals-content-row-left"
                    style={{
                    }}
                >
                    {item.path.split('/').slice(0, -2).map((i, index) =>
                        <div
                            key={index}
                            style={{
                                height: '30px',
                                width: '2px',
                                marginLeft: '7px',
                                borderLeft: '1px solid',
                                borderLeftColor: String(groups[i.split('-')[1]]?.color),
                            }}
                        >
                            &nbsp;
                        </div>
                    )}
                    <div
                        style={{
                            color: group?.color,
                        }}
                    >
                        {showChildren
                            ? <IconCaretDownFilled size={16} stroke={1.5} />
                            : <IconCaretRightFilled size={16} stroke={1.5} />
                        }
                    </div>
                    <div className="selectedtotals-content-row-title"
                        style={{
                            width: 0.2 * window.innerWidth - 10 * item.path.split('-').length - 10 - 120 + 'px',
                            minWidth: '0px'

                        }}
                    >
                        {group?.name}
                    </div>
                </div>
                <div className="selectedtotals-content-row-value"
                    style={{
                        backgroundColor: 'aliceblue',
                        borderRadius: '3px',
                        width: '100px',
                    }}
                >
                    {group.uom && get_uom_total(group.uom)
                        ? <nobr>
                            {get_uom_total(group.uom).toFixed(2)} {UOM_Display[group.uom]}
                        </nobr>
                        : <nobr>
                            {get_uom_total(most_common_uom).toFixed(2)} {UOM_Display[most_common_uom]}
                        </nobr>
                    }
                </div>
            </div>

            {showChildren && item.children &&
                <RenderTree data={item.children} measurements_values={measurements_values} />
            }
        </>
    )
}

const CountBreakdownItem = ({ item, index, setUpdating }) => {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        project,
        takeoffSettings,
        measurements, setMeasurements,
        selectedMeasurements,
        pages,
        showSelectedTotal, setShowSelectedTotal,
        currentMeasurement, setCurrentMeasurement,
        selectedCalculatedTotals, setSelectedCalculatedTotals,
        handleCountTransform,
    } = useContext(TakeoffContext);

    const [showModal, setShowModal] = useState(false);

    const [search, setSearch] = useState('');
    const [showGroupOptions, setShowGroupOptions] = useState(false);

    const changeCount = (measurementID) => {
        setUpdating(true);

        axios({
            'method': 'post',
            'url': `${API_ROUTE}/api/change-dot-count/`,
            'data': {
                'dot': selectedCalculatedTotals?.count_breakdown[item][0],
                'count': measurementID,
                'selectedDots': selectedCalculatedTotals?.count_breakdown[item],
            },
            'headers': {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((res) => {
                console.log(res);

                setMeasurements(res.data.measurements);

                axios({
                    method: 'put',
                    url: `${API_ROUTE}/api/calculate-selection/`,
                    data: {
                        'page': pageID,
                        'selection_polygon': selectedCalculatedTotals?.poly,
                    },
                    headers: {
                        'Authorization': `Token ${auth.token}`,
                        "Content-Type": "application/json"
                    },
                })
                    .then((calculate_response) => {
                        console.log(calculate_response);

                        setSelectedCalculatedTotals(prev => ({
                            ...prev,
                            'count': calculate_response.data.count,
                            'measurements': calculate_response.data.measurements,
                            'tree': calculate_response.data.tree,
                            'count_breakdown': calculate_response.data.count_breakdown,
                        }));
                        setUpdating(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((err) => {
                console.log(err);
            })
    }

    return (
        <div
            key={index}
            className="selectedtotals-count-breakdown-row"
            style={{
                backgroundColor: showModal && 'aliceblue',
            }}
            onContextMenu={(e) => {
                e.preventDefault();
                setShowModal(true);
            }}
            onMouseEnter={() => {
                setCurrentMeasurement(item);
            }}
        >
            <div>
                <IconCircles
                    id={'measurement-icon-' + item + '-count'}
                    size={12} color={measurements[item]?.color} stroke={1.5}
                    style={{
                        outline: 'none',
                    }}
                />
            </div>

            <div className="selectedtotals-count-breakdown-row-title"
                id={`selectedtotals-count-breakdown-row-title-${item}`}
            >
                {measurements[item]?.name}
            </div>

            <Tooltip className='tooltip' anchorSelect={`#selectedtotals-count-breakdown-row-title-${item}`} delayShow={500} place={'bottom-end'}>
                {measurements[item]?.name}
            </Tooltip>

            <div className="selectedtotals-count-breakdown-row-value">
                {selectedCalculatedTotals?.count_breakdown[item].length}
            </div>

            <Popup
                trigger={
                    <div
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            width: '0px',
                            height: '0px',
                            color: 'transparent',
                        }}
                    >
                        &nbsp;
                    </div>
                }
                open={showModal}
                on=''
                onClose={() => {
                    setShowModal(false);
                }}
                position={'bottom left'}
                closeOnDocumentClick
                mouseLeaveDelay={300}
                mouseEnterDelay={0}
                arrow={false}
                style={{
                    width: '200px',
                    padding: '0px',
                }}
            >
                <div className="pagessidebar-page-options-container">
                    <div
                        className="pagessidebar-page-options-section-changecount pagessidebar-page-options-section-item"
                        onMouseEnter={() => setShowGroupOptions(true)}
                        onMouseLeave={() => setShowGroupOptions(false)}
                    >
                        <div className="pagessidebar-page-options-section-icon">
                            <IconCircleArrowUpRight size={15} stroke={1} />
                        </div>
                        <div className="pagessidebar-page-options-section-text">
                            Change count
                        </div>
                        <div className="pagessidebar-page-options-section-icon">
                            {takeoffSettings?.measurement_sidebar_location === 'left' ? <IconChevronRight size={15} stroke={1} /> : <IconChevronLeft size={15} stroke={1} />}
                        </div>

                        {showGroupOptions &&
                            <div
                                className={
                                    takeoffSettings?.measurement_sidebar_location === 'left'
                                        ? "pagessidebar-page-options-group-options"
                                        : "pagessidebar-page-options-group-options-right"
                                }
                            >
                                <div className="pagessidebar-page-options-group-search-container">
                                    <IconSearch size={15} stroke={1} />
                                    <input
                                        type='text'
                                        className='pagessidebar-page-options-group-search-input'
                                        placeholder='Search...'
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </div>

                                <div className="pagessidebar-page-options-group-options-container">
                                    {measurements && Object.values(measurements).filter(m => m.type === 'count').filter(m => {
                                        if (search) {
                                            return m.name.toLowerCase().includes(search.toLowerCase());
                                        }
                                        return true;
                                    }).filter(m => String(m.page) === String(pageID)).map((m, index) =>
                                        <div
                                            key={m.id + 'count' + item}
                                            id={'pagessidebar-page-options-group-option' + m.id + 'count' + item}
                                            className="pagessidebar-page-options-group-option"
                                            onClick={() => {
                                                changeCount(m.id);
                                                setShowModal(false);
                                            }}
                                            style={{
                                                backgroundColor: m.id == item && 'aliceblue'
                                            }}
                                        >
                                            <div
                                                className={item == m.id
                                                    ? "pagessidebar-page-options-group-option-icon-selected"
                                                    : "pagessidebar-page-options-group-option-icon"
                                                }
                                            >
                                                &nbsp;
                                            </div>

                                            <div>
                                                <IconCircles
                                                    id={'measurement-icon-' + m.id + '-count'}
                                                    size={12} color={m?.color} stroke={1.5}
                                                    style={{
                                                        outline: 'none',
                                                    }}
                                                />
                                            </div>

                                            <div className="pagessidebar-page-options-group-option-text">
                                                {m.name}</div>

                                            <Tooltip className='tooltip' anchorSelect={'#pagessidebar-page-options-group-option' + m.id + 'count' + item} delayShow={500} place={takeoffSettings?.measurement_sidebar_location === 'left' ? 'right' : 'left'}>
                                                {m.id == item
                                                    ? <>Currently selected</>
                                                    : <>Change count to {m.name}</>}
                                            </Tooltip>
                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                    </div>

                    <div
                        className="pagessidebar-page-options-section-delete"
                        onClick={(e) => {
                            const newMeasurement = {
                                ...measurements[item],
                                count_dots: measurements[item].count_dots.filter(d => !selectedCalculatedTotals?.count_breakdown[item].includes(d)),
                            }

                            handleCountTransform(newMeasurement, false, 'group-delete', null, null, selectedCalculatedTotals?.count_breakdown[item]);

                            setSelectedCalculatedTotals(prev => {
                                const newCountBreakdown = { ...prev.count_breakdown };
                                delete newCountBreakdown[item];

                                return {
                                    ...prev,
                                    count: prev.count - selectedCalculatedTotals?.count_breakdown[item].length,
                                    count_breakdown: newCountBreakdown,
                                }
                            })

                            e.stopPropagation();
                            e.preventDefault();
                            setShowModal(false);
                        }}
                    >
                        <div className="pagessidebar-page-options-section-icon">
                            <IconTrashX size={15} stroke={1} />
                        </div>
                        <div className="pagessidebar-page-options-section-text">
                            Delete
                        </div>
                    </div>
                </div>
            </Popup>
        </div>
    )
}