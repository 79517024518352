import { useSelector } from 'react-redux';
import { axiosCurry, createLookupByKeyName, eventCheckedLens, eventFilesLens, eventValueLens, formDataFromObj, mapIndexed, responseDataLens } from '../utilities/utilities';
import { selectAuth } from '../redux/slices/authSlice';
import { useEffect, useRef, useState } from 'react';
import {
  add,
  always,
  and,
  andThen,
  append,
  compose,
  defaultTo,
  equals,
  F,
  filter,
  flatten,
  head,
  identity,
  ifElse,
  isEmpty,
  isNil,
  last,
  length,
  lensProp,
  map,
  mergeDeepLeft,
  over,
  pipe,
  reject,
  set,
  sortBy,
  splitEvery,
  view,
} from 'ramda';
import { API_ROUTE } from '..';
import { Button, ButtonGroup, Col, Form, ProgressBar, Row, Stack } from 'react-bootstrap';
import { IconAlertSquare, IconTrash } from '@tabler/icons-react';
import { Document, View, Text, PDFViewer, Page, Image as PDFImage, pdf } from '@react-pdf/renderer';
import ReactQuill from 'react-quill';
import axios from 'axios';
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { StyledSortableRow } from '../files/Components';
import { v4 as uuidv4 } from 'uuid';
import Html from 'react-pdf-html';
import { PDFDocument } from 'pdf-lib';
import { saveByteArray } from '../pdfexport/utilities';
import { dfs } from '../estimate/EstimateUtilities';
import { Table, TD, TH, TR } from '@ag-media/react-pdf-table';
import ExcellentExport from 'excellentexport';

const basePageStyle = { padding: '25px' };
const baseCellStyle = {
  padding: '10px',
  textAlign: 'center',
  fontSize: '9px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};
const baseVerticalPaddedViewStyle = { display: 'flex', flexDirection: 'column', gap: 2, paddingTop: '10px', paddingBottom: '10px' };
const pageNumberStyle = { position: 'absolute', fontSize: 12, top: 5, left: 0, right: 5, textAlign: 'right', color: 'grey' };

const isInitialFetchCompleteLens = lensProp('isInitialFetchComplete');

const exportTypeLens = lensProp('exportType');

const isLogoIncludedLens = lensProp('isLogoIncluded');
const isTitlePageIncludedLens = lensProp('isTitlePageIncluded');
const isPreambleIncludedLens = lensProp('isPreambleIncluded');
const isOnlyExportingSelectedRows = lensProp('isOnlyExportingSelectedRows');
const isPostambleIncludedLens = lensProp('isPostambleIncluded');
const isAttachmentsIncludedLens = lensProp('isAttachmentsIncluded');

const logoFileUploadLens = lensProp('logoFileUpload');
const logoFileUploadIsInProgressLens = compose(logoFileUploadLens, lensProp('isInProgress'));
const logoFileUploadProgressLens = compose(logoFileUploadLens, lensProp('progress'));
const titlePageFileUploadLens = lensProp('titlePageFileUpload');
const titlePageFileUploadIsInProgressLens = compose(titlePageFileUploadLens, lensProp('isInProgress'));
const titlePageFileUploadProgressLens = compose(titlePageFileUploadLens, lensProp('progress'));
const attachmentFileUploadUuidsLens = lensProp('attachmentFileUploadUuids');

const nodesLens = lensProp('nodes');

const pdfExportStatusLens = lensProp('pdfExportStatus');
const pdfExportStatusIsExportingLens = compose(pdfExportStatusLens, lensProp('isExporting'));
const pdfExportStatusNumTotalFilesLens = compose(pdfExportStatusLens, lensProp('numTotalFiles'));
const pdfExportStatusNumFilesFetchedLens = compose(pdfExportStatusLens, lensProp('numFilesFetched'));
const pdfExportStatusNumFilesProcessedLens = compose(pdfExportStatusLens, lensProp('numFilesProcessed'));
const pdfExportStatusMessageLens = compose(pdfExportStatusLens, lensProp('message'));

const getNodeLens = (uuid) => compose(nodesLens, lensProp(uuid));
const getNodePreambleLens = (uuid) => compose(getNodeLens(uuid), lensProp('preamble'));
const getNodePostambleLens = (uuid) => compose(getNodeLens(uuid), lensProp('postamble'));
const getNodeLogoNameLens = (uuid) => compose(getNodeLens(uuid), lensProp('logo_name'));
const getNodeLogoFileKeyLens = (uuid) => compose(getNodeLens(uuid), lensProp('logo_file_key'));
const getNodeLogoFileUrlLens = (uuid) => compose(getNodeLens(uuid), lensProp('logo_file_url'));
const getNodeTitlePageNameLens = (uuid) => compose(getNodeLens(uuid), lensProp('title_page_name'));
const getNodeTitlePageFileKeyLens = (uuid) => compose(getNodeLens(uuid), lensProp('title_page_file_key'));
const getNodeTitlePageFileUrlLens = (uuid) => compose(getNodeLens(uuid), lensProp('title_page_file_url'));
const getNodePdfExportAttachmentUuidsLens = (uuid) => compose(getNodeLens(uuid), lensProp('pdf_export_attachment_uuids'));
const getNodeNameLens = (uuid) => compose(getNodeLens(uuid), lensProp('name'));
const getNodeProgressLens = (uuid) => compose(getNodeLens(uuid), lensProp('progress'));
const getNodeIndexLens = (uuid) => compose(getNodeLens(uuid), lensProp('index'));
const getNodeFileUrlLens = (uuid) => compose(getNodeLens(uuid), lensProp('file_url'));

const initialState = {
  nodes: {},
  isInitialFetchComplete: false,
  exportType: 'pdf',
  isTitlePageIncluded: false,
  isPreambleIncluded: false,
  isOnlyExportingSelectedRows: false,
  isPostambleIncluded: false,
  isAttachmentsIncluded: false,
  logoFileUpload: { isInProgress: false, progress: 0 },
  titlePageFileUpload: { isInProgress: false, progress: 0 },
  attachmentFileUploadUuids: [],
  pdfExportStatus: {
    isExporting: false,
    message: '',
    numTotalFiles: 1,
    numFilesFetched: 0,
    numFilesProcessed: 0,
  },
};

const clearLogoFile = (projectUuid) =>
  pipe(set(getNodeLogoNameLens(projectUuid))(null), set(getNodeLogoFileKeyLens(projectUuid))(''), set(getNodeLogoFileUrlLens(projectUuid))(null), set(isLogoIncludedLens)(false));
const clearTitlePageFile = (projectUuid) =>
  pipe(set(getNodeTitlePageNameLens(projectUuid))(null), set(getNodeTitlePageFileKeyLens(projectUuid))(''), set(getNodeTitlePageFileUrlLens(projectUuid))(null), set(isTitlePageIncludedLens)(false));
const addAttachmentUpload = (attachmentUuid) =>
  pipe(set(getNodeProgressLens(attachmentUuid))(0), set(getNodeNameLens(attachmentUuid))(''), over(attachmentFileUploadUuidsLens)(append(attachmentUuid)));
const deleteAttachmentUpload = (attachmentUuid) => over(attachmentFileUploadUuidsLens)(reject(equals(attachmentUuid)));
const deleteAttachment = (projectUuid) => (attachmentUuid) => (state) => {
  const newAttachments = pipe(getNodePdfExportAttachmentUuidsLens, (l) => view(l)(state), reject(equals(attachmentUuid)))(projectUuid);
  const conditionalExcludeAttachments = ifElse(isEmpty, always(set(isAttachmentsIncludedLens)(false)), always(identity))(newAttachments);
  return pipe(set(getNodePdfExportAttachmentUuidsLens(projectUuid))(newAttachments), conditionalExcludeAttachments)(state);
};

const resetPdfExportStatus = pipe(
  set(pdfExportStatusIsExportingLens)(false),
  set(pdfExportStatusNumTotalFilesLens)(1),
  set(pdfExportStatusNumFilesFetchedLens)(0),
  set(pdfExportStatusNumFilesProcessedLens)(0),
  set(pdfExportStatusMessageLens)('')
);

export const PDFExport2 = ({
  // values
  projectUuid,
  projectPrice,
  visibleColumns,
  sortedVisibleIds,
  children,

  //column methods
  getColumnHeader,

  //node id methods
  getIsNodeSelected,
  getNodeDepth,
  getNodeType,
  getNodeVisibleChildren,

  //column, node id methods
  getPdfCellValue,
  getTableCellValue,

  //helpers
  handleExcludeAllColumns,
  onHide,
}) => {
  const auth = useSelector(selectAuth);
  const headers = {
    Authorization: `Token ${auth.token}`,
    'Content-Type': 'application/json',
  };
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const [state, setState] = useState(initialState);
  const viewState = (lens) => view(lens)(state);

  const columnSelectionRef = useRef(null);
  const logoFileUploadRef = useRef(null);
  const titlePageUploadRef = useRef(null);
  const attachmentUploadInputRef = useRef(null);

  const pdfExportApi = axiosCurry(`${API_ROUTE}/api/pdf-export-api2/${projectUuid}/`)(headers)('post');
  const getAWSPresignedHeaders = (filename) =>
    pipe(axiosCurry(`${API_ROUTE}/api/aws-presigned-headers-api/`)({ Authorization: `Token ${auth.token}` })('post'), andThen(view(responseDataLens)))({ filename });

  // Effects ========================================================================================
  // initial fetch
  useEffect(() => {
    pipe(
      pdfExportApi,
      andThen(view(responseDataLens)),
      andThen(last),
      andThen(createLookupByKeyName('uuid')),
      andThen((nodes) => setState(pipe(set(nodesLens)(nodes), set(isInitialFetchCompleteLens)(true))))
    )([{ operation: 'pdf_export_attachments_generate_uuids' }, { operation: 'project_get' }]);
  }, [projectUuid]);

  // Calculated Values ==============================================================================
  const isColumnCountExceeded = visibleColumns.length > 9;
  const selectedIds = filter(getIsNodeSelected)(sortedVisibleIds);
  const selectedIdAndDescendantSet = pipe(dfs(getNodeVisibleChildren), flatten, (visibleIds) => new Set(visibleIds))(selectedIds);
  const toExportIds = ifElse(viewState, always(filter((nodeId) => selectedIdAndDescendantSet.has(nodeId))(sortedVisibleIds)), always(sortedVisibleIds))(isOnlyExportingSelectedRows);
  const chunkedNodeIds = splitEvery(24)(toExportIds);

  const ImageElement = <PDFImage style={{ maxHeight: '60px', maxWidth: '60px' }} source={pipe(getNodeLogoFileUrlLens, viewState)(projectUuid)} />;
  const ImageView = ifElse(
    viewState,
    always(
      <View fixed style={{ display: 'flex', direction: 'row', justifyContent: 'flex-end', width: '100%', paddingBottom: '5px' }}>
        {ImageElement}
      </View>
    ),
    F
  )(isLogoIncludedLens);
  const mainEsimatePDFDocument = (
    <Document>
      {ifElse(
        viewState,
        always(
          <Page size='A4' style={basePageStyle}>
            {ImageView}
            <Text style={pageNumberStyle} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
            <View style={baseVerticalPaddedViewStyle}>
              <Html style={{ fontSize: '9px' }}>{`<html>${pipe(getNodePreambleLens, viewState)(projectUuid)}</html>`}</Html>
            </View>
          </Page>
        ),
        F
      )(isPreambleIncludedLens)}

      {
        <Page style={basePageStyle}>
          {ImageView}
          <Text style={pageNumberStyle} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
          <Table>
            <TH fixed>
              {map((column) => (
                <TD style={{ ...baseCellStyle, backgroundColor: '#eeeeee' }} key={column}>
                  {getColumnHeader(column)}
                </TD>
              ))(['name', ...visibleColumns])}
            </TH>
            {map((nodeId) => (
              <TR key={nodeId}>
                <TD
                  style={{
                    ...baseCellStyle,
                    backgroundColor: pipe(getNodeType, ifElse(equals('group'), always('#f0f8ff'), always('white')))(nodeId),
                  }}
                >
                  <Text style={{ paddingLeft: `${getNodeDepth(nodeId) * 10}px` }}>{getPdfCellValue('name')(nodeId)}</Text>
                </TD>
                {map((column) => (
                  <TD
                    style={{
                      ...baseCellStyle,
                      backgroundColor: pipe(getNodeType, ifElse(equals('group'), always('#f0f8ff'), always('white')))(nodeId),
                    }}
                    key={column}
                  >
                    {getPdfCellValue(column)(nodeId)}
                  </TD>
                ))(visibleColumns)}
              </TR>
            ))(toExportIds)}
          </Table>
          <View style={{ marginTop: '10px', ...baseVerticalPaddedViewStyle }}>
            <Text style={{ fontSize: 14 }}>Price: ${(Math.round(projectPrice * 100) / 100).toFixed(2)}</Text>
          </View>
        </Page>
      }

      {ifElse(
        viewState,
        always(
          <Page size='A4' style={basePageStyle}>
            {ImageView}
            <Text style={pageNumberStyle} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
            <View style={{ ...baseVerticalPaddedViewStyle }}>
              <Html style={{ fontSize: '9px' }}>{`<html>${pipe(getNodePostambleLens, viewState)(projectUuid)}</html>`}</Html>
            </View>
          </Page>
        ),
        F
      )(isPostambleIncludedLens)}
    </Document>
  );

  const sortedAttachmentUuids = pipe(getNodePdfExportAttachmentUuidsLens, viewState, defaultTo([]), sortBy(pipe(getNodeIndexLens, viewState)))(projectUuid);

  // Handlers (with side effects) ===================================================================
  const handlePreambleBlur = (e, f, g) => {
    const preamble = g.getHTML();
    pipe(getNodePreambleLens, (l) => set(l)(preamble), setState)(projectUuid);
    pdfExportApi([{ operation: 'project_update', preamble }]);
  };

  const handlePostambleBlur = (e, f, g) => {
    const postamble = g.getHTML();
    pipe(getNodePostambleLens, (l) => set(l)(postamble), setState)(projectUuid);
    pdfExportApi([{ operation: 'project_update', postamble }]);
  };

  const handleUploadFileToS3 = (onUploadProgress) => (file) => {
    const filename = file.name;
    return pipe(
      getAWSPresignedHeaders,
      andThen(({ url, fields }) => {
        const { key } = fields;
        return pipe(axios.post, andThen(always(key)))(url, formDataFromObj({ ...fields, file: file }), { onUploadProgress });
      })
    )(filename);
  };

  const handleLogoFileUpload = (file) => {
    const filename = file.name;
    const onUploadProgress = ({ progress }) => pipe(set(logoFileUploadProgressLens), setState)(progress * 100);
    setState(pipe(set(logoFileUploadIsInProgressLens)(true), set(getNodeLogoNameLens(projectUuid))(filename)));
    return pipe(
      handleUploadFileToS3(onUploadProgress),
      andThen((key) => pdfExportApi([{ operation: 'project_update', logo_file: key, logo_name: filename }, { operation: 'project_get' }])),
      andThen(view(responseDataLens)),
      andThen(last),
      andThen(createLookupByKeyName('uuid')),
      andThen((nodes) => setState(pipe(set(nodesLens)(nodes), set(logoFileUploadIsInProgressLens)(false), set(logoFileUploadProgressLens)(0))))
    )(file);
  };

  const handleTitlePageFileUpload = (file) => {
    const filename = file.name;
    const onUploadProgress = ({ progress }) => pipe(set(titlePageFileUploadProgressLens), setState)(progress * 100);
    setState(pipe(set(titlePageFileUploadIsInProgressLens)(true), set(getNodeTitlePageNameLens(projectUuid))(filename)));
    return pipe(
      handleUploadFileToS3(onUploadProgress),
      andThen((key) => pdfExportApi([{ operation: 'project_update', title_page_file: key, title_page_name: filename }, { operation: 'project_get' }])),
      andThen(view(responseDataLens)),
      andThen(last),
      andThen(createLookupByKeyName('uuid')),
      andThen((nodes) => setState(pipe(set(nodesLens)(nodes), set(titlePageFileUploadIsInProgressLens)(false), set(titlePageFileUploadProgressLens)(0))))
    )(file);
  };

  const handleAttachmentUpload = (file) => {
    const attachmentUuid = uuidv4();
    const filename = file.name;
    const onUploadProgress = ({ progress }) => pipe(set(getNodeProgressLens(attachmentUuid)), setState)(progress * 100);
    setState(pipe(addAttachmentUpload(attachmentUuid), set(getNodeNameLens(attachmentUuid))(filename)));
    return pipe(
      handleUploadFileToS3(onUploadProgress),
      andThen((key) => pdfExportApi([{ operation: 'pdf_export_attachment_create', file: key, name: filename }, { operation: 'project_get' }])),
      andThen(view(responseDataLens)),
      andThen(last),
      andThen(createLookupByKeyName('uuid')),
      andThen((nodes) => setState(pipe(over(nodesLens)(mergeDeepLeft(nodes)), deleteAttachmentUpload(attachmentUuid))))
    )(file);
  };

  const handleAttachmentDelete = (attachmentUuid) => {
    pdfExportApi([{ operation: 'pdf_export_attachment_delete', uuid: attachmentUuid }]);
    setState(deleteAttachment(projectUuid)(attachmentUuid));
  };

  const handleAttachmentsReorder = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = sortedAttachmentUuids.indexOf(active.id);
      const newIndex = sortedAttachmentUuids.indexOf(over.id);
      const newItems = arrayMove(sortedAttachmentUuids, oldIndex, newIndex);
      setState(pipe(identity, ...mapIndexed((attachmentUuid, index) => set(getNodeIndexLens(attachmentUuid))(index))(newItems)));
      pipe(
        mapIndexed((uuid, index) => ({ uuid, index, operation: 'pdf_export_attachment_update' })),
        pdfExportApi
      )(newItems);
    }
  };

  const handlePdfExport = () => {
    // array buffer promises for pdfs
    const titlePageArrayBufferPromiseList = ifElse(
      viewState,
      always(
        pipe(
          getNodeTitlePageFileUrlLens,
          viewState,
          fetch,
          andThen((response) => response.arrayBuffer()),
          (p) => [p]
        )(projectUuid)
      ),
      always([])
    )(isTitlePageIncludedLens);
    const mainEsimatePDFArrayBufferPromise = pipe(
      pdf,
      (pdfDocument) => pdfDocument.toBlob(),
      andThen((pdfBlob) => pdfBlob.arrayBuffer())
    )(mainEsimatePDFDocument);

    const attachmentsArrayBufferPromises = pipe(
      ifElse(viewState, always(pipe(getNodePdfExportAttachmentUuidsLens, viewState)(projectUuid)), always([])),
      map(getNodeFileUrlLens),
      map(viewState),
      map(fetch),
      map(andThen((response) => response.arrayBuffer()))
    )(isAttachmentsIncludedLens);
    const pdfArrayBufferPromises = [...titlePageArrayBufferPromiseList, mainEsimatePDFArrayBufferPromise, ...attachmentsArrayBufferPromises];

    // initialize pdf export status in state
    setState(
      pipe(
        set(pdfExportStatusIsExportingLens)(true),
        set(pdfExportStatusNumTotalFilesLens)(pdfArrayBufferPromises.length),
        set(pdfExportStatusNumFilesFetchedLens)(0),
        set(pdfExportStatusMessageLens)('Gathering files...')
      )
    );

    map(andThen((arrayBuffer) => pipe(add, over(pdfExportStatusNumFilesFetchedLens), setState)(1)))(pdfArrayBufferPromises);

    const mergedPdfDocumentPromise = pipe(
      map(andThen(PDFDocument.load)),
      (pdfDocumentPromises) => Promise.all(pdfDocumentPromises),
      andThen(async (pdfDocuments) => {
        const mergedPdfDocument = await PDFDocument.create();
        for (const pdfDocument of pdfDocuments) {
          const copiedPages = await mergedPdfDocument.copyPages(pdfDocument, pdfDocument.getPageIndices());
          copiedPages.forEach((page) => mergedPdfDocument.addPage(page));
          setState(pipe(set(pdfExportStatusMessageLens)('Merging files...'), over(pdfExportStatusNumFilesProcessedLens)(add(1))));
        }
        return mergedPdfDocument;
      })
    )(pdfArrayBufferPromises);

    // downloading the merged pdf
    pipe(
      andThen((mergedPdfDocument) => mergedPdfDocument.save()),
      andThen(saveByteArray('test.pdf')),
      andThen(() => setState(resetPdfExportStatus))
    )(mergedPdfDocumentPromise);
  };

  const handleSpreadsheetExport = (type) => {
    const headers = map(getColumnHeader)(['name', ...visibleColumns]);
    const rows = map((nodeId) => [getTableCellValue('name')(nodeId), ...map((column) => getTableCellValue(column)(nodeId))(visibleColumns)])(toExportIds);
    const tableArray = [headers, ...rows];
    const element = document.createElement('a');
    ExcellentExport.convert({ anchor: element, filename: `estimate_table.${type}`, format: type }, [{ name: 'Sheet 1', from: { array: tableArray } }]);
    element.setAttribute('download', `estimate_table.${type}`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  // =================================================================================================
  return (
    <Stack className='h-full overflow-hidden' direction='horizontal' gap={1}>
      <Stack className='items-center justify-center w-1/2 h-full gap-2 p-2 grow-0 shrink-0'>
        {pipe(
          viewState,
          ifElse(
            equals('pdf'),
            always(
              ifElse(
                identity,
                always(
                  <>
                    <IconAlertSquare size={50} />
                    <div>Export to PDF only available with 9 columns or fewer</div>
                    <div>
                      Go to{' '}
                      <span onClick={() => columnSelectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })} className='underline cursor-pointer text-blue-bobyard'>
                        column selection
                      </span>
                    </div>
                  </>
                ),
                always(
                  <PDFViewer className='w-full h-full' showToolbar={false}>
                    {mainEsimatePDFDocument}
                  </PDFViewer>
                )
              )(isColumnCountExceeded)
            ),
            always(
              <div className='flex flex-row w-full h-full overflow-scroll'>
                <table>
                  <tr className='sticky top-0 bg-gray-revell'>
                    {map((column) => (
                      <th className='px-4 py-2 text-nowrap' key={column}>
                        {getColumnHeader(column)}
                      </th>
                    ))(['name', ...visibleColumns])}
                  </tr>
                  {map((nodeId) => (
                    <tr key={nodeId}>
                      <td className='px-4 py-2 text-nowrap'>{getTableCellValue('name')(nodeId)}</td>
                      {map((column) => (
                        <td key={column} className='px-4 py-2 text-nowrap'>
                          {getTableCellValue(column)(nodeId)}
                        </td>
                      ))(visibleColumns)}
                    </tr>
                  ))(toExportIds)}
                </table>
              </div>
            )
          )
        )(exportTypeLens)}
      </Stack>
      <Stack className='items-center w-1/2 h-full overflow-hidden grow-0 shrink-0'>
        <Stack className='items-center h-full overflow-scroll grow shrink'>
          <Form className='flex flex-col items-center w-full'>
            <Row className='w-full p-2 border-b'>
              <Col sm={4}>Export as</Col>
              <Col sm={8}>
                <Form.Select aria-label='Default select example' value={viewState(exportTypeLens)} onChange={pipe(view(eventValueLens), set(exportTypeLens), setState)}>
                  <option value='pdf'>PDF</option>
                  <option value='csv'>Excel</option>
                  <option value='xlsx'>CSV</option>
                </Form.Select>
              </Col>
            </Row>
            {pipe(
              viewState,
              ifElse(
                equals('pdf'),
                always(
                  <>
                    <Row className='w-full p-2 border-b'>
                      <Col sm={4}>
                        <Form.Check
                          label='Include Title Page (not shown in preview)'
                          checked={viewState(isTitlePageIncludedLens)}
                          onChange={pipe(view(eventCheckedLens), set(isTitlePageIncludedLens), setState)}
                          disabled={pipe(getNodeTitlePageFileUrlLens, viewState, isNil)(projectUuid)}
                        />
                      </Col>
                      <Col sm={8}>
                        <Form.Group>
                          <a className='w-full overflow-hidden underline text-blue-bobyard' target='_blank' href={pipe(getNodeTitlePageFileUrlLens, viewState)(projectUuid)}>
                            {pipe(getNodeTitlePageNameLens, viewState)(projectUuid)}
                          </a>
                          <input accept='application/pdf' type='file' className='hidden' ref={titlePageUploadRef} onChange={pipe(view(eventFilesLens), head, handleTitlePageFileUpload)} />
                          {pipe(
                            getNodeTitlePageFileUrlLens,
                            viewState,
                            ifElse(
                              isNil,
                              always(
                                <Button className='w-full' variant='outline-primary' onClick={() => titlePageUploadRef.current.click()}>
                                  Upload New
                                </Button>
                              ),
                              always(
                                <ButtonGroup className='w-full mt-2'>
                                  <Button variant='outline-secondary' onClick={() => titlePageUploadRef.current.click()}>
                                    Replace
                                  </Button>
                                  <Button
                                    variant='outline-secondary'
                                    onClick={() => {
                                      setState(clearTitlePageFile(projectUuid));
                                      pdfExportApi([{ operation: 'project_update', title_page_file: null, title_page_name: null }]);
                                    }}
                                  >
                                    Clear
                                  </Button>
                                </ButtonGroup>
                              )
                            )
                          )(projectUuid)}
                        </Form.Group>
                        {ifElse(viewState, always(<ProgressBar className='mt-2' now={viewState(titlePageFileUploadProgressLens)} />), F)(titlePageFileUploadIsInProgressLens)}
                      </Col>
                    </Row>
                    <Row className='w-full p-2 border-b'>
                      <Col sm={4}>
                        <Form.Check
                          label='Include Logo on Header'
                          checked={viewState(isLogoIncludedLens)}
                          onChange={pipe(view(eventCheckedLens), set(isLogoIncludedLens), setState)}
                          disabled={pipe(getNodeLogoFileUrlLens, viewState, isNil)(projectUuid)}
                        />
                      </Col>
                      <Col sm={8}>
                        <Form.Group>
                          <input accept='image/*' type='file' className='hidden' ref={logoFileUploadRef} onChange={pipe(view(eventFilesLens), head, handleLogoFileUpload)} />
                          {pipe(
                            getNodeLogoFileUrlLens,
                            viewState,
                            ifElse(
                              isNil,
                              always(
                                <Button className='w-full' variant='outline-primary' onClick={() => logoFileUploadRef.current.click()}>
                                  Upload New
                                </Button>
                              ),
                              always(
                                <>
                                  <Form.Label className='w-full overflow-hidden'>{pipe(getNodeLogoNameLens, viewState)(projectUuid)}</Form.Label>
                                  {/* <Image
                                    className='mb-2'
                                    src={{ uri: pipe(getNodeLogoFileUrlLens, viewState)(projectUuid), method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }}
                                    rounded
                                  /> */}
                                  {/* <Image className='mb-2' src={pipe(getNodeLogoFileUrlLens, viewState)(projectUuid)} rounded /> */}
                                  <ButtonGroup className='w-full'>
                                    <Button variant='outline-secondary' onClick={() => logoFileUploadRef.current.click()}>
                                      Replace
                                    </Button>
                                    <Button
                                      variant='outline-secondary'
                                      onClick={() => {
                                        setState(clearLogoFile(projectUuid));
                                        pdfExportApi([{ operation: 'project_update', logo_file: null, logo_name: null }]);
                                      }}
                                    >
                                      Clear
                                    </Button>
                                  </ButtonGroup>
                                </>
                              )
                            )
                          )(projectUuid)}
                        </Form.Group>
                        {ifElse(viewState, always(<ProgressBar className='mt-2' now={viewState(logoFileUploadProgressLens)} />), F)(logoFileUploadIsInProgressLens)}
                      </Col>
                    </Row>
                    <Row className='w-full p-2 border-b'>
                      <Col sm={4}>
                        <Form.Check label='Include Preamble' checked={viewState(isPreambleIncludedLens)} onChange={pipe(view(eventCheckedLens), set(isPreambleIncludedLens), setState)} />
                      </Col>
                      <Col sm={8}>
                        {ifElse(
                          viewState,
                          always(
                            <ReactQuill
                              defaultValue={pipe(getNodePreambleLens, viewState)(projectUuid)}
                              // onChange={(markup) => pipe(set(getNodePreambleLens(projectUuid)), setState)(markup)}
                              onBlur={handlePreambleBlur}
                            />
                          ),
                          F
                        )(isInitialFetchCompleteLens)}
                      </Col>
                    </Row>
                  </>
                ),
                F
              )
            )(exportTypeLens)}

            <Row className='w-full p-2 border-b'>
              <Col sm={4}>
                <Form.Check
                  className='mb-2'
                  label='Export only selected rows'
                  checked={viewState(isOnlyExportingSelectedRows)}
                  onChange={pipe(view(eventCheckedLens), set(isOnlyExportingSelectedRows), setState)}
                />
              </Col>
              <Col sm={8}>
                <span className='font-medium'>{length(toExportIds)}</span> total rows currently displayed
                {ifElse(
                  viewState,
                  always(
                    <>
                      &nbsp;from <span className='font-medium'>{length(selectedIds)}</span> selected rows
                    </>
                  ),
                  always(F)
                )(isOnlyExportingSelectedRows)}
              </Col>
            </Row>
          </Form>
          <div className='w-full p-2 border-b shrink-0 bg-blue-alice' ref={columnSelectionRef}>
            {children}
            {pipe(
              viewState,
              equals('pdf'),
              ifElse(
                and(isColumnCountExceeded),
                always(
                  <div className='flex flex-row items-center justify-between p-2 mt-2 rounded bg-gray-revell'>
                    <div>More than 9 columns selected</div>
                    <Button variant='outline-danger' size='sm' onClick={handleExcludeAllColumns}>
                      Clear
                    </Button>
                  </div>
                ),
                F
              )
            )(exportTypeLens)}
          </div>
          {pipe(
            viewState,
            ifElse(
              equals('pdf'),
              always(
                <Form className='flex flex-col w-full'>
                  <Row className='w-full p-2 border-b'>
                    <Col sm={4}>
                      <Form.Check label='Include Postamble' checked={viewState(isPostambleIncludedLens)} onChange={pipe(view(eventCheckedLens), set(isPostambleIncludedLens), setState)} />
                    </Col>
                    <Col sm={8}>
                      {ifElse(
                        viewState,
                        always(
                          <ReactQuill
                            value={pipe(getNodePostambleLens, viewState)(projectUuid)}
                            // onChange={(markup) => pipe(set(getNodePreambleLens(projectUuid)), setState)(markup)}
                            onBlur={handlePostambleBlur}
                          />
                        ),
                        F
                      )(isInitialFetchCompleteLens)}
                    </Col>
                  </Row>
                  <Row className='w-full p-2'>
                    <Col sm={4}>
                      <Form.Check
                        label='Include Attachments (not shown in preview)'
                        checked={viewState(isAttachmentsIncludedLens)}
                        onChange={pipe(view(eventCheckedLens), set(isAttachmentsIncludedLens), setState)}
                        disabled={pipe(getNodePdfExportAttachmentUuidsLens, viewState, isEmpty)(projectUuid)}
                      />
                    </Col>
                    <Col sm={8}>
                      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleAttachmentsReorder}>
                        {pipe(getNodePdfExportAttachmentUuidsLens, viewState, (maybeAttachmentUuids) => {
                          return (
                            <SortableContext items={defaultTo([])(sortedAttachmentUuids)}>
                              {map((attachmentUuid) => (
                                <StyledSortableRow key={attachmentUuid} id={attachmentUuid} className={`flex flex-row h-10 w-full items-center`}>
                                  <a
                                    className='overflow-hidden underline cursor-pointer grow text-nowrap text-ellipsis text-blue-bobyard'
                                    target='_blank'
                                    href={pipe(getNodeFileUrlLens, viewState)(attachmentUuid)}
                                  >
                                    {pipe(getNodeNameLens, viewState)(attachmentUuid)}
                                  </a>
                                  <Button size='sm' variant='outline-secondary' onClick={() => handleAttachmentDelete(attachmentUuid)}>
                                    <IconTrash />
                                  </Button>
                                </StyledSortableRow>
                              ))(sortedAttachmentUuids)}
                            </SortableContext>
                          );
                        })(projectUuid)}
                      </DndContext>
                      <Stack gap={1} className='mt-4'>
                        {pipe(
                          viewState,
                          map((attachmentUploadUuid) => (
                            <Row key={attachmentUploadUuid}>
                              <Col sm={6} className='overflow-hidden text-nowrap text-ellipsis'>
                                {pipe(getNodeNameLens, viewState)(attachmentUploadUuid)}
                              </Col>
                              <Col sm={6} className='overflow-hidden text-nowrap text-ellipsis'>
                                <ProgressBar now={pipe(getNodeProgressLens, viewState)(attachmentUploadUuid)} />
                              </Col>
                            </Row>
                          ))
                        )(attachmentFileUploadUuidsLens)}
                      </Stack>
                      <input ref={attachmentUploadInputRef} type='file' accept='application/pdf' multiple className='hidden' onChange={pipe(view(eventFilesLens), map(handleAttachmentUpload))} />
                      <Button className='w-full mt-2' variant='outline-primary' onClick={() => attachmentUploadInputRef.current.click()}>
                        Add attachment
                      </Button>
                    </Col>
                  </Row>
                </Form>
              ),
              F
            )
          )(exportTypeLens)}
        </Stack>
        <Stack direction='horizontal' gap={1} className='justify-end p-2 border-t'>
          {ifElse(
            viewState,
            always(
              <Stack gap={1}>
                <div className='text-gray-darkish'>{viewState(pdfExportStatusMessageLens)}</div>
                <ProgressBar
                  now={
                    (viewState(pdfExportStatusNumFilesFetchedLens) / viewState(pdfExportStatusNumTotalFilesLens)) * 50 +
                    (viewState(pdfExportStatusNumFilesProcessedLens) / viewState(pdfExportStatusNumTotalFilesLens)) * 50
                  }
                  className='w-48'
                />
              </Stack>
            ),
            F
          )(pdfExportStatusIsExportingLens)}
          <Button variant='outline-secondary' onClick={onHide}>
            Close
          </Button>
          <Button
            variant='outline-primary'
            onClick={() => {
              if (viewState(exportTypeLens) == 'pdf') handlePdfExport();
              else handleSpreadsheetExport(viewState(exportTypeLens));
            }}
            disabled={viewState(pdfExportStatusIsExportingLens)}
          >
            Export
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
