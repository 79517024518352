import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import { selectAuth } from '../../redux/slices/authSlice';
import { KeybindContext } from './helper/Context';

import "../styles/Keybind.css";

import { API_ROUTE } from '../../index';
import DashboardNavbar from '../../components/DashboardNavbar';
import KeybindInput from './components/KeybindInput';
import KeybindItem from './components/KeybindItem';
import { Description } from './helper/Description';
import ProfileSidebar from '../Profile/ProfileSidebar';
import { Blocks } from 'react-loader-spinner';

export default function Keybind() {
    const auth = useSelector(selectAuth);

    const [keybinds, setKeybinds] = useState(null);

    const [currentKeybind, setCurrentKeybind] = useState(null);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (auth.contractor && auth.token && !keybinds) {
            axios({
                method: 'get',
                url: `${API_ROUTE}/api/keybind/${auth.contractor.id}/`,
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json"
                },
            })
                .then((response) => {
                    console.log(response);

                    setKeybinds(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [auth, keybinds]);

    return (
        <KeybindContext.Provider
            value={{
                keybinds, setKeybinds,
                currentKeybind, setCurrentKeybind,
            }}
        >
            <DashboardNavbar auth={auth} />
            <ProfileSidebar current={'keybinds'} />

            {loading
                ? <div className='keybind-container'>

                    <div className='keybind-body'>
                        <div className='keybind-binds'>
                            <div className='keybind-title'>
                                Keybinds
                            </div>
                        </div>
                    </div>

                    <br></br>

                    <div className='keybind-loading'>
                        <Blocks
                            visible={true}
                            height="60"
                            width="60"
                            color="#006AFE"
                            ariaLabel="blocks-loading"
                            radius="20"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                        />
                    </div>
                </div>
                : <div className='keybind-container'>
                    {keybinds &&
                        <div className='keybind-body'>
                            <div className='keybind-binds'>
                                <div className='keybind-title'>
                                    Keybinds
                                </div>

                                <div className='keybind-desc'>
                                    <div>
                                        Available keybinds are 'Tab', 'Shift', 'Control', and 'Option'
                                    </div>

                                    <div>'Control' and 'Command' are the same keybind</div>

                                    <div>'Option' and 'Alt' are the same keybind</div>
                                </div>

                                <div className='keybind-section'>
                                    <div className='keybind-section-title'>
                                        Undo / Redo
                                    </div>

                                    <div className='keybind-header'>
                                        <div>Action</div>
                                        <div>Primary bind</div>
                                        <div>Alternative</div>
                                    </div>

                                    <div className='keybind-section-content'>
                                        <KeybindItem action='Undo' />

                                        <KeybindItem action='Redo' />
                                    </div>
                                </div>


                                <div className='keybind-section'>
                                    <div className='keybind-section-title'>
                                        Zoom
                                    </div>

                                    <div className='keybind-section-content'>
                                        <KeybindItem action='Zoom In' />

                                        <KeybindItem action='Zoom Out' />

                                        <KeybindItem action='Zoom Reset' />

                                        <KeybindItem action='Zoom To Measurement' />
                                    </div>
                                </div>

                                <div className='keybind-section'>
                                    <div className='keybind-section-title'>
                                        Minimap
                                    </div>

                                    <div className='keybind-section-content'>
                                        <KeybindItem action='Minimap Toggle ' />
                                    </div>
                                </div>

                                <div className='keybind-section'>
                                    <div className='keybind-section-title'>
                                        Drawing
                                    </div>

                                    <div className='keybind-section-content'>
                                        <KeybindItem action='Draw Scale' />

                                        <KeybindItem action='Draw Rectangle' />

                                        <KeybindItem action='Draw Polygon' />

                                        <KeybindItem action='Draw Circle' />

                                        <KeybindItem action='Draw Length' />

                                        <KeybindItem action='Draw Count' />
                                    </div>
                                </div>

                                <div className='keybind-section'>
                                    <div className='keybind-section-title'>
                                        Edit measurement
                                    </div>

                                    <div className='keybind-section-content'>
                                        <KeybindItem action='Cut Polygon' />

                                        <KeybindItem action='Cut Polygon Rect' />

                                        <KeybindItem action='Split Polygon' />

                                        <KeybindItem action='Cut Rectangle' />

                                        <KeybindItem action='Cut Rectangle Rect' />

                                        <KeybindItem action='Split Rectangle' />

                                        <KeybindItem action='Add Count' />

                                        <KeybindItem action='Delete Count' />

                                        <div
                                            className='keybind-section-content-item'
                                            onMouseEnter={() => {
                                                setCurrentKeybind('Combine Measurments');
                                            }}
                                        >
                                            <div className='keybind-section-content-item-title'>
                                                Combine Measurements
                                            </div>
                                            <div>
                                                Fixed keybind
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='keybind-section'>
                                    <div className='keybind-section-title'>
                                        Selection
                                    </div>

                                    <div className='keybind-section-content'>
                                        <div
                                            className='keybind-section-content-item'
                                            onMouseEnter={() => {
                                                setCurrentKeybind('Multi-select');
                                            }}
                                        >
                                            <div className='keybind-section-content-item-title'>
                                                Multi-select
                                            </div>
                                            <div>
                                                Fixed keybind
                                            </div>
                                        </div>

                                        <div
                                            className='keybind-section-content-item'
                                            onMouseEnter={() => {
                                                setCurrentKeybind('Highlight-area');
                                            }}
                                        >
                                            <div className='keybind-section-content-item-title'>
                                                Highlight area
                                            </div>
                                            <div>
                                                Fixed keybind
                                            </div>
                                        </div>

                                        <div
                                            className='keybind-section-content-item'
                                            onMouseEnter={() => {
                                                setCurrentKeybind('Delete-Measurement');
                                            }}
                                        >
                                            <div className='keybind-section-content-item-title'>
                                                Delete Measurement(s)
                                            </div>
                                            <div>
                                                Fixed keybind
                                            </div>
                                        </div>

                                        <KeybindItem action='Erase Count' />

                                        <KeybindItem action='Selection Calculator' />
                                    </div>
                                </div>

                                <div className='keybind-section'>
                                    <div className='keybind-section-title'>
                                        Annotations
                                    </div>

                                    <div className='keybind-section-content'>
                                        <KeybindItem action='Draw Annotation Ruler' />

                                        <KeybindItem action='Draw Annotation Text' />

                                        <KeybindItem action='Draw Annotation Arrow' />

                                        <KeybindItem action='Draw Annotation Rectangle' />
                                    </div>
                                </div>

                                <div className='keybind-section'>
                                    <div className='keybind-section-title'>
                                        Sidebars
                                    </div>

                                    <div className='keybind-section-content'>
                                        <KeybindItem action='Toggle Measurement Sidebar' />

                                        <KeybindItem action='Toggle Pages Sidebar' />

                                        <KeybindItem action='Toggle AI Sidebar' />
                                    </div>
                                </div>

                                <div className='keybind-section'>
                                    <div className='keybind-section-title'>
                                        Pages
                                    </div>

                                    <div className='keybind-section-content'>
                                        <KeybindItem action='Previous Page' />

                                        <KeybindItem action='Next Page' />
                                    </div>
                                </div>

                                <div className='keybind-section'>
                                    <div className='keybind-section-title'>
                                        Search
                                    </div>

                                    <div className='keybind-section-content'>
                                        <KeybindItem action='Search Measurement' />

                                        <KeybindItem action='Search Page' />
                                    </div>
                                </div>
                            </div>

                            {currentKeybind &&
                                <div className='keybind-details'>
                                    <div className='keybind-details-title'>
                                        <b>Action:</b> {currentKeybind}
                                    </div>

                                    <div className='keybind-details-desc'>
                                        {Description[currentKeybind.replace(/\s/g, '')]}
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            }
        </KeybindContext.Provider>
    )
}