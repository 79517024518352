import { useParams } from 'react-router-dom';
import { axiosCurry, eventCheckedLens, getLastSundayTimestamp, HOUR_MS, responseDataLens, reverseView, WEEK_MS } from '../utilities/utilities';
import { useSelector } from 'react-redux';
import { selectAuth } from '../redux/slices/authSlice';
import { createElement, useEffect, useMemo, useState } from 'react';
import { API_ROUTE } from '..';
import {
  pipe,
  view,
  andThen,
  lensProp,
  set,
  map,
  compose,
  ifElse,
  over,
  add,
  mergeDeepLeft,
  groupBy,
  keys,
  defaultTo,
  length,
  fromPairs,
  isEmpty,
  lensPath,
  not,
  xprod,
  reduce,
  always,
  flatten,
  filter,
  mergeAll,
  equals,
  reject,
} from 'ramda';
import { getDepthObject, getPrevMultiple } from './Utilities';
import { defaultItemColumns, dfs, getColumnHeader, getIsNumeric, restColumns } from '../estimate/EstimateUtilities';
import Popup from 'reactjs-popup';
import {
  fullGetCalculationsObject,
  fullGetDependencies,
  fullGetIsApplicable,
  fullGetIsCalculated,
  fullGetIsEditable,
  fullGetPrimaryValue,
  fullGetShouldUseCalculatedValue,
} from '../estimate/Calculations';
import { ColumnHeader, ColumnListElement, ColumnWrapper } from '../estimate/components/styledcomponents';
import TextElement from '../estimate/components/TextElement';
import InapplicableElement from '../estimate/components/InapplicableElement';
import NumericElement from '../estimate/components/NumericElement';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { IconArrowBarToRight, IconArrowLeft, IconArrowRight, IconCaretDown, IconCaretRight, IconFolder, IconFolderOpen, IconRestore, IconSelect } from '@tabler/icons-react';
import { Blocks } from 'react-loader-spinner';
import NavDropDown from '../components/NavDropDown';
import axios from 'axios';
import { Tooltip } from 'react-tooltip';
import './EditHistory.css';
import { Resizable } from 'react-resizable';

const MODIFIED_REST_COLUMNS = reject(equals('use_group_color'))(restColumns);

const startTimeLens = lensProp('startTime');
const loadedSnapshotsLens = lensProp('loadedSnapshots');
const groupedSnapshotsLens = lensProp('groupedSnapshots');
const currentlyExpandedHourLens = lensProp('currentlyExpandedHour');

const currentSnapshotLens = lensProp('currentSnapshot');
const currentSnapshotIDLens = compose(currentSnapshotLens, lensProp('id'));
const currentSnapshotObjLens = compose(currentSnapshotLens, lensProp('obj'));
const currentSnapshotTopLevelIDsLens = compose(currentSnapshotObjLens, lensProp('top_level_ids'));
const currentSnapshotTreeDataLens = compose(currentSnapshotObjLens, lensProp('tree_data'));
const currentSnapshotCollapsedNodesLens = compose(currentSnapshotLens, lensProp('collapsedNodes'));
const currentSnapshotIsLoadingLens = compose(currentSnapshotLens, lensProp('isLoading'));

const getLoadedSnapshotLens = (id) => compose(loadedSnapshotsLens, lensProp(id));
const getLoadedSnapshotDateLens = (id) => compose(getLoadedSnapshotLens(id), lensProp('date'));
const getLoadedSnapshotContractorLens = (id) => compose(getLoadedSnapshotLens(id), lensProp('contractor'));
const getLoadedSnapshotContractorFirstNameLens = (id) => compose(getLoadedSnapshotContractorLens(id), lensProp('first_name'));
const getLoadedSnapshotContractorLastNameLens = (id) => compose(getLoadedSnapshotContractorLens(id), lensProp('last_name'));

const getCurrentSnapshotLens = (id) => compose(currentSnapshotTreeDataLens, lensProp(id));
const getCurrentSnapshotValueLens = (id) => (column) => compose(getCurrentSnapshotLens(id), lensProp(column));
const getCurrentSnapshotIsCollapsedLens = (id) => compose(currentSnapshotCollapsedNodesLens, lensProp(id));

const getGroupedSnapshotLens = (hourTimestampStr) => compose(groupedSnapshotsLens, lensProp(hourTimestampStr));
const getIsColumnShownLens = (column) => lensPath(['shownColumns', column]);

const getColumnWidthLens = (column) => lensPath(['columnWidths', column]);

const fullGetRoutedField = (getIsApplicable) => (getIsNumeric) => (column) => (nodeID) => {
  if (!getIsApplicable(column)(nodeID)) return InapplicableElement;
  if (getIsNumeric(column)(nodeID)) return NumericElement;
  return TextElement;
};

const initialState = {
  startTime: getLastSundayTimestamp(),
  loadedSnapshots: {},
  groupedSnapshots: {},
  currentlyExpandedHour: null,
  shownColumns: pipe(
    map((column) => [column, defaultItemColumns.has(column)]),
    fromPairs
  )(MODIFIED_REST_COLUMNS),
  currentSnapshot: { id: null, obj: { top_level_ids: [], tree_data: {} }, collapsedNodes: {}, isLoading: false },
  columnWidths: pipe(
    map((col) => [col, 200]),
    fromPairs
  )(['name', ...restColumns]),
};

const fullGetCurrentSnapshotValue = (state) => (column) => (nodeID) => view(getCurrentSnapshotValueLens(nodeID)(column))(state);
const fullGetCurrentSnapshotType = (state) => (nodeID) => fullGetCurrentSnapshotValue(state)('type')(nodeID);
const fullGetCurrentSnapshotChildren = (state) => (nodeID) => pipe(fullGetCurrentSnapshotValue(state)('children'), defaultTo([]))(nodeID);
const fullGetCurrentSnapshotGroup = (state) => (nodeID) => pipe(fullGetCurrentSnapshotValue(state)('group'), defaultTo(null))(nodeID);
const fullGetCurrentSnapshotVisibleChildren = (state) => ifElse(pipe(getCurrentSnapshotIsCollapsedLens, reverseView(state)), always([]), fullGetCurrentSnapshotChildren(state));

const setCurrentlyExpandedHourLens = (hourTimestampStr) => over(currentlyExpandedHourLens)(pipe(ifElse(equals(hourTimestampStr), always(null), always(hourTimestampStr))));

const nextWeek = pipe(over(startTimeLens)(add(WEEK_MS)), set(currentlyExpandedHourLens)(null));
const prevWeek = pipe(over(startTimeLens)(add(-WEEK_MS)), set(currentlyExpandedHourLens)(null));

const handleNewSnapshots = (loadedSnapshots) => (state) => {
  const getHourOfSnapshot = (id) => getPrevMultiple(HOUR_MS)(loadedSnapshots[id].date);
  const groupedSnapshots = groupBy(getHourOfSnapshot)(keys(loadedSnapshots));
  return pipe(set(loadedSnapshotsLens)(loadedSnapshots), set(groupedSnapshotsLens)(groupedSnapshots))(state);
};

const EditHistory = () => {
  const params = useParams();
  const projectUUID = params.projectUUID;
  const auth = useSelector(selectAuth);
  const [state, setState] = useState(initialState);

  const [reverting, setReverting] = useState(false);
  const [projects, setProjects] = useState(null);

  const viewState = (lens) => view(lens)(state);

  const editHistoryAPI = useMemo(
    () => axiosCurry(`${API_ROUTE}/api/edit-history-api/${projectUUID}/`)({ Authorization: `Token ${auth.token}`, 'Content-Type': 'application/json' }),
    [API_ROUTE, auth.token]
  );

  const endTime = viewState(startTimeLens) + WEEK_MS - 1;

  // calculations related code
  const getNodeType = fullGetCurrentSnapshotType(state);
  const getChildren = fullGetCurrentSnapshotChildren(state);
  const getGroup = fullGetCurrentSnapshotGroup(state);
  const getValue = fullGetCurrentSnapshotValue(state);
  const getIsApplicable = fullGetIsApplicable(getValue)(getNodeType);
  const getIsCalculated = fullGetIsCalculated(getNodeType)(getIsApplicable);
  const getDependencies = fullGetDependencies(getIsCalculated)(getGroup)(getChildren)(getNodeType);
  const getIsEditable = fullGetIsEditable(getIsApplicable);
  const getShouldUseCalculatedValue = fullGetShouldUseCalculatedValue(getIsEditable)(getIsCalculated)(getValue)(() => () => undefined);
  const toCalculate = useMemo(() => pipe(viewState, keys, xprod(MODIFIED_REST_COLUMNS))(currentSnapshotTreeDataLens), [viewState(currentSnapshotTreeDataLens)]);
  const calculations = useMemo(
    () =>
      reduce((acc, [col, id]) => fullGetCalculationsObject(acc)(getIsCalculated)(getDependencies)(getNodeType)(getGroup)(getChildren)(getShouldUseCalculatedValue)(getValue)(col)(id))({})(toCalculate),
    [toCalculate, viewState(currentSnapshotTreeDataLens)]
  );
  const getCalculatedValue = (column) => (nodeID) => calculations[column]?.[nodeID];
  const getPrimaryValue = fullGetPrimaryValue(getIsEditable)(getIsCalculated)(getValue)(getCalculatedValue);

  // estimate table rendering related code
  const visibleColumns = filter(pipe(getIsColumnShownLens, viewState))(MODIFIED_REST_COLUMNS);
  const getVisibleChildren = fullGetCurrentSnapshotVisibleChildren(state);
  const visibleNodeIDs = useMemo(
    () => pipe(map(dfs(getVisibleChildren)), flatten)(viewState(currentSnapshotTopLevelIDsLens)),
    [viewState(currentSnapshotTopLevelIDsLens), viewState(currentSnapshotCollapsedNodesLens)]
  );
  const depthObject = useMemo(
    () => pipe(map(getDepthObject(getChildren)(0)), mergeAll)(viewState(currentSnapshotTopLevelIDsLens)),
    [viewState(currentSnapshotTopLevelIDsLens), viewState(currentSnapshotTreeDataLens)]
  );
  const getDepth = (nodeID) => depthObject[nodeID];

  useEffect(() => {
    pipe(
      editHistoryAPI('post'),
      andThen(view(responseDataLens)),
      andThen(handleNewSnapshots),
      andThen(setState)
    )({ type: 'get_edit_history_date_range', start: viewState(startTimeLens), end: endTime });
  }, [viewState(startTimeLens)]);

  useEffect(() => {
    if (auth.token && !projects) {
      axios({
        method: 'get',
        url: `${API_ROUTE}/api/projects/`,
        headers: {
          Authorization: `Token ${auth.token}`,
          'Content-Type': 'application/json',
        },
        params: {
          projectUUID: projectUUID,
        },
      })
        .then((response) => {
          console.log(response);

          axios({
            method: 'get',
            url: `${API_ROUTE}/api/projects/`,
            headers: {
              Authorization: `Token ${auth.token}`,
              'Content-Type': 'application/json',
            },
            params: {
              companyID: response.data.company,
            },
          })
            .then((response) => {
              console.log(response);

              setProjects(response.data);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [auth, projects]);

  return (
    <div className='flex flex-col w-screen h-screen'>
      <div className='bg-white h-10 flex flex-row items-center justify-between border-b border-solid border-[#e0e0e0]'>
        <div className='takeoffedithistory-project-name-container'>
          <NavDropDown projectUUID={projectUUID} />

          <div className='takeoffnavbar-project-name' id='compagepages-navbar-project-name' style={{ cursor: 'default' }}>
            {projects?.find((p) => p.uuid === projectUUID) ? projects.find((p) => p.uuid === projectUUID).title : ''}
          </div>

          <Popup
            trigger={(open) => (
              <div className='takeoffnavbar-project-name-icon' id='compagepages-navbar-project-name-icon'>
                <IconSelect size={20} stroke={1} color='#525252' />
              </div>
            )}
            on='click'
            position='bottom center'
            closeOnDocumentClick
            mouseLeaveDelay={300}
            mouseEnterDelay={0}
          >
            <div className='navdropdown-items'>
              {projects
                ?.sort((a, b) => b.id - a.id)
                .map((p) => (
                  <>
                    {p.uuid === projectUUID ? (
                      <div className='navdropdown-item-active'>
                        <div>{p.title}</div>
                      </div>
                    ) : (
                      <a href={'/edit-history/' + p.uuid} className='navdropdown-item-link'>
                        <div className='navdropdown-item'>
                          <div>{p.title}</div>
                        </div>
                      </a>
                    )}
                  </>
                ))}
            </div>
          </Popup>

          <Tooltip delayShow={500} anchorSelect='#compagepages-navbar-project-name-icon' place='top'>
            Navigate to another project
          </Tooltip>
        </div>

        {((startTime) => {
          const thisWeekTimestamp = getLastSundayTimestamp();
          const isThisWeek = startTime === thisWeekTimestamp;
          return (
            <>
              <Popup trigger={<div className='edithistory-columns-button'>Columns</div>} contentStyle={{ width: '300px' }}>
                <div className='edithistory-columns-popup'>
                  {map((column) => (
                    <div className='flex flex-row justify-between' key={column}>
                      <div className='text-nowrap'>{getColumnHeader(column)}</div>
                      <input
                        className='edithistory-columns-checkbox'
                        type='checkbox'
                        checked={pipe(getIsColumnShownLens, viewState)(column)}
                        onChange={pipe(view(eventCheckedLens), set(getIsColumnShownLens(column)), setState)}
                      />
                    </div>
                  ))(MODIFIED_REST_COLUMNS)}
                </div>
              </Popup>
            </>
          );
        })(viewState(startTimeLens))}

        <div className='takeoffedithistory-edit-button-container'>
          <a href={`/takeoff-edit-history/${projectUUID}`}>
            <div className='takeoffedithistory-edit-button'>Takeoff edit history</div>
          </a>
        </div>
      </div>

      <div className='flex flex-row min-h-0 overflow-hidden grow'>
        {!viewState(currentSnapshotIDLens) && <div className='takeoffedithistory-no-snapshot-selected'>Select a snapshot to view</div>}
        {viewState(currentSnapshotIDLens) && viewState(currentSnapshotIsLoadingLens) && (
          <div className='flex flex-row items-center justify-center w-full h-full'>
            <Blocks />
          </div>
        )}
        {viewState(currentSnapshotIDLens) && !viewState(currentSnapshotIsLoadingLens) && (
          <ScrollSync>
            <div className='flex flex-row h-full min-w-0 overflow-hidden grow'>
              <ColumnWrapper className='flex flex-col w-fit' style={{ width: viewState(getColumnWidthLens('name')) }}>
                <Resizable
                  handle={<div onClick={(e) => e.stopPropagation} className='w-0.5 h-full bg-gray-lightgray cursor-col-resize hover:bg-blue-bobyard'></div>}
                  width={viewState(getColumnWidthLens('name'))}
                  height={40}
                  minConstraints={[200, 0]}
                  maxConstraints={[1000, Infinity]}
                  onResize={(e, data) => setState(set(getColumnWidthLens('name'))(data.size.width))}
                >
                  <ColumnHeader>
                    <div>{getColumnHeader('name')}</div>
                  </ColumnHeader>
                </Resizable>
                <ScrollSyncPane>
                  <div className='flex flex-col w-full min-h-0 px-2 overflow-scroll grow'>
                    {map((nodeID) => (
                      <ColumnListElement
                        key={nodeID}
                        style={{ marginLeft: `${getDepth(nodeID)}em` }}
                        className='flex flex-row items-center gap-2 cursor-pointer'
                        onClick={() => pipe(getCurrentSnapshotIsCollapsedLens, (lens) => over(lens)(pipe(defaultTo(false), not)), setState)(nodeID)}
                      >
                        <div className='flex flex-row items-center w-10 h-full justify-ceter'>
                          {getNodeType(nodeID) == 'group' && (viewState(getCurrentSnapshotIsCollapsedLens(nodeID)) ? <IconFolder size={20} /> : <IconFolderOpen size={20} />)}
                        </div>
                        <TextElement value={getPrimaryValue('name')(nodeID)} />
                      </ColumnListElement>
                    ))(visibleNodeIDs)}
                  </div>
                </ScrollSyncPane>
              </ColumnWrapper>
              <div className='flex flex-row h-full min-w-0 overflow-scroll grow'>
                {map((column) => (
                  <ColumnWrapper className='flex flex-col w-fit' key={column} style={{ width: viewState(getColumnWidthLens(column)) }}>
                    <Resizable
                      handle={<div onClick={(e) => e.stopPropagation} className='w-0.5 h-full bg-gray-lightgray cursor-col-resize hover:bg-blue-bobyard'></div>}
                      width={viewState(getColumnWidthLens(column))}
                      height={40}
                      minConstraints={[200, 0]}
                      maxConstraints={[1000, Infinity]}
                      onResize={(e, data) => setState(set(getColumnWidthLens(column))(data.size.width))}
                    >
                      <ColumnHeader className='!cursor-default'>
                        <div>{getColumnHeader(column)}</div>
                      </ColumnHeader>
                    </Resizable>
                    <ScrollSyncPane>
                      <div className='flex flex-col w-full min-h-0 px-2 overflow-scroll grow no-scrollbar'>
                        {map((nodeID) => (
                          <ColumnListElement key={nodeID}>
                            {createElement(fullGetRoutedField(getIsApplicable)(getIsNumeric)(column)(nodeID), { value: getPrimaryValue(column)(nodeID) })}
                          </ColumnListElement>
                        ))(visibleNodeIDs)}
                      </div>
                    </ScrollSyncPane>
                  </ColumnWrapper>
                ))(visibleColumns)}
              </div>
            </div>
          </ScrollSync>
        )}

        <div className='edithistory-snapshot-list-container'>
          {((startTime) => {
            const thisWeekTimestamp = getLastSundayTimestamp();
            const isThisWeek = startTime === thisWeekTimestamp;
            return (
              <div className='flex flex-row justify-between px-2'>
                <button onClick={() => setState(prevWeek)} className='text-blue-bobyard'>
                  <IconArrowLeft />
                </button>
                <div className='flex flex-col items-center justify-center h-16 text-sm'>
                  <span className=''>
                    {new Date(startTime).toLocaleDateString()} - {new Date(endTime).toLocaleDateString()}
                  </span>
                  <span>{isThisWeek && '(this week)'}</span>
                </div>
                <button onClick={() => setState(nextWeek)} disabled={isThisWeek} className={`${isThisWeek ? '' : 'text-blue-bobyard'}`}>
                  <IconArrowRight />
                </button>
                <button
                  onClick={() => setState(pipe(set(startTimeLens)(thisWeekTimestamp), set(currentlyExpandedHourLens)(null)))}
                  disabled={isThisWeek}
                  className={`${isThisWeek ? '' : 'text-blue-bobyard'}`}
                >
                  <IconArrowBarToRight />
                </button>
              </div>
            );
          })(viewState(startTimeLens))}

          {/*20vw width */}
          <div className='flex flex-col min-h-0 overflow-scroll grow'>
            {pipe(viewState, isEmpty)(loadedSnapshotsLens) && <div className='takeoffedithistory-no-snapshots'>No snapshots to show for this week</div>}
            {map((hourTimestampStr) => {
              const hourTimestamp = parseInt(hourTimestampStr);
              const date = new Date(hourTimestamp);
              const snapshots = viewState(getGroupedSnapshotLens(hourTimestampStr));
              const isExpanded = viewState(currentlyExpandedHourLens) === hourTimestampStr;
              return (
                <div key={hourTimestampStr}>
                  <div className='takeoffedithistory-snapshot-list-folder' onClick={() => setState(setCurrentlyExpandedHourLens(hourTimestampStr))}>
                    {!isExpanded && <IconCaretRight stroke={1} size={20} />}
                    {isExpanded && <IconCaretDown stroke={1} size={20} />}
                    <span>
                      {date.getMonth() + 1}/{date.getDate()}, {date.toLocaleTimeString().split(':')[0]} {date.toLocaleTimeString().split(' ')[1]}
                    </span>
                    <span className='takeoffedithistory-snapshot-list-folder-edit-count'>{pipe(getGroupedSnapshotLens, viewState, length)(hourTimestampStr)} edits</span>
                  </div>
                  <div className='flex flex-col'>
                    {isExpanded &&
                      map((snapshotID) => {
                        const snapshotTime = new Date(viewState(getLoadedSnapshotDateLens(snapshotID))).toLocaleTimeString();
                        return (
                          <div
                            key={snapshotID}
                            className={`takeoffedithistory-snapshot-list-item ${viewState(currentSnapshotIDLens) == snapshotID ? 'takeoffedithistory-snapshot-list-item-active' : ''}`}
                            onClick={() => {
                              setState(pipe(set(currentSnapshotIsLoadingLens)(true), set(currentSnapshotIDLens)(snapshotID)));
                              pipe(
                                editHistoryAPI('post'),
                                andThen(view(responseDataLens)),
                                andThen((snapshotObj) => set(currentSnapshotObjLens)(snapshotObj)({})),
                                andThen(set(currentSnapshotIDLens)(snapshotID)),
                                andThen(set(currentSnapshotIsLoadingLens)(false)),
                                andThen(mergeDeepLeft),
                                andThen(setState)
                              )({ type: 'get_detailed_commit', id: snapshotID });
                            }}
                          >
                            <div className='flex flex-row items-center justify-between'>
                              <div>
                                <div className='min-w-0 grow'>{snapshotTime}</div>
                                <div className='text-xs'>
                                  by {viewState(getLoadedSnapshotContractorFirstNameLens(snapshotID))} {viewState(getLoadedSnapshotContractorLastNameLens(snapshotID))}
                                </div>
                              </div>
                              <div className='flex flex-row gap-2'>
                                <button
                                  className='takeoffedithistory-snapshot-list-item-button'
                                  onClick={(e) => {
                                    if (reverting) return;

                                    e.stopPropagation();
                                    setReverting(true);

                                    pipe(
                                      editHistoryAPI('post'),
                                      andThen(view(responseDataLens)),
                                      andThen(handleNewSnapshots),
                                      andThen(setState),
                                      andThen(() => setReverting(false))
                                    )({ type: 'restore_commit', id: snapshotID, start: viewState(startTimeLens), end: endTime });
                                  }}
                                >
                                  {reverting ? (
                                    <Blocks visible={true} height='20' width='20' color='#006AFE' ariaLabel='blocks-loading' radius='10' wrapperStyle={{}} wrapperClass='blocks-wrapper' />
                                  ) : (
                                    <IconRestore stroke={1} size={20} />
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })(snapshots)}
                  </div>
                </div>
              );
            })(pipe(viewState, keys)(groupedSnapshotsLens))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditHistory;
