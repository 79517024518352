import { useContext, useState } from "react";
import { Rect, Text } from "react-konva";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";

import { selectAuth } from "../../../redux/slices/authSlice";
import { TakeoffContext } from "../../helper/Context";
import { API_ROUTE } from "../../..";

export default function AIAutoFind({ }) {
    const {
        AIAutoFinding,
        AILocalization,
        pageID,
        pages,
    } = useContext(TakeoffContext);

    const [gettingInference, setGettingInference] = useState(false);

    return (
        <>
            {AIAutoFinding?.[pageID]?.show && AILocalization && AILocalization.filter(a => !a.count_dot && !a.measurement).sort((a, b) => (b.x2 - b.x1) * (b.y2 - b.y1) - (a.x2 - a.x1) * (a.y2 - a.y1)).map((rect, i) =>
                <AILocalizationDot
                    key={i}
                    rect={rect}
                    pageID={pageID}
                    pages={pages}
                    gettingInference={gettingInference}
                    setGettingInference={setGettingInference}
                />
            )}
        </>
    )
}

function AILocalizationDot({ rect, pageID, pages, gettingInference, setGettingInference }) {
    const auth = useSelector(selectAuth);

    const {
        AIAutoClassifying, setAIAutoClassifying,
        AILocalization, setAILocalization,
        setMeasurements,
        tree, setTree,
        currentGroup,
        AIAutoCountExample, setAIAutoCountExample,
        autoCountLocation, setAutoCountLocation,
    } = useContext(TakeoffContext);

    const [hovering, setHovering] = useState(false);

    const handleClassify = () => {
        if (gettingInference) return;
        setGettingInference(true);

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/auto-find/`,
            data: {
                'page': pageID,
                'symbol': rect.id,
                'current_group': currentGroup || null,
                'contractor': auth.contractor.id,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);

                setHovering(false);
                setGettingInference(false);
                setAILocalization(response.data.localization_symbols);

                if (response.data.measurement) {
                    setMeasurements(prev => ({
                        ...prev,
                        [response.data.measurement.id]: response.data.measurement
                    }));

                    axios({
                        method: 'post',
                        url: `${API_ROUTE}/api/create-ai-preview-measurement/`,
                        data: {
                            measurement: response.data.measurement.id,
                        },
                        headers: {
                            'Authorization': `Token ${auth.token}`,
                            "Content-Type": "application/json",
                        }
                    })
                        .then((response) => {
                            console.log(response);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
                setTree(response.data.tree);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    return (
        <Rect
            x={rect.x1}
            y={rect.y1}
            width={rect.x2 - rect.x1}
            height={rect.y2 - rect.y1}
            fill={hovering ? "rgba(0, 0, 255, 0.1)" : "rgba(0, 0, 255, 0.00)"}
            stroke={hovering ? "blue" : ""}
            strokeWidth={1 / pages[pageID].zoom}
            onMouseEnter={(e) => {
                if (!gettingInference) {
                    setHovering(true);
                    e.target.getStage().container().style.cursor = "pointer";
                } else {
                    e.target.getStage().container().style.cursor = "wait";
                }
            }}
            onMouseLeave={(e) => {
                if (!gettingInference) {
                    setHovering(false)
                }
            }}
            onClick={(e) => {
                if (e.evt.button !== 0) return;

                handleClassify()
            }}
        />
    );
}