import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { TakeoffContext } from './helper/Context';

import './styles/TakeoffSidebar.css';

import MeasurementSidebar from './components/MeasurementSidebar';
import PagesSidebar from './components/PagesSidebar';
import AISidebar from './components/AISidebar';
import { IconChevronsLeft, IconChevronsRight, IconLayoutGrid, IconList, IconRobot, IconX } from '@tabler/icons-react';
import { Tooltip } from 'react-tooltip';

export default function TakeoffSidebar({ }) {
    const {
        takeoffSettings,
        keybinds,
        handleChangeTakeoffSettings,
    } = useContext(TakeoffContext);

    const [measurementSidebarWidth, setShowMeasurementsSidebarWidth] = useState(takeoffSettings?.measurement_sidebar_width || null);
    const [pagesSidebarWidth, setShowPagesSidebarWidth] = useState(takeoffSettings?.pages_sidebar_width || null);
    const [aiSidebarWidth, setShowAISidebarWidth] = useState(takeoffSettings?.ai_sidebar_width || null);

    const [currentAITab, setCurrentAITab] = useState('count');

    useEffect(() => {
        if (takeoffSettings) {
            setShowMeasurementsSidebarWidth(takeoffSettings?.measurement_sidebar_width);
            setShowPagesSidebarWidth(takeoffSettings?.pages_sidebar_width);
            setShowAISidebarWidth(takeoffSettings?.ai_sidebar_width);
        }
    }, [takeoffSettings]);

    const ShowLeft = (takeoffSettings?.show_measurement_sidebar && takeoffSettings?.measurement_sidebar_location === 'left') || (takeoffSettings?.show_pages_sidebar && takeoffSettings?.pages_sidebar_location === 'left') || (takeoffSettings?.show_ai_sidebar && takeoffSettings?.ai_sidebar_location === 'left');
    const ShowRight = (takeoffSettings?.show_measurement_sidebar && takeoffSettings?.measurement_sidebar_location === 'right') || (takeoffSettings?.show_pages_sidebar && takeoffSettings?.pages_sidebar_location === 'right') || (takeoffSettings?.show_ai_sidebar && takeoffSettings?.ai_sidebar_location === 'right');

    const LeftWidth = ((takeoffSettings?.show_measurement_sidebar && takeoffSettings?.measurement_sidebar_location === 'left')
        ? measurementSidebarWidth
        : (takeoffSettings?.show_pages_sidebar && takeoffSettings?.pages_sidebar_location === 'left')
            ? pagesSidebarWidth
            : (takeoffSettings?.show_ai_sidebar && takeoffSettings?.ai_sidebar_location === 'left')
                ? aiSidebarWidth
                : null);

    const RightWidth = ((takeoffSettings?.show_measurement_sidebar && takeoffSettings?.measurement_sidebar_location === 'right')
        ? measurementSidebarWidth
        : (takeoffSettings?.show_pages_sidebar && takeoffSettings?.pages_sidebar_location === 'right')
            ? pagesSidebarWidth
            : (takeoffSettings?.show_ai_sidebar && takeoffSettings?.ai_sidebar_location === 'right')
                ? aiSidebarWidth
                : null);

    if (!takeoffSettings && !measurementSidebarWidth && !pagesSidebarWidth && !aiSidebarWidth) return null;

    return (
        <>
            {!ShowLeft &&
                <div className="takeoff-left-toggle">
                    {takeoffSettings?.ai_sidebar_location === 'left' &&
                        <div
                            className="takeoff-left-toggle-item"
                            onClick={() => handleChangeTakeoffSettings({
                                ...takeoffSettings,
                                show_ai_sidebar: true
                            })}
                        >
                            <div className="takeoff-left-toggle-item-name">
                                <IconRobot size={20} stroke={1} />

                                <div className="takeoff-left-toggle-item-name-text">
                                    AI&nbsp;Tools
                                </div>
                            </div>

                            <IconChevronsRight size={20} stroke={1} />
                        </div>
                    }

                    {takeoffSettings?.pages_sidebar_location === 'left' &&
                        <div
                            className="takeoff-left-toggle-item"
                            onClick={() => handleChangeTakeoffSettings({
                                ...takeoffSettings,
                                show_pages_sidebar: true
                            })}
                        >
                            <div className="takeoff-left-toggle-item-name">
                                <IconLayoutGrid size={20} stroke={1} />

                                <div className="takeoff-left-toggle-item-name-text">
                                    Pages
                                </div>
                            </div>

                            <IconChevronsRight size={20} stroke={1} />
                        </div>
                    }

                    {takeoffSettings?.measurement_sidebar_location === 'left' &&
                        <div className="takeoff-left-toggle-item"
                            onClick={() => handleChangeTakeoffSettings({
                                ...takeoffSettings,
                                show_measurement_sidebar: true
                            })}
                        >
                            <div className="takeoff-left-toggle-item-name">
                                <IconList size={20} stroke={1} />

                                <div className="takeoff-left-toggle-item-name-text">
                                    Takeoffs
                                </div>
                            </div>

                            <IconChevronsRight size={20} stroke={1} />
                        </div>
                    }
                </div>
            }

            {!ShowRight &&
                <div className="takeoff-right-toggle">
                    {takeoffSettings?.ai_sidebar_location === 'right' &&
                        <div className="takeoff-right-toggle-item"
                            onClick={() => handleChangeTakeoffSettings({
                                ...takeoffSettings,
                                show_ai_sidebar: true
                            })}
                        >
                            <IconChevronsLeft size={20} stroke={1} />

                            <div className="takeoff-right-toggle-item-name">
                                <IconRobot size={20} stroke={1} />
                                <div className="takeoff-right-toggle-item-name-text">
                                    AI&nbsp;Tools
                                </div>
                            </div>
                        </div>
                    }

                    {takeoffSettings?.pages_sidebar_location === 'right' &&
                        <div className="takeoff-right-toggle-item"
                            onClick={() => handleChangeTakeoffSettings({
                                ...takeoffSettings,
                                show_pages_sidebar: true
                            })}
                            style={{
                                top: takeoffSettings?.ai_sidebar_location === 'right' ? 31 + 5 + 'px' : 0
                            }}
                        >
                            <IconChevronsLeft size={20} stroke={1} />

                            <div className="takeoff-right-toggle-item-name">
                                <IconLayoutGrid size={20} stroke={1} />
                                <div className="takeoff-right-toggle-item-name-text">
                                    Pages
                                </div>
                            </div>
                        </div>
                    }

                    {takeoffSettings?.measurement_sidebar_location === 'right' &&
                        <div className="takeoff-right-toggle-item"
                            onClick={() => handleChangeTakeoffSettings({
                                ...takeoffSettings,
                                show_measurement_sidebar: true
                            })}
                            style={{
                                top: takeoffSettings?.ai_sidebar_location === 'right' && takeoffSettings?.pages_sidebar_location === 'right'
                                    ? 31 + 31 + 5 + 5 + 'px'
                                    : (takeoffSettings?.ai_sidebar_location === 'right' || takeoffSettings?.pages_sidebar_location === 'right')
                                        ? 31 + 5 + 'px'
                                        : '0px'
                            }}
                        >
                            <IconChevronsLeft size={20} stroke={1} />

                            <div className="takeoff-right-toggle-item-name">
                                <IconList size={20} stroke={1} />
                                <div className="takeoff-right-toggle-item-name-text">
                                    Takeoffs
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }

            {takeoffSettings?.show_pages_sidebar &&
                <PagesSidebar
                    sidebarWidth={pagesSidebarWidth}
                    setSidebarWidth={setShowPagesSidebarWidth}
                />
            }

            {takeoffSettings?.show_measurement_sidebar &&
                <MeasurementSidebar
                    sidebarWidth={measurementSidebarWidth}
                    setSidebarWidth={setShowMeasurementsSidebarWidth}
                />
            }

            {takeoffSettings?.show_ai_sidebar &&
                <AISidebar
                    sidebarWidth={aiSidebarWidth}
                    setSidebarWidth={setShowAISidebarWidth}
                    currentAITab={currentAITab}
                    setCurrentAITab={setCurrentAITab}
                />
            }
        </>
    )
}