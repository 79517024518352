import React, { useContext, useState } from "react";
import { Circle, Rect, Layer, Line, Stage, Image } from "react-konva";
import { useSelector } from "react-redux";

import axios from "axios";

import { API_ROUTE } from "../../..";
import { TakeoffContext } from "../../helper/Context";
import { selectAuth } from "../../../redux/slices/authSlice";
import { Portal } from "react-konva-utils";
import { v4 } from "uuid";

export default function AILegendEntry({ }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages,
        groups, setGroups,
        currentGroup,
        createMeasurement,
        setDrawingRectangle,
        history, setHistory,
        currentHistory, setCurrentHistory,
        setMeasurements,
        tree, setTree,
        handleChangeFilter,
        AILocalization, setAILocalization,
        AIMessages, setAIMessages,
        AILegends, setAILegends,
        localizationLegends, setLocalizationLegends,
        currentLegend, setCurrentLegend,
        populatingLegend, setPopulatingLegend,
        drawingLegendEntryRect, setDrawingLegendEntryRect,
        drawingLegendEntryRun, setDrawingLegendEntryRun,
    } = useContext(TakeoffContext);

    const [drawing, setDrawing] = useState(false);

    const handlePopulate = (points) => {
        axios({
            method: 'put',
            url: `${API_ROUTE}/api/populate-virtual-legend/`,
            data: {
                'page': pageID,
                'virtual_legend': currentLegend,
                'x1': Math.min(points.x1, points.x2),
                'y1': Math.min(points.y1, points.y2),
                'x2': Math.max(points.x1, points.x2),
                'y2': Math.max(points.y1, points.y2),
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);

                setPopulatingLegend({
                    'message': '',
                    'progress': 0,
                    'show': true,
                    'running': true,
                    'id': null,
                })
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <>
            {drawingLegendEntryRect.start && drawingLegendEntryRect.end &&
                <Rect
                    x={Math.min(drawingLegendEntryRect.start.x, drawingLegendEntryRect.end.x)}
                    y={Math.min(drawingLegendEntryRect.start.y, drawingLegendEntryRect.end.y)}
                    width={Math.abs(drawingLegendEntryRect.start.x - drawingLegendEntryRect.end.x)}
                    height={Math.abs(drawingLegendEntryRect.start.y - drawingLegendEntryRect.end.y)}
                    fill={'#9DD9F3'}
                    stroke={'#006AFE'}
                    opacity={0.5}
                />
            }

            <Portal
                selector={'.top-layer'}
                enabled={drawingLegendEntryRect.start}
            >
                <Rect
                    x={0}
                    y={0}
                    width={pages[pageID].width}
                    height={pages[pageID].height}
                    onMouseEnter={(e) => {
                        e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+rectangle.svg') 8 24, auto"
                    }}
                    onMouseLeave={(e) => {
                        e.target.getStage().container().style.cursor = 'default';
                    }}
                    onClick={(e) => {
                        if (e.evt.button !== 0) return;

                        if (!populatingLegend.running) {
                            if (drawing) {
                                const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                                const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                                setDrawing(false);
                                setDrawingLegendEntryRect(pre => ({
                                    ...pre,
                                    end: { x: x, y: y },
                                }));
                                handlePopulate({ x1: drawingLegendEntryRect.start.x, y1: drawingLegendEntryRect.start.y, x2: x, y2: y });
                            } else {
                                setDrawing(true);
                                const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                                const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                                setDrawingLegendEntryRect(pre => ({
                                    ...pre,
                                    start: { x: x, y: y },
                                }));
                            }
                        }
                    }}
                    onMouseMove={(e) => {
                        if (drawing) {
                            const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                            const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                            setDrawingLegendEntryRect(pre => ({
                                ...pre,
                                end: { x: x, y: y },
                            }));
                        }
                    }}
                />

                {AILocalization && AILocalization.filter(a => !a.count_dot && !a.measurement).sort((a, b) => (b.x2 - b.x1) * (b.y2 - b.y1) - (a.x2 - a.x1) * (a.y2 - a.y1)).map((rect, i) =>
                    <AILocalizationDot
                        key={i}
                        rect={rect}
                        pageID={pageID}
                        pages={pages}
                    />
                )}
            </Portal>
        </>
    );
}

function AILocalizationDot({ rect, pageID, pages }) {
    const auth = useSelector(selectAuth);

    const {
        currentLegend, setCurrentLegend,
        populatingLegend, setPopulatingLegend,
    } = useContext(TakeoffContext);

    const [hovering, setHovering] = useState(false);

    const handlePopulate = () => {
        axios({
            method: 'patch',
            url: `${API_ROUTE}/api/populate-virtual-legend/`,
            data: {
                'page': pageID,
                'virtual_legend': currentLegend,
                'symbol': rect.id,
                'x1': rect.x1,
                'y1': rect.y1,
                'x2': rect.x2,
                'y2': rect.y2,
                'uuid': v4(),
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);

                setPopulatingLegend({
                    'message': '',
                    'progress': 0,
                    'show': true,
                    'running': true,
                    'id': null,
                })
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <Rect
            x={rect.x1}
            y={rect.y1}
            width={rect.x2 - rect.x1}
            height={rect.y2 - rect.y1}
            fill={hovering ? "rgba(0, 0, 255, 0.1)" : "rgba(0, 0, 255, 0.1)"}
            stroke={hovering ? "blue" : ""}
            strokeWidth={1 / pages[pageID].zoom}
            onMouseEnter={(e) => {
                setHovering(true);
                e.target.getStage().container().style.cursor = "pointer";
            }}
            onMouseLeave={(e) => {
                setHovering(false);
                e.target.getStage().container().style.cursor = "default";
            }}
            onClick={(e) => {
                if (e.evt.button !== 0) return;

                handlePopulate();
            }}
        />
    );
}