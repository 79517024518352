import React, { useContext, useState } from "react";
import { Circle, Rect, Layer, Line, Stage, Image } from "react-konva";
import { useSelector } from "react-redux";

import axios from "axios";

import { API_ROUTE } from "../../..";
import { TakeoffContext } from "../../helper/Context";
import { selectAuth } from "../../../redux/slices/authSlice";

export default function AICountRectangle({ }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages,
        groups, setGroups,
        currentGroup,
        createMeasurement,
        setDrawingRectangle,
        history, setHistory,
        currentHistory, setCurrentHistory,
        setMeasurements,
        tree, setTree,
        handleChangeFilter,
        AILocalization, setAILocalization,
        AIAutoCountRect, setAIAutoCountRect,
        AIMessages, setAIMessages,
        autoCountReference, setAutoCountReference,
        AIAutoCountExample,
        currentLegend,
        AILegends,
    } = useContext(TakeoffContext);

    const [drawing, setDrawing] = useState(false);

    const handleInference = (points) => {
        if (autoCountReference === 'auto') {
            axios({
                method: 'post',
                url: `${API_ROUTE}/api/ai-auto-count/`,
                data: {
                    'page': pageID,
                    'contractor': auth.contractor.id,
                    'reference': 'auto',
                    'location': 'rectangle',
                    'x1': Math.min(points.x1, points.x2),
                    'y1': Math.min(points.y1, points.y2),
                    'x2': Math.max(points.x1, points.x2),
                    'y2': Math.max(points.y1, points.y2),
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json",
                }
            })
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else if (autoCountReference === 'example') {
            axios({
                method: 'post',
                url: `${API_ROUTE}/api/ai-auto-count/`,
                data: {
                    'page': pageID,
                    'contractor': auth.contractor.id,
                    'reference': 'example',
                    'location': 'rectangle',
                    'x1': Math.min(points.x1, points.x2),
                    'y1': Math.min(points.y1, points.y2),
                    'x2': Math.max(points.x1, points.x2),
                    'y2': Math.max(points.y1, points.y2),
                    'example': AIAutoCountExample?.[pageID]?.symbol,
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json",
                }
            })
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else if (autoCountReference === 'legend') {
            axios({
                method: 'post',
                url: `${API_ROUTE}/api/ai-auto-count/`,
                data: {
                    'page': pageID,
                    'contractor': auth.contractor.id,
                    'reference': 'legend',
                    'location': 'rectangle',
                    'x1': Math.min(points.x1, points.x2),
                    'y1': Math.min(points.y1, points.y2),
                    'x2': Math.max(points.x1, points.x2),
                    'y2': Math.max(points.y1, points.y2),
                    'legend': currentLegend || Object.keys(AILegends)[0],
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json",
                }
            })
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        setAIMessages(prev => ({
            ...prev,
            [pageID]: {
                ...prev[pageID],
                'autoCount': {
                    ...prev[pageID]?.autoCount,
                    'progress': 1 / 100,
                    'show': true,
                    'running': true,
                }
            }
        }));
    }

    return (
        <>
            {AIAutoCountRect?.[pageID]?.end && AIAutoCountRect?.[pageID]?.start &&
                <Rect
                    x={AIAutoCountRect?.[pageID]?.start.x}
                    y={AIAutoCountRect?.[pageID]?.start.y}
                    width={AIAutoCountRect?.[pageID]?.end.x - AIAutoCountRect?.[pageID]?.start.x}
                    height={AIAutoCountRect?.[pageID]?.end.y - AIAutoCountRect?.[pageID]?.start.y}
                    fill={'#9DD9F3'}
                    stroke={'#006AFE'}
                    opacity={0.5}
                />
            }

            {AIAutoCountRect?.[pageID]?.isComplete && AIMessages[pageID]?.autoCount?.running && AIAutoCountRect?.[pageID]?.start && AIAutoCountRect?.[pageID]?.end
                && AILocalization.filter(a => !a.legend && !a.count_dot).filter((item) => item.x1 > Math.min(AIAutoCountRect?.[pageID]?.start.x, AIAutoCountRect?.[pageID]?.end.x) && item.x2 < Math.max(AIAutoCountRect?.[pageID]?.start.x, AIAutoCountRect?.[pageID]?.end.x) && item.y1 > Math.min(AIAutoCountRect?.[pageID]?.start.y, AIAutoCountRect?.[pageID]?.end.y) && item.y2 < Math.max(AIAutoCountRect?.[pageID]?.start.y, AIAutoCountRect?.[pageID]?.end.y)).map((item, index) => (
                    <Rect
                        key={index}
                        x={item.x1}
                        y={item.y1}
                        width={item.x2 - item.x1}
                        height={item.y2 - item.y1}
                        fill='rgba(0, 0, 255, 0.1)'
                        stroke='Blue'
                        opacity={0.5}
                    />
                ))}

            <Rect
                x={0}
                y={0}
                width={pages[pageID].width}
                height={pages[pageID].height}
                onMouseUp={(e) => {
                    if (e.evt.button !== 0) return;

                    if (!AIMessages[pageID]?.autoCount?.running) {
                        if (drawing) {
                            const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                            const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                            setDrawing(false);
                            setAIAutoCountRect(prev => ({
                                ...prev,
                                [pageID]: {
                                    ...prev?.[pageID],
                                    end: { x: x, y: y },
                                    isComplete: true,
                                }
                            }));
                            handleInference({ x1: AIAutoCountRect?.[pageID]?.start.x, y1: AIAutoCountRect?.[pageID]?.start.y, x2: x, y2: y });
                        } else {
                            setDrawing(true);
                            const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                            const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                            setAIAutoCountRect(prev => ({
                                ...prev,
                                [pageID]: {
                                    ...prev?.[pageID],
                                    start: { x: x, y: y },
                                    isComplete: false,
                                }
                            }));
                        }
                    }
                }}
                onMouseMove={(e) => {
                    if (drawing && !AIMessages[pageID]?.autoCount?.running) {
                        const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                        const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                        setAIAutoCountRect(prev => ({
                            ...prev,
                            [pageID]: {
                                ...prev?.[pageID],
                                end: { x: x, y: y }
                            }
                        }));
                    }
                }}
            />
        </>
    );
}