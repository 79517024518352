import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux";
import axios from "axios";

import {
    IconFolderPlus, IconFilterStar, IconLayoutSidebarRightCollapse, IconLayoutList, IconLayoutGrid,
    IconLayoutSidebar, IconLayoutSidebarRight, IconLayoutSidebarLeftCollapse, IconListNumbers,
    IconCircleChevronLeft, IconCircleChevronRight, IconArrowBarToLeft, IconArrowBarToRight,
    IconStarFilled, IconStar, IconChevronRightPipe, IconChevronLeftPipe, IconLayout, IconSearch, IconLayoutNavbarCollapse,
    IconLayoutNavbarExpand,
    IconX,
    IconList,
} from "@tabler/icons-react";

import "../styles/PagesSidebar.css";

import { TakeoffContext } from "../helper/Context";
import { selectAuth } from "../../redux/slices/authSlice";

import { API_ROUTE } from "../../index";
import Page from "./pages/Page";
import ButtonHoverInfo from "../../components/ButtonHoverInfo";
import DefaultButton from "../../components/DefaultButton";
import PageNameList from "./pages/PageNameList";
import DefaultIconButton from "../../components/DefaultIconButton";
import PageList from "./pages/PageList";
import PageGrid from "./pages/PageGrid";
import ViewOptions from "./pages/ViewOptions";
import ContextMenu from "../../components/ContextMenu";
import { Tooltip } from "react-tooltip";
import Popup from "reactjs-popup";
import { IconRobot } from "@tabler/icons-react";

export default function PagesSidebar({ sidebarWidth, setSidebarWidth }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages, setPages,
        project, setProject,
        takeoffSettings, setTakeoffSettings,
        handleChangeTakeoffSettings,
        handleUpdateProject,
        pageGroups, setPageGroups,
        ungroupedPages, setUngroupedPages,
        keybinds,
        AIAutoFinding,
        hintingSAM,
        hintingLength,
        AIAutoCountRect,
        AIAutoCountPoly,
        AIAutoMeasureLengthRect,
        hintingAutoMeasure,
        hintingAutoMeasurePerim,
        hintingAutoFindDW,
        selectingLegend,
        drawingLegend,
        drawingLegendEntry,
        drawingLegendEntryRun,
        AIAutoCountExample,
    } = useContext(TakeoffContext);

    const [filterStarred, setFilterStarred] = useState(false);

    const [useNames, setUseNames] = useState(false);
    const [useGrid, setUseGrid] = useState(true);
    const [useList, setUseList] = useState(false);

    const [showSearch, setShowSearch] = useState(false);
    const [search, setSearch] = useState('');

    const [newName, setNewName] = useState('');
    const [creatingGroup, setCreatingGroup] = useState(false);

    const [showPageGroups, setShowPageGroups] = useState({});

    const sidebarRef = useRef(null);
    const [isResizing, setIsResizing] = useState(false);

    const [showViewOptions, setShowViewOptions] = useState(false);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape' && document.activeElement.tagName === 'INPUT') {
                setCreatingGroup(false);
                setNewName('');

                if (document.getElementById('search-pages-input') === document.activeElement) {
                    document.activeElement.blur();
                }
            }

            if (e.key === 'Enter' && document.activeElement.tagName === 'INPUT' && !e.defaultPrevented) {
                document.activeElement.blur();
            }
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }

    }, [newName, creatingGroup]);

    const startResizing = useCallback(() => {
        setIsResizing(true);
    }, []);

    const stopResizing = useCallback((e) => {
        if (isResizing) {
            let temp;

            if (takeoffSettings?.pages_sidebar_location === 'left') {
                temp = Math.min(Math.max(e.clientX - sidebarRef.current.getBoundingClientRect().left, 0.17 * window.innerWidth), 0.30 * window.innerWidth)
            } else {
                temp = Math.min(Math.max(sidebarRef.current.getBoundingClientRect().right - e.clientX, 0.17 * window.innerWidth), 0.30 * window.innerWidth);
            }

            handleChangeTakeoffSettings({
                ...takeoffSettings,
                pages_sidebar_width: temp,
            })

            setSidebarWidth(temp);
        }

        setIsResizing(false);
    }, [isResizing, takeoffSettings]);

    const resize = useCallback((e) => {
        if (isResizing) {
            let newWidth = sidebarWidth;

            if (takeoffSettings?.pages_sidebar_location === 'left') {
                newWidth = Math.min(Math.max(e.clientX - sidebarRef.current.getBoundingClientRect().left, 0.17 * window.innerWidth), 0.30 * window.innerWidth);

                //setSidebarWidth(Math.min(Math.max(e.clientX - sidebarRef.current.getBoundingClientRect().left, 0.17 * window.innerWidth), 0.30 * window.innerWidth));
            } else {
                newWidth = Math.min(Math.max(sidebarRef.current.getBoundingClientRect().right - e.clientX, 0.17 * window.innerWidth), 0.30 * window.innerWidth);

                //setSidebarWidth(Math.min(Math.max(sidebarRef.current.getBoundingClientRect().right - e.clientX, 0.17 * window.innerWidth), 0.30 * window.innerWidth));
            }

            setTakeoffSettings(prev => ({
                ...prev,
                pages_sidebar_width: newWidth,
            }))
        }
    }, [isResizing, takeoffSettings]);

    const handleReset = () => {
        handleChangeTakeoffSettings({
            ...takeoffSettings,
            pages_sidebar_width: window.innerWidth * 0.20,
        });

        setSidebarWidth('20%');
    }

    useEffect(() => {
        window.addEventListener("mousemove", resize);
        window.addEventListener("mouseup", stopResizing);

        return () => {
            window.removeEventListener("mousemove", resize);
            window.removeEventListener("mouseup", stopResizing);
        };
    }, [resize, stopResizing]);

    useEffect(() => {
        if ((pageGroups && Object.keys(pageGroups).length + 1 !== Object.keys(showPageGroups).length)
            || (!pageGroups && Object.keys(showPageGroups).length !== 1)
        ) {
            const temp = {};
            Object.keys(pageGroups).forEach(key => {
                temp[key] = false;
            });
            temp['ungrouped'] = true;
            setShowPageGroups(temp);
        }
    }, [pageGroups]);

    const handleCreateGroup = () => {
        if (newName === '' || !newName) {
            setTimeout(() => {
                setCreatingGroup(false);
            }, 100);
            return;
        }

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/pagegroups/`,
            data: {
                name: newName,
                project: project.id,
                color: '#ADD8E6',
                index: pageGroups ? Object.keys(pageGroups).length : 1,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        }).then(res => {
            setPageGroups(prev => {
                const temp = { ...prev };
                temp[res.data.id] = {
                    ...res.data,
                    pages: [],
                };
                return temp;
            });

            setNewName('');
            setCreatingGroup(false);
        }).catch(err => {
            console.log(err);
        });
    }

    const handleGroupPage = (page, groupID) => {
        console.log(page, groupID);

        if (pages[page].group === groupID) return;

        if (!pages[page].group) {
            setUngroupedPages(prev => prev.filter(p => p != page));
            console.log('ungrouped', ungroupedPages);
        } else {
            setPageGroups(prev => {
                const temp = { ...prev };
                temp[pages[page].group].pages = temp[pages[page].group].pages.filter(p => p != page);
                return temp;
            });
        }

        if (groupID && String(groupID) !== '-1' && String(groupID) !== 'None') {
            setPageGroups(prev => {
                const temp = { ...prev };
                temp[groupID].pages = [...temp[groupID].pages, page];
                return temp;
            });

            setPages(prev => ({
                ...prev,
                [page]: {
                    ...prev[page],
                    group: groupID,
                }
            }));

            axios({
                method: 'put',
                url: `${API_ROUTE}/api/pages/${page}/`,
                data: {
                    project: project.id,
                    group: groupID,
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    'Content-Type': 'application/json',
                }
            }).then(res => {
                console.log(res);
            }).catch(err => {
                console.log(err);
            });
        } else {
            setUngroupedPages(prev => ([...prev, page]));

            setPages(prev => ({
                ...prev,
                [page]: {
                    ...prev[page],
                    group: null,
                }
            }));

            axios({
                method: 'put',
                url: `${API_ROUTE}/api/pages/${page}/`,
                data: {
                    project: project.id,
                    group: null,
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    'Content-Type': 'application/json',
                }
            }).then(res => {
                console.log(res);
            }).catch(err => {
                console.log(err);
            });
        }
    }

    const handleGroupPages = (selectedPages, groupID) => {
        if (!selectedPages || !selectedPages.length) return;

        let newPages = { ...pages };
        let newUngroupedPages = [...ungroupedPages];
        let newPageGroups = { ...pageGroups };

        selectedPages.forEach(page => {
            if (pages[page].group === groupID) return;

            if (!pages[page].group) {
                newUngroupedPages = newUngroupedPages.filter(p => p != page);
            } else {
                newPageGroups[pages[page].group].pages = newPageGroups[pages[page].group].pages.filter(p => p != page);
            }

            if (groupID && String(groupID) !== '-1' && String(groupID) !== 'None') {
                newPageGroups[groupID].pages = [...newPageGroups[groupID].pages, page];

                pages[page].group = groupID;
            } else {
                newUngroupedPages = [...newUngroupedPages, page];

                pages[page].group = null;
            }
        })

        setPages(newPages);
        setUngroupedPages(newUngroupedPages);
        setPageGroups(newPageGroups);

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/group-pages/`,
            data: {
                pages: selectedPages,
                groupID: groupID === '-1' ? null : groupID,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        }).then(res => {
            console.log(res);
        }).catch(err => {
            console.log(err);
        });
    }

    const AIActive = useMemo(() => {
        return AIAutoFinding?.[pageID]?.show
            || hintingSAM
            || hintingLength
            || AIAutoCountRect?.[pageID]?.show
            || AIAutoCountPoly?.[pageID]?.show
            || AIAutoMeasureLengthRect?.[pageID]?.show
            || hintingAutoMeasure?.[pageID]?.show
            || hintingAutoMeasurePerim?.[pageID]?.show
            || hintingAutoFindDW?.[pageID]?.show
            || selectingLegend
            || drawingLegend
            || drawingLegendEntry
            || drawingLegendEntryRun
            || AIAutoCountExample?.[pageID]?.show;
    }, [pageID, AIAutoFinding, hintingSAM, hintingLength, AIAutoCountRect, AIAutoCountPoly, AIAutoMeasureLengthRect, hintingAutoMeasure, hintingAutoMeasurePerim, hintingAutoFindDW, selectingLegend, drawingLegend, drawingLegendEntry, drawingLegendEntryRun, AIAutoCountExample]);

    return (
        <div
            className={takeoffSettings?.pages_sidebar_location === 'left' ? "takeoffsidebar-pages-container" : "takeoffsidebar-container"}
            style={{
                width: sidebarWidth,
                boxShadow: AIActive && "0px 0px 5px 0px #006aef",
                borderLeft: AIActive && takeoffSettings?.pages_sidebar_location === 'right' && "1px solid #006aef",
                borderRight: AIActive && takeoffSettings?.pages_sidebar_location === 'left' && "1px solid #006aef",
            }}
            //onMouseDown={(e) => e.preventDefault()}
            ref={sidebarRef}
            id="pagessidebar"
        >
            <div className={takeoffSettings?.pages_sidebar_location === 'left'
                ? "measurementsidebar-left-resizer"
                : "measurementsidebar-resizer"}
                onMouseDown={startResizing}
                onContextMenu={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setIsResizing(false);
                    setSidebarWidth('20%');
                    handleReset();
                }}
            />

            <div className="pagessidebar-container">
                <div className="measurementsidebar-toggle">
                    {takeoffSettings?.ai_sidebar_location === takeoffSettings?.pages_sidebar_location &&
                        <div
                            className='takeoffnavbar-toggle-button'
                            id='takeoffnavbar-settings-button-left-ai'
                            style={takeoffSettings?.show_ai_sidebar ? { backgroundColor: 'aliceblue', color: '#006AFF', zIndex: 3 } : { zIndex: 3 }}
                            onClick={() => {
                                let newTakeoffSettings = { ...takeoffSettings };

                                if (!newTakeoffSettings?.show_ai_sidebar) {
                                    newTakeoffSettings.show_ai_sidebar = true;

                                    if (newTakeoffSettings?.measurement_sidebar_location === newTakeoffSettings?.ai_sidebar_location) {
                                        newTakeoffSettings.show_measurement_sidebar = false
                                    }

                                    if (newTakeoffSettings?.pages_sidebar_location === newTakeoffSettings?.ai_sidebar_location) {
                                        newTakeoffSettings.show_pages_sidebar = false
                                    }
                                }
                                handleChangeTakeoffSettings(newTakeoffSettings);
                            }}
                        >
                            <div><IconRobot size={20} stroke={1} /></div>
                            <div><nobr>AI tools</nobr></div>
                        </div>
                    }

                    <Tooltip delayShow={500} anchorSelect='#takeoffnavbar-settings-button-left-ai' place='top-start'>
                        Toggle AI sidebar ({keybinds?.ToggleAISidebar?.control ? 'Ctrl + ' : ''}{keybinds?.ToggleAISidebar?.shift ? 'Shift + ' : ''}{keybinds?.ToggleAISidebar?.key})
                    </Tooltip>

                    <div
                        className='takeoffnavbar-toggle-button'
                        id='takeoffnavbar-pages-left-button'
                        style={takeoffSettings?.show_pages_sidebar ? { backgroundColor: 'aliceblue', color: '#006AFF', zIndex: 3 } : { zIndex: 3 }}
                        onClick={() => {
                            let newTakeoffSettings = { ...takeoffSettings };

                            if (!newTakeoffSettings?.show_pages_sidebar) {
                                newTakeoffSettings.show_pages_sidebar = true;

                                if (newTakeoffSettings?.measurement_sidebar_location === newTakeoffSettings?.pages_sidebar_location) {
                                    newTakeoffSettings.show_measurement_sidebar = false
                                }

                                if (newTakeoffSettings?.ai_sidebar_location === newTakeoffSettings?.pages_sidebar_location) {
                                    newTakeoffSettings.show_ai_sidebar = false
                                }
                            }

                            handleChangeTakeoffSettings(newTakeoffSettings);
                        }}

                    >
                        <div><IconLayoutGrid size={20} stroke={1} /></div>
                        <div>Pages</div>
                    </div>

                    <Tooltip delayShow={500} anchorSelect='#takeoffnavbar-pages-left-button' place='top-start'>
                        Toggle pages sidebar ({keybinds?.TogglePagesSidebar?.control ? 'Ctrl + ' : ''}{keybinds?.TogglePagesSidebar?.shift ? 'Shift + ' : ''}{keybinds?.TogglePagesSidebar?.key})
                    </Tooltip>

                    {takeoffSettings?.measurement_sidebar_location === takeoffSettings?.pages_sidebar_location &&
                        <div
                            id='takeoffnavbar-measurements-button-left'
                            className='takeoffnavbar-toggle-button'
                            style={takeoffSettings?.show_measurement_sidebar ? { backgroundColor: 'aliceblue', color: '#006AFF', zIndex: 3 } : { zIndex: 3 }}
                            onClick={() => {
                                let newTakeoffSettings = { ...takeoffSettings };

                                if (!newTakeoffSettings?.show_measurement_sidebar) {
                                    newTakeoffSettings.show_measurement_sidebar = true;

                                    if (newTakeoffSettings?.pages_sidebar_location === newTakeoffSettings?.measurement_sidebar_location) {
                                        newTakeoffSettings.show_pages_sidebar = false
                                    }

                                    if (newTakeoffSettings?.ai_sidebar_location === newTakeoffSettings?.measurement_sidebar_location) {
                                        newTakeoffSettings.show_ai_sidebar = false
                                    }
                                }

                                handleChangeTakeoffSettings(newTakeoffSettings); takeoffSettings?.show_pages_sidebar
                            }}

                        >
                            <div><IconList size={20} stroke={1} /></div>
                            <div>Takeoffs</div>
                        </div>
                    }

                    <Tooltip delayShow={500} anchorSelect='#takeoffnavbar-measurements-button-left' place='top-start'>
                        Toggle measurements sidebar ({keybinds?.ToggleMeasurementSidebar?.control ? 'Ctrl + ' : ''}{keybinds?.ToggleMeasurementSidebar?.shift ? 'Shift + ' : ''}{keybinds?.ToggleMeasurementSidebar?.key})
                    </Tooltip>
                </div>

                <div className="pagessidebar-header-container">
                    <div className="pagessidebar-header">
                        <div className='pagessidebar-header-buttons'>
                            <div
                                className='measurementsidebar-header-button'
                                id='pagessidebar-create-group-button'
                                onClick={() => {
                                    setCreatingGroup(true);
                                }}
                                style={{
                                    backgroundColor: creatingGroup && 'aliceblue'
                                }}
                            >
                                <IconFolderPlus size={20} stroke={1}
                                    style={{
                                        color: creatingGroup && '#006aff'
                                    }}
                                />
                            </div>

                            <Tooltip className='tooltip' anchorSelect="#pagessidebar-create-group-button" delayShow={500} place={takeoffSettings?.pages_sidebar_location === 'left' ? 'bottom-start' : 'bottom-end'}>
                                Create group
                            </Tooltip>

                            <div
                                className="measurementsidebar-header-button"
                                id="pagessidebar-search-button"
                                onClick={() => {
                                    if (showSearch) {
                                        setSearch('');
                                        setShowSearch(false);
                                    } else {
                                        setShowSearch(true);
                                        setSearch('');
                                    }
                                }}
                                style={{
                                    backgroundColor: showSearch && 'aliceblue'
                                }}
                            >
                                <IconSearch size={20} stroke={1}
                                    style={{
                                        color: showSearch && '#006aff'
                                    }}
                                />
                            </div>

                            <Tooltip className='tooltip' anchorSelect="#pagessidebar-search-button" delayShow={500} place={takeoffSettings?.pages_sidebar_location === 'left' ? 'bottom-start' : 'bottom-end'}>
                                Search pages
                            </Tooltip>

                            <Popup
                                trigger={
                                    <div
                                        className="measurementsidebar-header-button"
                                        id="pagessidebar-viewoptions-button"
                                        style={{
                                            backgroundColor: showViewOptions && 'aliceblue'
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();

                                            setShowViewOptions(prev => !prev);
                                        }}
                                    >
                                        <IconLayout size={20} stroke={1} color={showViewOptions ? "#006aef" : "#000"} />
                                    </div>
                                }
                                position="bottom left"
                                on=""
                                open={showViewOptions}
                                onClose={() => setShowViewOptions(false)}
                                closeOnDocumentClick
                                closeOnEscape
                                mouseLeaveDelay={300}
                                mouseEnterDelay={0}
                            >
                                <div>
                                    <div
                                        className="contextmenu-item"
                                        id="pagessidebar-viewoptions-names"
                                        onClick={() => {
                                            setUseGrid(false);
                                            setUseList(false);
                                            setUseNames(true);
                                        }}
                                        style={{
                                            backgroundColor: useNames && 'aliceblue'
                                        }}
                                    >
                                        <IconListNumbers size={20} color={useNames ? "#006aef" : "#000"} stroke={1} />
                                        <div>Name view</div>
                                    </div>

                                    {/*<Tooltip className='tooltip' anchorSelect="#pagessidebar-viewoptions-names" delayShow={500} place="right">
                                            Use name view
                                        </Tooltip>*/}

                                    <div
                                        className="contextmenu-item"
                                        id="pagessidebar-viewoptions-grid"
                                        onClick={() => {
                                            setUseGrid(true);
                                            setUseList(false);
                                            setUseNames(false);
                                        }}
                                        style={{
                                            backgroundColor: useGrid && 'aliceblue'
                                        }}
                                    >
                                        <IconLayoutGrid size={20} color={useGrid ? "#006aef" : "#000"} stroke={1} />
                                        <div>Grid view</div>
                                    </div>

                                    {/*<Tooltip className='tooltip' anchorSelect="#pagessidebar-viewoptions-grid" delayShow={500} place="right">
                                            Use grid view
                                        </Tooltip>*/}

                                    <div
                                        className="contextmenu-item"
                                        id="pagessidebar-viewoptions-list"
                                        onClick={() => {
                                            setUseGrid(false);
                                            setUseList(true);
                                            setUseNames(false);
                                        }}
                                        style={{
                                            backgroundColor: useList && 'aliceblue'
                                        }}
                                    >
                                        <IconLayoutList size={20} color={useList ? "#006aef" : "#000"} stroke={1} />
                                        <div>List view</div>
                                    </div>

                                    {/*<Tooltip className='tooltip' anchorSelect="#pagessidebar-viewoptions-list" delayShow={500} place="right">
                                            Use list view
                                        </Tooltip>*/}
                                </div>
                            </Popup>


                            <Tooltip className='tooltip' anchorSelect="#pagessidebar-viewoptions-button" delayShow={500} place={takeoffSettings?.pages_sidebar_location === 'left' ? 'bottom-start' : 'bottom-end'}>
                                View options
                            </Tooltip>

                            {/*
                            <div className="pagessidebar-viewoptions">
                                {showViewOptions ?
                                    <ViewOptions
                                        showViewOptions={showViewOptions}
                                        setShowViewOptions={setShowViewOptions}
                                    >
                                        <div
                                            className="contextmenu-item"
                                            id="pagessidebar-viewoptions-names"
                                            onClick={() => {
                                                setUseGrid(false);
                                                setUseList(false);
                                                setUseNames(true);
                                            }}
                                            style={{
                                                backgroundColor: useNames && 'aliceblue'
                                            }}
                                        >
                                            <IconListNumbers size={20} color={useNames ? "#006aef" : "#000"} stroke={1} />
                                            <div>Name view</div>
                                        </div>


                                        <div
                                            className="contextmenu-item"
                                            id="pagessidebar-viewoptions-grid"
                                            onClick={() => {
                                                setUseGrid(true);
                                                setUseList(false);
                                                setUseNames(false);
                                            }}
                                            style={{
                                                backgroundColor: useGrid && 'aliceblue'
                                            }}
                                        >
                                            <IconLayoutGrid size={20} color={useGrid ? "#006aef" : "#000"} stroke={1} />
                                            <div>Grid view</div>
                                        </div>


                                        <div
                                            className="contextmenu-item"
                                            id="pagessidebar-viewoptions-list"
                                            onClick={() => {
                                                setUseGrid(false);
                                                setUseList(true);
                                                setUseNames(false);
                                            }}
                                            style={{
                                                backgroundColor: useList && 'aliceblue'
                                            }}
                                        >
                                            <IconLayoutList size={20} color={useList ? "#006aef" : "#000"} stroke={1} />
                                            <div>List view</div>
                                        </div>

                                    </ViewOptions>
                                    : null}
                                        </div>*/}

                            {/*
                            <div
                                className="measurementsidebar-header-button"
                                id="pagessidebar-viewoptions-button"
                                style={{
                                    backgroundColor: showViewOptions && 'aliceblue'
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();

                                    setShowViewOptions(prev => !prev);
                                }}
                            >
                                <IconLayout size={20} stroke={1} color={showViewOptions ? "#006aef" : "#000"} />
                            </div>*/}

                            <div
                                className="measurementsidebar-header-button"
                                id="pagessidebar-filter-starred-button"
                                onClick={() => setFilterStarred(prev => !prev)}
                                style={{
                                    backgroundColor: filterStarred && 'aliceblue'
                                }}
                            >
                                <IconStar size={20} color={filterStarred ? "#006aef" : "#000"} stroke={1} />
                                {/*filterStarred
                                    ? <IconStarFilled size={20} color={filterStarred ? "#006aef" : "#000"} stroke={1} />
                                    : <IconStar size={20} color={filterStarred ? "#006aef" : "#000"} stroke={1} />
                            */}
                            </div>

                            <Tooltip className='tooltip' anchorSelect="#pagessidebar-filter-starred-button" delayShow={500} place={takeoffSettings?.pages_sidebar_location === 'left' ? 'bottom-start' : 'bottom-end'}>
                                {filterStarred ? "Show all pages" : "Starred pages only"}
                            </Tooltip>

                            <div
                                className="measurementsidebar-header-button"
                                id="pagessidebar-collapse-all-button"
                                onClick={() => {
                                    if (Object.values(showPageGroups).every(v => v)) {
                                        setShowPageGroups(prev => {
                                            const temp = { ...prev };
                                            Object.keys(temp).forEach(key => {
                                                temp[key] = false;
                                            });
                                            temp['ungrouped'] = false;
                                            return temp;
                                        });
                                    } else {
                                        setShowPageGroups(prev => {
                                            const temp = { ...prev };
                                            Object.keys(temp).forEach(key => {
                                                temp[key] = true;
                                            });
                                            temp['ungrouped'] = true;
                                            return temp;
                                        });
                                    }
                                }}
                            >
                                {Object.values(showPageGroups).some(v => v)
                                    ? <IconLayoutNavbarCollapse size={20} stroke={1} />
                                    : <IconLayoutNavbarExpand size={20} stroke={1} />
                                }
                            </div>

                            <Tooltip className='tooltip' anchorSelect="#pagessidebar-collapse-all-button" delayShow={500} place={takeoffSettings?.pages_sidebar_location === 'left' ? 'bottom-start' : 'bottom-end'}>
                                {Object.values(showPageGroups).some(v => v)
                                    ? "Collapse all"
                                    : "Expand all"
                                }
                            </Tooltip>
                        </div>

                        <div className='pagessidebar-header-buttons'>
                            <div
                                className="measurementsidebar-header-button"
                                id="pagessidebar-move-sidebar-button"
                                onClick={() => {
                                    handleChangeTakeoffSettings({
                                        ...takeoffSettings,
                                        show_pages_sidebar: true,
                                        pages_sidebar_location: takeoffSettings?.pages_sidebar_location === 'left' ? 'right' : 'left',
                                        show_ai_sidebar: takeoffSettings?.ai_sidebar_location !== takeoffSettings?.pages_sidebar_location ? false : takeoffSettings?.show_ai_sidebar,
                                        show_measurement_sidebar: takeoffSettings?.measurement_sidebar_location !== takeoffSettings?.pages_sidebar_location ? false : takeoffSettings?.show_measurement_sidebar,
                                    })

                                    document.getElementById('pagessidebar-move-sidebar-button').dispatchEvent(new MouseEvent('mouseleave'));
                                }}
                            >
                                {takeoffSettings?.pages_sidebar_location === 'left'
                                    ? <IconChevronRightPipe size={20} stroke={1} />
                                    : <IconChevronLeftPipe size={20} stroke={1} />
                                }
                            </div>

                            <Tooltip className='tooltip' anchorSelect="#pagessidebar-move-sidebar-button" delayShow={500} place={takeoffSettings?.pages_sidebar_location === 'left' ? 'bottom-start' : 'bottom-end'}>
                                {takeoffSettings?.pages_sidebar_location === 'left' ? "Move sidebar to the right" : "Move sidebar to the left"}
                            </Tooltip>

                            <div
                                className="measurementsidebar-header-button"
                                id="pagessidebar-hide-sidebar-button"
                                onClick={() => {
                                    handleChangeTakeoffSettings({
                                        ...takeoffSettings,
                                        show_pages_sidebar: false,
                                    })
                                }}
                            >
                                <IconX size={20} stroke={1} />
                            </div>

                            <Tooltip className='tooltip' anchorSelect="#pagessidebar-hide-sidebar-button" delayShow={500} place={takeoffSettings?.pages_sidebar_location === 'left' ? 'bottom-start' : 'bottom-end'}>
                                Hide pages ({keybinds?.TogglePagesSidebar?.control ? 'Ctrl + ' : ''}{keybinds?.TogglePagesSidebar?.shift ? 'Shift + ' : ''}{keybinds?.TogglePagesSidebar?.key})
                            </Tooltip>
                        </div>
                    </div>

                    {(showSearch || creatingGroup) &&
                        <div className="measurementsidebar-header-input-container">
                            {showSearch &&
                                <div className="measurementsidebar-search-container">
                                    <IconSearch size={20} stroke={1} />
                                    <input
                                        type="text"
                                        className="measurementsidebar-group-title-input"
                                        id='search-pages-input'
                                        placeholder="Search pages"
                                        value={search}
                                        onChange={e => setSearch(e.target.value)}
                                    />
                                </div>
                            }

                            {creatingGroup &&
                                <div className="measurementsidebar-search-container">
                                    <IconFolderPlus size={20} stroke={1} />
                                    <input
                                        type="text"
                                        className="measurementsidebar-group-title-input"
                                        placeholder="Group name..."
                                        autoFocus
                                        value={newName}
                                        onChange={e => setNewName(e.target.value)}
                                        onBlur={() => handleCreateGroup()}
                                    />
                                </div>
                            }
                        </div>
                    }
                </div>


                {useNames &&
                    <PageNameList
                        search={search}
                        filterStarred={filterStarred}
                        showPageGroups={showPageGroups}
                        setShowPageGroups={setShowPageGroups}
                        handleGroupPage={handleGroupPage}
                        handleGroupPages={handleGroupPages}
                    />
                }

                {useList &&
                    <PageList
                        search={search}
                        filterStarred={filterStarred}
                        showPageGroups={showPageGroups}
                        setShowPageGroups={setShowPageGroups}
                    />
                }

                {useGrid &&
                    <PageGrid
                        search={search}
                        filterStarred={filterStarred}
                        showPageGroups={showPageGroups}
                        setShowPageGroups={setShowPageGroups}
                        handleGroupPage={handleGroupPage}
                        handleGroupPages={handleGroupPages}
                    />
                }
            </div>
        </div >
    )
}