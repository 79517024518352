import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';

import { validateEmail } from "../helper/validateEmail";
import usePasswordValidator from "../helper/usePasswordValidator";

import { useSelector, useDispatch } from 'react-redux';

import { selectAuth, updateAuth } from '../redux/slices/authSlice';

import "./styles/Register.css";

import { API_ROUTE } from "../index";

export default function JoinCompanyRegister({ }) {
    const params = useParams();
    const inviteLink = params.inviteLink;

    const dispatch = useDispatch();

    const auth = useSelector(selectAuth);

    const [key, setKey] = useState(null);
    const [keyError, setKeyError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const [password, setPassword, passwordError] = usePasswordValidator({
        min: 8,
        max: 20
    });

    const [company, setCompany] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (inviteLink && auth?.user?.id) {
            axios({
                method: 'get',
                url: `${API_ROUTE}/api/join-company/`,
                params: {
                    inviteLink: inviteLink,
                    userID: auth?.user?.id
                },
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((response) => {
                    console.log(response);

                    if (response.data?.status === 'error') {
                        setError(response.data.error);
                    } else if (response.data?.status === 'joined') {
                        window.location.assign('/dashboard');
                    } else if (response.data?.status === 'registered') {
                        window.location.assign('/join-company/' + inviteLink);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [inviteLink, auth]);

    useEffect(() => {
        if (!email) {
            setEmailError("");
        } else {
            if (validateEmail(email)) {
                setEmailError("");
            } else {
                setEmailError("Please enter a valid email.");
            }
        }
    }, [email]);

    useEffect(
        () => {
            if (!confirmPassword || !password) {
                setConfirmPasswordError("");
            } else {
                if (password !== confirmPassword) {
                    setConfirmPasswordError("The passwords must match.");
                } else {
                    setConfirmPasswordError("");
                }
            }
        },
        [password, confirmPassword]
    );

    function buttonDisabled() {
        if (emailError !== "" || passwordError !== "" || confirmPasswordError !== "" || email === "" || password === "" || confirmPassword === "") {
            return true;
        }
        return false;
    }

    function handleSubmit(e) {
        e.preventDefault();

        axios({
            method: "post",
            url: `${API_ROUTE}/api/register/`,
            data: {
                key: key,
                email: email,
                username: email,
                password: password,
                password2: confirmPassword,
            },
            headers: {
                "Content-Type": "application/json"
            },
        })
            .then((createuserResponse) => {
                console.log(createuserResponse);

                axios({
                    method: 'post',
                    url: `${API_ROUTE}/api-token-auth/`,
                    data: {
                        username: email,
                        password: password,
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    },
                })
                    .then((tokenresponse) => {
                        axios({
                            method: 'post',
                            url: `${API_ROUTE}/api/login/`,
                            data: {
                                'token': tokenresponse.data.token,
                                'email': email
                            },
                            headers: {
                                'Content-Type': 'application/json'
                            },
                        })
                            .then((userResponse) => {
                                console.log(userResponse.data);

                                dispatch(updateAuth({
                                    user: userResponse.data.user,
                                    contractor: userResponse.data.contractor,
                                    token: tokenresponse.data.token,
                                }));

                                axios({
                                    method: 'get',
                                    url: `${API_ROUTE}/api/join-company/`,
                                    params: {
                                        inviteLink: inviteLink,
                                        userID: userResponse.data.user?.id
                                    },
                                    headers: {
                                        "Content-Type": "application/json"
                                    }
                                })
                                    .then((response) => {
                                        console.log(response);

                                        if (response.data?.status === 'error') {
                                            setError(response.data.error);
                                        } else if (response.data?.status === 'joined') {
                                            window.location.assign('/dashboard');
                                            setError("You have already joined this company.");
                                        } else if (response.data?.status === 'registered') {
                                            window.location.assign('/join-company/' + inviteLink);
                                        } else {
                                            setCompany(response.data);
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);

                if (error.response.data.error === "Invalid key.") {
                    setKeyError("Invalid key.");
                }

                if (error.response.data.email) {
                    setEmailError("This email is already registered.");
                }
            })
    }

    return (
        <div className="registercontainer">
            <a href="/">
                <div className='login-logo-container'>
                    <img src={"https://bobyard-public-images.s3.us-west-2.amazonaws.com/bobyard+(2).png"} className='login-logo-image' />
                </div>
            </a>

            <div className='login-title'>Join your team on Bobyard</div>

            <div className="login-container">
                Join link: {inviteLink}
                <br></br>
                <br></br>
            </div>

            {!error
                ? <>
                    <div className="login-container">
                        Create an account to join {company?.name}
                        <br></br>
                        <br></br>

                        <div className="error">{keyError}</div>
                        <input
                            value={key}
                            className="inputregister"
                            onChange={e => setKey(e.target.value)}
                            type="text"
                            placeholder="Key from Bobyard team"
                        />

                        <div className="error">{emailError}</div>
                        <input
                            value={email}
                            className="inputregister"
                            onChange={e => setEmail(e.target.value)}
                            type="text"
                            placeholder="Email"
                        />

                        <div className="error">{passwordError}</div>
                        <input
                            value={password}
                            className="inputregister"
                            onChange={e => setPassword(e.target.value)}
                            type="password"
                            placeholder="Password"
                        />

                        <div className="error">{confirmPasswordError}</div>
                        <input
                            value={confirmPassword}
                            className="inputregister"
                            onChange={e => setConfirmPassword(e.target.value)}
                            type="password"
                            placeholder="Confirm Password"
                        />
                    </div>

                    <button
                        type="submit"
                        className="default-button register-button"
                        onClick={e => handleSubmit(e)}
                        disabled={buttonDisabled()}
                    >
                        Create account
                    </button>


                    {/*<div className="register-note">
                        By clicking "Create account", you agree <br></br>to Bobyard's <a href="https://bobyard-public-images.s3.us-west-2.amazonaws.com/Bobyard+-+Contractor+Terms+of+Use.pdf" target="_blank">Terms of Service</a>.
                    </div>*/}

                    <div className="login-footer-container">
                        Already have an account? <a href={`/join-company/${inviteLink}`}>Sign in</a>
                    </div>
                </>
                : <div className="login-container">
                    {error} No company found with this link!
                </div>
            }
        </div>
    );
}