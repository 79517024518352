import React, { useContext, useEffect, useMemo, useState } from "react";
import { Layer, Stage, Line as KonvaLine, Rect, Group, Circle as KonvaCircle, Line } from "react-konva";
import { Html, Portal } from "react-konva-utils";
import axios from "axios";

import { TakeoffContext } from "../../helper/Context";
import ContextMenu from "../../../components/ContextMenu";
import pSBC from "../../helper/Colors";
import { IconCopy, IconTrashX, IconZoomScan } from "@tabler/icons-react";

export default function Circle({ measurement }) {
    const {
        project, setProject,
        pageID,
        pages,
        groups, setGroups,
        measurements, setMeasurements,
        currentMeasurement, setCurrentMeasurement,
        takeoffSettings, setTakeoffSettings,
        handleChangeTakeoffSettings,
        drawing,
        handleCountTransform,
        DeleteMeasurement,
        handleZoomToMeasurement,
        selectedMeasurements, setSelectedMeasurements,
        setShowDetails,
        handleCircleTransform,
        optionDown, shiftDown,
        handleDuplicateMeasurement,
    } = useContext(TakeoffContext);

    const isSelected = currentMeasurement == measurement.id;

    const [dragging, setDragging] = useState(false);

    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

    const [colorPattern, setColorPattern] = useState([]);

    useEffect(() => {
        if (measurement?.gap > 0) {
            const colors = [];
            let x = Number(measurement.gap) / 4;

            for (let i = 0; i + 2 * x < 100; i += 2 * x) {
                colors.push(i / 100.0, pSBC(-0.05, measurement.color));
                colors.push((i + x) / 100.0, pSBC(-0.05, measurement.color));
                colors.push((i + x) / 100.0, 'white');
                colors.push((i + 2 * x) / 100.0, 'white');
            }

            if (colorPattern !== colors) {
                setColorPattern(colors);
            }
        }
    }, [measurement, measurement.gap, measurement.color]);

    const width = (pages[pageID].width);
    const height = (pages[pageID].height);

    const angleInDeg = measurement.size;
    const angle = ((180 - angleInDeg) / 180) * Math.PI
    const length = Math.abs(width * Math.sin(angle)) + Math.abs(height * Math.cos(angle))
    const halfx = (Math.sin(angle) * length)
    const halfy = (Math.cos(angle) * length)
    const cx = width / 2.0
    const cy = height / 2.0
    const x1 = cx - halfx
    const y1 = cy - halfy
    const x2 = cx + halfx
    const y2 = cy + halfy

    function approxPolygon(radius, x, y) {
        //approixmate circle with 100 points
        let points = [];
        for (let i = 0; i < 100; i++) {
            points.push([
                x + radius * Math.cos(2 * Math.PI * i / 100),
                y + radius * Math.sin(2 * Math.PI * i / 100)
            ]);
        }
        return points;
    }

    function intersectionY(edge, y) {
        const [[x1, y1], [x2, y2]] = edge;
        const dir = Math.sign(y2 - y1);
        if (dir && (y1 - y) * (y2 - y) <= 0) return { x: x1 + (y - y1) / (y2 - y1) * (x2 - x1), dir };
    }

    function intersectionX(edge, x) {
        const [[x1, y1], [x2, y2]] = edge;
        const dir = Math.sign(x2 - x1);
        if (dir && (x1 - x) * (x2 - x) <= 0) return { y: y1 + (x - x1) / (x2 - x1) * (y2 - y1), dir };
    }

    function tilePolygon(points, tileSize) {
        const minY = Math.min(...points.map(p => p[1])) - tileSize / 2;
        const maxY = Math.max(...points.map(p => p[1])) - tileSize / 2;
        const minX = Math.min(...points.map(p => p[0])) - tileSize / 2;
        const gridPoints = [];
        for (let y = minY; y <= maxY; y += tileSize) {
            // Collect x-coordinates where polygon crosses this horizontal line (y)
            const cuts = [];
            let prev = null;
            for (let i = 0; i < points.length; i++) {
                const cut = intersectionY([points[i], points[(i + 1) % points.length]], y);
                if (!cut) continue;
                if (!prev || prev.dir !== cut.dir) cuts.push(cut);
                prev = cut;
            }
            if (prev && prev.dir === cuts[0].dir) cuts.pop();
            // Now go through those cuts from left to right toggling whether we are in/out the polygon
            let dirSum = 0;
            let startX = null;
            for (let cut of cuts.sort((a, b) => a.x - b.x)) {
                dirSum += cut.dir;
                if (dirSum % 2) { // Entering polygon
                    if (startX === null) startX = cut.x;
                } else if (startX !== null) { // Exiting polygon
                    // Genereate grid points on this horizontal line segement
                    for (let x = minX + Math.ceil((startX - minX) / tileSize) * tileSize; x <= cut.x; x += tileSize) {
                        gridPoints.push([x, y]);
                    }
                    startX = null;
                }
            }
        }
        return gridPoints;
    }

    function tilePolygonTriangle(points, tileSize) {
        //just like above, but for triangles. we will tile the plane with equilateral triangles with side length tileSize
        //then we will find the points of intersection of the triangle inside the polygon

        //the are two types of rows. 
        //1. start with y = 0: x = 0, x = tileSize, x = 2 * tileSize, x = 3 * tileSize, ...
        //              then y increases by sqrt(3.0) * tileSize

        //2. start with y = sqrt(3.0) * tileSize / 2: x = tileSize / 2, x = 3 * tileSize / 2, x = 5 * tileSize / 2, ...
        //              then y increases by sqrt(3.0) * tileSize

        const minY = Math.min(...points.map(p => p[1])) - tileSize * Math.sqrt(3.0) / 2.0;
        const maxY = Math.max(...points.map(p => p[1])) - tileSize * Math.sqrt(3.0) / 2.0;
        const minX = Math.min(...points.map(p => p[0])) - tileSize / 2.0;

        const gridPoints = [];

        //run the code from tilePolygon for the first type of row
        for (let y = minY; y <= maxY; y += tileSize * Math.sqrt(3.0)) {
            // Collect x-coordinates where polygon crosses this horizontal line (y)
            const cuts = [];
            let prev = null;
            for (let i = 0; i < points.length; i++) {
                const cut = intersectionY([points[i], points[(i + 1) % points.length]], y);
                if (!cut) continue;
                if (!prev || prev.dir !== cut.dir) cuts.push(cut);
                prev = cut;
            }
            if (prev && prev.dir === cuts[0].dir) cuts.pop();
            // Now go through those cuts from left to right toggling whether we are in/out the polygon
            let dirSum = 0;
            let startX = null;
            for (let cut of cuts.sort((a, b) => a.x - b.x)) {
                dirSum += cut.dir;
                if (dirSum % 2) { // Entering polygon
                    if (startX === null) startX = cut.x;
                } else if (startX !== null) { // Exiting polygon
                    // Genereate grid points on this horizontal line segement
                    for (let x = minX + Math.ceil((startX - minX) / tileSize) * tileSize; x <= cut.x; x += tileSize) {
                        gridPoints.push([x, y]);
                    }
                    startX = null;
                }
            }
        }

        //run the code from tilePolygon for the second type of row
        for (let y = minY + tileSize * Math.sqrt(3.0) / 2.0; y <= maxY; y += tileSize * Math.sqrt(3.0)) {
            // Collect x-coordinates where polygon crosses this horizontal line (y)
            const cuts = [];
            let prev = null;
            for (let i = 0; i < points.length; i++) {
                const cut = intersectionY([points[i], points[(i + 1) % points.length]], y);
                if (!cut) continue;
                if (!prev || prev.dir !== cut.dir) cuts.push(cut);
                prev = cut;
            }
            if (prev && prev.dir === cuts[0].dir) cuts.pop();
            // Now go through those cuts from left to right toggling whether we are in/out the polygon
            let dirSum = 0;
            let startX = null;
            for (let cut of cuts.sort((a, b) => a.x - b.x)) {
                dirSum += cut.dir;
                if (dirSum % 2) { // Entering polygon
                    if (startX === null) startX = cut.x;
                } else if (startX !== null) { // Exiting polygon
                    // Genereate grid points on this horizontal line segement
                    for (let x = minX + Math.ceil((startX - minX) / tileSize) * tileSize + Math.ceil(tileSize / 2); x <= cut.x; x += tileSize) {
                        gridPoints.push([x, y]);
                    }
                    startX = null;
                }
            }
        }

        return gridPoints;
    }

    const gridPolygon = (points, tileSize) => {
        //polygon is a list of points
        //tileSize is the size of the grid
        //just like dots, but this time we just want to draw horizontal lines across the shape. 
        //return an array of lines that can be drawn (x1, y1, x2, y2)
        //if the ray leaves the polygon and enters again, we will draw two lines

        const minY = Math.min(...points.map(p => p[1]));
        const maxY = Math.max(...points.map(p => p[1]));

        const gridLines = [];

        for (let y = minY; y <= maxY; y += tileSize) {
            // Collect x-coordinates where polygon crosses this horizontal line (y)
            const cuts = [];
            let prev = null;
            for (let i = 0; i < points.length; i++) {
                const cut = intersectionY([points[i], points[(i + 1) % points.length]], y);
                if (!cut) continue;
                if (!prev || prev.dir !== cut.dir) cuts.push(cut);
                prev = cut;
            }
            if (prev && prev.dir === cuts[0].dir) cuts.pop();
            // Now go through those cuts from left to right toggling whether we are in/out the polygon
            let dirSum = 0;
            let startX = null;
            for (let cut of cuts.sort((a, b) => a.x - b.x)) {
                dirSum += cut.dir;
                if (dirSum % 2) { // Entering polygon
                    if (startX === null) startX = cut.x;
                } else if (startX !== null) { // Exiting polygon
                    gridLines.push([startX, y, cut.x, y]);
                    startX = null;
                }
            }
        }

        return gridLines;
    }

    const gridPolygonVertical = (points, tileSize) => {
        //polygon is a list of points
        //tileSize is the size of the grid
        //just like dots, but this time we just want to draw vertical lines across the shape.

        const minX = Math.min(...points.map(p => p[0]));
        const maxX = Math.max(...points.map(p => p[0]));

        const gridLines = [];

        for (let x = minX; x <= maxX; x += tileSize) {
            // Collect y-coordinates where polygon crosses this vertical line (x)
            const cuts = [];
            let prev = null;
            for (let i = 0; i < points.length; i++) {
                const cut = intersectionX([points[i], points[(i + 1) % points.length]], x);
                if (!cut) continue;
                if (!prev || prev.dir !== cut.dir) cuts.push(cut);
                prev = cut;
            }
            if (prev && prev.dir === cuts[0].dir) cuts.pop();
            // Now go through those cuts from left to right toggling whether we are in/out the polygon
            let dirSum = 0;
            let startY = null;
            for (let cut of cuts.sort((a, b) => a.y - b.y)) {
                dirSum += cut.dir;
                if (dirSum % 2) { // Entering polygon
                    if (startY === null) startY = cut.y;
                } else if (startY !== null) { // Exiting polygon
                    gridLines.push([x, startY, x, cut.y]);
                    startY = null;
                }
            }
        }

        return gridLines;
    }

    const intersection = (edge1, edge2) => {
        //edge1 and edge2 are two edges of a polygon
        //return the point of intersection of these two edges
        //if the edges are parallel, return null

        let x1 = edge1[0][0];
        let y1 = edge1[0][1];
        let x2 = edge1[1][0];
        let y2 = edge1[1][1];

        let x3 = edge2[0][0];
        let y3 = edge2[0][1];
        let x4 = edge2[1][0];
        let y4 = edge2[1][1];

        let den = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);

        if (den === 0) {
            return null;
        }

        let t = ((x1 - x3) * (y3 - y4) - (y1 - y3) * (x3 - x4)) / den;
        let u = -((x1 - x2) * (y1 - y3) - (y1 - y2) * (x1 - x3)) / den;

        if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
            return { x: x1 + t * (x2 - x1), y: y1 + t * (y2 - y1), dir: Math.sign((x2 - x1) * (y3 - y4) - (y2 - y1) * (x3 - x4)) };
        }

        return null;
    }

    const gridPolygonAngle = (points, tileSize, angle) => {
        //same as gridPolygon, but this time we will draw lines at an angle
        //we will shoot a ray at an angle through the polygon every tileSize distance

        //first we will find the line that is perpendicular to the angle and not passing through the polygon
        //this line will be the line that we will use to shoot rays from

        console.log(angle);

        const gridLines = [];

        //find the coordinates of this line
        let x1, y1, x2, y2;

        //find the slope of the line
        let m = - 1 / Math.tan(angle);

        //find the center of the polygon
        let cx = 0;
        let cy = 0;

        for (let i = 0; i < points.length; i++) {
            cx += points[i][0];
            cy += points[i][1];
        }

        cx /= points.length;
        cy /= points.length;

        //we will move this line to the left by the maximum distance between two points in the polygon
        let maxDistance = 0;

        for (let i = 0; i < points.length; i++) {
            for (let j = i + 1; j < points.length; j++) {
                let distance = Math.sqrt((points[i][0] - points[j][0]) * (points[i][0] - points[j][0]) + (points[i][1] - points[j][1]) * (points[i][1] - points[j][1]));
                if (distance > maxDistance) {
                    maxDistance = distance;
                }
            }
        }

        //move the center to be maxDistance away from the real center in a direction perpendicular to the angle

        cx -= maxDistance * Math.cos(angle);
        cy -= maxDistance * Math.sin(angle);

        //x1, y1 is maxDistance away cx, cy in the direction of the angle
        x1 = cx - maxDistance * Math.sin(angle);
        y1 = cy + maxDistance * Math.cos(angle);

        //x2, y2 is maxDistance away cx, cy in the opposite direction of the angle
        x2 = cx + maxDistance * Math.sin(angle);
        y2 = cy - maxDistance * Math.cos(angle);

        //now we will shoot rays from this line at an angle of angle
        //we will shoot rays every tileSize distance

        //the distance along the perpendicular line is tileSize
        // so distance between (start_x, start_y) and x1, y1 are multiples of tileSize

        for (let start_x = Math.min(x1, x2); start_x <= Math.max(x1, x2); start_x += tileSize * Math.abs(Math.sin(angle))) {
            //this ray starts from (x, y) and goes in the direction of the angle

            let start_y = x1 < x2
                ? y1 + m * (start_x - (Math.min(x1, x2)))
                : y1 + m * (start_x - (Math.max(x1, x2)));

            //the line is always maxDistance * 2 long 
            let end_x = start_x + maxDistance * 2 * Math.cos(angle);
            let end_y = start_y + (Math.tan(angle) * maxDistance * 2 * Math.cos(angle));

            // Collect x-coordinates where polygon crosses this line 
            const cuts = [];
            let prev = null;
            for (let i = 0; i < points.length; i++) {
                const cut = intersection([points[i], points[(i + 1) % points.length]], [[start_x, start_y], [end_x, end_y]]);
                if (!cut) continue;
                if (!prev || prev.dir !== cut.dir) cuts.push(cut);
                prev = cut;
            }
            if (prev && prev.dir === cuts[0].dir) cuts.pop();
            // Now go through those cuts from left to right toggling whether we are in/out the polygon
            let dirSum = 0;
            let cut_x1 = null;
            let cut_y1 = null;
            let cut_x2 = null;
            let cut_y2 = null;

            for (let cut of cuts.sort((a, b) => a.x - b.x)) {
                dirSum += cut.dir;
                if (dirSum % 2) { // Entering polygon
                    if (cut_x1 === null) {
                        cut_x1 = cut.x;
                        cut_y1 = cut.y;
                    }
                } else if (cut_x1 !== null) { // Exiting polygon
                    cut_x2 = cut.x;
                    cut_y2 = cut.y;
                    gridLines.push([cut_x1, cut_y1, cut_x2, cut_y2]);
                    cut_x1 = null;
                    cut_y1 = null;
                    cut_x2 = null;
                    cut_y2 = null;
                }
            }
        }

        return gridLines;
    }

    const OCLines = useMemo(() => {
        if ((measurement.uom !== 'ft' && measurement.uom !== 'in' && measurement.uom !== 'yrd' && measurement.uom !== 'm' && measurement.uom !== 'cm'
            && measurement.uom !== 'inx2' && measurement.uom !== 'ftx2' && measurement.uom !== 'cmx2' && measurement.uom !== 'mx2' && measurement.uom !== 'yrdx2')
            || !measurement.quantity1) return [];

        let points = approxPolygon(measurement.circle.radius, measurement.circle.x, measurement.circle.y);

        let oc_lines = [];

        if (measurement.uom === 'inx2' || measurement.uom === 'ftx2' || measurement.uom === 'cmx2' || measurement.uom === 'mx2' || measurement.uom === 'yrdx2') {
            if (!measurement.quantity2 || measurement.quantity2 % 90 === 0) {
                oc_lines = gridPolygon(points, (measurement.quantity1 / 12) / pages[pageID].scale).concat(gridPolygonVertical(points, (measurement.quantity1 / 12) / pages[pageID].scale));
            } else {
                oc_lines = gridPolygonAngle(points, (measurement.quantity1 / 12) / pages[pageID].scale, measurement.quantity2 * Math.PI / 180).concat(gridPolygonAngle(points, (measurement.quantity1 / 12) / pages[pageID].scale, measurement.quantity2 * Math.PI / 180 + Math.PI / 2));
            }
        } else {
            if (!measurement.quantity2 || measurement.quantity2 == 0 || measurement.quantity2 == 180) {
                oc_lines = gridPolygon(points, (measurement.quantity1 / 12) / pages[pageID].scale);
            } else if (measurement.quantity2 == 90 || measurement.quantity2 == 270) {
                oc_lines = gridPolygonVertical(points, (measurement.quantity1 / 12) / pages[pageID].scale);
            } else {
                oc_lines = gridPolygonAngle(points, (measurement.quantity1 / 12) / pages[pageID].scale, measurement.quantity2 * Math.PI / 180);
            }
        }

        console.log(oc_lines?.length);
        if (oc_lines?.length > 500) return [];
        return oc_lines;
    }, [measurement, measurement.uom, measurement.quantity1, measurement.quantity2, pages[pageID].scale]);

    const OCPoints = useMemo(() => {
        if (measurement.uom !== 'ea' || !measurement.quantity1) return [];

        let points = approxPolygon(measurement.circle.radius, measurement.circle.x, measurement.circle.y);

        console.log(points);

        let oc_points = tilePolygon(points, (measurement.quantity1 / 12) / pages[pageID].scale);

        if (measurement.quantity2) {
            oc_points = tilePolygonTriangle(points, (measurement.quantity1 / 12) / pages[pageID].scale);
        }

        console.log(oc_points);
        if (oc_points?.length > 10000) return [];
        return oc_points;
    }, [measurement, measurement.quantity1, measurement.quantity2, pages[pageID].scale, measurement.uom]);

    return (
        <>
            <Html>
                {showContextMenu && currentMeasurement === measurement.id &&
                    <ContextMenu
                        x={contextMenuPosition.x}
                        y={contextMenuPosition.y}
                        zoom={pages[pageID].zoom}
                        showContextMenu={showContextMenu}
                        setShowContextMenu={setShowContextMenu}
                    >
                        {/*<div
                            className="contextmenu-item"
                            onClick={() => {
                                document.getElementById(`measurement-${measurement.id}`).dispatchEvent(new MouseEvent('contextmenu', { bubbles: true }));
                            }}
                        >
                            Rename
                        </div>

                        <div
                            className="contextmenu-item"
                            onClick={() => {
                                document.getElementById(`measurement-${measurement.id}`).dispatchEvent(new MouseEvent('contextmenu', { bubbles: true }));
                            }}
                        >
                            Group
                        </div>

                        <div
                            className="contextmenu-item"
                            onClick={() => {
                                document.getElementById(`measurement-${measurement.id}`).dispatchEvent(new MouseEvent('contextmenu', { bubbles: true }));
                            }}
                        >
                            Set depth
                        </div>

                        <div
                            className="contextmenu-item"
                            onClick={() => {
                                document.getElementById(`measurement-${measurement.id}`).dispatchEvent(new MouseEvent('contextmenu', { bubbles: true }));
                            }}
                        >
                            Set pitch
                        </div>*/}

                        <div
                            className="contextmenu-item"
                            onClick={() => {
                                handleDuplicateMeasurement(measurement);
                            }}
                        >
                            <IconCopy size={16} stroke={1} />
                            <div>
                                Duplicate
                            </div>
                        </div>

                        <div
                            className="contextmenu-item"
                            onClick={() => handleZoomToMeasurement()}
                        >
                            <IconZoomScan size={16} stroke={1} />
                            <div>
                                Zoom
                            </div>
                        </div>

                        <div
                            className="contextmenu-item contextmenu-item-delete"
                            onClick={() => DeleteMeasurement(measurement)}
                        >
                            <IconTrashX size={16} stroke={1} />
                            <div>
                                Delete
                            </div>
                        </div>
                    </ContextMenu>
                }
            </Html>

            <Portal
                selector={'.top-layer'}
                enabled={isSelected}
            >
                {OCLines?.map((line, index) => (
                    <Line
                        key={index}
                        points={line}
                        stroke={(isSelected || selectedMeasurements.find(m => m === measurement.id)) ? pSBC(-0.8, measurement.color) : measurement?.color}
                        strokeWidth={2 / pages[pageID].zoom}
                        opacity={isSelected || selectedMeasurements.find(m => m === measurement.id) ? 0.7 : 0.5}
                        lineJoin="round"
                        perfectDrawEnabled={false}
                    />
                ))}

                {OCPoints?.map((point, index) => (
                    <KonvaCircle
                        key={index}
                        draggable={false}
                        x={point[0]}
                        y={point[1]}
                        radius={5}
                        fill={(isSelected || selectedMeasurements.find(m => m === measurement.id)) ? pSBC(-0.8, measurement.color) : measurement?.color}
                    />
                ))}

                <KonvaCircle
                    x={measurement.circle.x}
                    y={measurement.circle.y}
                    radius={measurement.circle.radius}
                    opacity={(isSelected || selectedMeasurements.find(m => m === measurement.id)) ? 0.7 : 0.5}
                    strokeWidth={(isSelected || selectedMeasurements.find(m => m === measurement.id)) ? 2 / pages[pageID].zoom : 1 / pages[pageID].zoom}
                    stroke={(isSelected || selectedMeasurements.find(m => m === measurement.id)) ? pSBC(-0.8, measurement.color) : measurement?.color}
                    fill={measurement?.gap ? undefined : measurement.color ? measurement.color : 'lightblue'}

                    fillLinearGradientStartPoint={{ x: x1, y: y1 }}
                    fillLinearGradientEndPoint={{ x: x2, y: y2 }}
                    fillLinearGradientColorStops={colorPattern}

                    shadowColor={pSBC(-0.25, measurement.color)}
                    //shadowBlur={isSelected ? 15 / pages[pageID].zoom : 0}
                    draggable={isSelected && !drawing}
                    onMouseLeave={(e) => {
                        e.target.getStage().container().style.cursor = "default";
                    }}
                    onMouseUp={(e) => {
                        setDragging(false);
                        e.target.getStage().container().style.cursor = "pointer";
                    }}
                    onMouseDown={(e) => {
                        if (e.evt.button !== 0) return;
                        setCurrentMeasurement(measurement.id);

                        if (isSelected) {
                            setDragging(true);
                            e.target.getStage().container().style.cursor = "grabbing";
                        } else {
                            e.target.getStage().container().style.cursor = "default";
                        }
                    }}
                    onMouseEnter={(e) => {
                        if (isSelected) {
                            e.target.getStage().container().style.cursor = "pointer";
                        } else {
                            e.target.getStage().container().style.cursor = "default";
                        }
                    }}
                    onClick={(e) => {
                        if (e.evt.button !== 0) return;
                        if (e.evt.metaKey) {
                            if (selectedMeasurements.find(m => m === measurement.id)) {
                                setSelectedMeasurements(selectedMeasurements.filter(m => m !== measurement.id));
                            } else {
                                setSelectedMeasurements([...selectedMeasurements, measurement.id]);
                            }
                        } else {
                            setSelectedMeasurements([measurement.id]);
                            setCurrentMeasurement(measurement.id);
                        }

                        handleChangeTakeoffSettings({
                            ...takeoffSettings,
                            show_measurement_sidebar: true
                        })

                        setShowDetails(true);

                        if (optionDown) {
                            setTimeout(() => {
                                const element = document.getElementById(`measurement-${measurement.id}-name`);
                                if (element) element.click();
                            }, 100);
                        }

                        setTimeout(() => {
                            const element = document.getElementById("measurement-" + measurement.id);
                            if (element && (element.getBoundingClientRect().top < 0 || element.getBoundingClientRect().top > window.innerHeight)) {
                                element.scrollIntoView();
                            }
                        }, 100);
                    }}
                    onContextMenu={(e) => {
                        e.evt.preventDefault();
                        setContextMenuPosition({
                            x: (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom,
                            y: (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom,
                        })
                        handleChangeTakeoffSettings({
                            ...takeoffSettings,
                            show_measurement_sidebar: true,
                            show_pages_sidebar: takeoffSettings.pages_sidebar_location === takeoffSettings.measurement_sidebar_location
                                ? false : takeoffSettings.show_pages_sidebar,
                            show_ai_sidebar: takeoffSettings.ai_sidebar_location === takeoffSettings.measurement_sidebar_location
                                ? false : takeoffSettings.show_ai_sidebar,
                        })
                        setShowContextMenu(true);
                        setCurrentMeasurement(measurement.id);
                        setSelectedMeasurements([measurement.id])
                    }}
                    onDragEnd={(e) => {
                        setDragging(false);
                        handleCircleTransform({
                            ...measurement,
                            circle: {
                                ...measurement.circle,
                                x: e.target.x(),
                                y: e.target.y(),
                            }
                        });
                    }}
                    perfectDrawEnabled={false}
                />

                {!dragging && isSelected &&
                    <CircleRezier measurement={measurement} />
                }
            </Portal>
        </>
    );
}

const CircleRezier = ({ measurement }) => {
    const {
        pageID,
        pages,
        measurements, setMeasurements,
        handleCircleTransform,
    } = useContext(TakeoffContext);

    const [position, setPosition] = useState({ x: measurement.circle.x + measurement.circle.radius, y: measurement.circle.y });

    const [prev, setPrev] = useState(null);

    useEffect(() => {
        setPosition({ x: measurement.circle.x + measurement.circle.radius, y: measurement.circle.y });
    }, [measurement?.circle?.x, measurement?.circle?.y]);

    return (
        <KonvaCircle
            x={position.x}
            y={position.y}
            radius={5 / pages[pageID].zoom}
            stroke={pSBC(-0.8, measurement.color)}
            strokeWidth={1 / pages[pageID].zoom}
            fill={'white'}
            opacity={0.5}
            draggable
            onMouseEnter={(e) => {
                e.target.getStage().container().style.cursor = "move";
            }}
            onMouseLeave={(e) => {
                e.target.getStage().container().style.cursor = "default";
            }}
            onDragStart={(e) => {
                setPrev(measurement);
            }}
            onDragMove={(e) => {
                setMeasurements(prev => ({
                    ...prev,
                    [measurement.id]: {
                        ...prev[measurement.id],
                        circle: {
                            ...prev[measurement.id].circle,
                            radius: Math.sqrt(Math.pow(e.target.x() - measurement.circle.x, 2) + Math.pow(e.target.y() - measurement.circle.y, 2)),
                        }
                    }
                }))
                setPosition({ x: e.target.x(), y: e.target.y() });
            }}
            onDragEnd={(e) => {
                handleCircleTransform({
                    ...measurement,
                    area: 3.14159 * Math.pow(measurement.circle.radius, 2),
                    circle: {
                        ...measurement.circle,
                        radius: Math.sqrt(Math.pow(e.target.x() - measurement.circle.x, 2) + Math.pow(e.target.y() - measurement.circle.y, 2)),
                    }
                }, false, prev);
            }}
            perfectDrawEnabled={false}
        />
    );
}