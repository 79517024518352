import { useContext, useEffect, useState } from 'react';
import { TakeoffContext } from '../../helper/Context';

import LineDetails from './components/LineDetails';

import './styles/DetailsNavbar.css';
import PolygonDetails from './components/PolygonDetails';
import RectangleDetails from './components/RectangleDetails';
import CountDetails from './components/CountDetails';
import CircleDetails from './components/CircleDetails';
import MultiDetails from './components/MultiDetails';
import AnnotationTextDetails from './annotations/AnnotationTextDetails';
import AnnotationArrowDetails from './annotations/AnnotationArrowDetails';
import AnnotationRectDetails from './annotations/AnnotationRectDetails';
import AnnotationRulerDetails from './annotations/AnnotationRulerDetails';

export default function DetailsNavbar() {
    const {
        project, setProject,
        pages, setPages,
        drawing,
        handleUpdateProject,
        measurements, setMeasurements,
        currentMeasurement, setCurrentMeasurement,
        takeoffSettings,
        selectedMeasurements, setSelectedMeasurements,
        currentAnnotation,
        annotations,
        pageID,
    } = useContext(TakeoffContext);

    const left = takeoffSettings?.show_pages_sidebar && takeoffSettings?.pages_sidebar_location === 'left'
        ? takeoffSettings?.pages_sidebar_width || window.innerWidth * 0.2
        : takeoffSettings?.show_ai_sidebar && takeoffSettings?.ai_sidebar_location === 'left'
            ? takeoffSettings?.ai_sidebar_width || window.innerWidth * 0.2
            : takeoffSettings?.show_measurement_sidebar && takeoffSettings?.measurement_sidebar_location === 'left'
                ? takeoffSettings?.measurement_sidebar_width || window.innerWidth * 0.2
                : 0

    const right = takeoffSettings?.show_pages_sidebar && takeoffSettings?.pages_sidebar_location === 'right'
        ? takeoffSettings?.pages_sidebar_width || window.innerWidth * 0.2
        : takeoffSettings?.show_ai_sidebar && takeoffSettings?.ai_sidebar_location === 'right'
            ? takeoffSettings?.ai_sidebar_width || window.innerWidth * 0.2
            : takeoffSettings?.show_measurement_sidebar && takeoffSettings?.measurement_sidebar_location === 'right'
                ? takeoffSettings?.measurement_sidebar_width || window.innerWidth * 0.2
                : 0

    const width = window.innerWidth - left - right;

    if (new Set(selectedMeasurements).size > 1) {
        return (
            <div
                className='detailsnavbar-container'
                style={{
                    /*left: left + 'px',
                    width: width + 'px',
                    borderLeft: left && '1px solid gainsboro',
                    borderRight: right && '1px solid gainsboro',*/
                    backgroundColor: 'aliceblue',
                }}
            >
                <MultiDetails
                    left={left}
                    width={width}
                />
            </div>
        )
    }

    if (!currentMeasurement && !currentAnnotation) return null;

    return (
        <div
            className='detailsnavbar-container'
            style={{
                /*left: left + 'px',
                width: width + 'px',
                borderLeft: left && '1px solid gainsboro',
                borderRight: right && '1px solid gainsboro',*/
            }}
        >
            {measurements[currentMeasurement]?.type === 'line' &&
                <LineDetails
                    left={left}
                    width={width}
                />
            }

            {measurements[currentMeasurement]?.type === 'rectangle' &&
                <RectangleDetails
                    left={left}
                    width={width}
                />
            }

            {measurements[currentMeasurement]?.type === 'polygon' &&
                <PolygonDetails
                    left={left}
                    width={width}
                />
            }

            {measurements[currentMeasurement]?.type === 'count' &&
                <CountDetails
                    left={left}
                    width={width}
                />
            }

            {measurements[currentMeasurement]?.type === 'circle' &&
                <CircleDetails
                    left={left}
                    width={width}
                />
            }

            {!currentMeasurement && currentAnnotation &&
                <>
                    {annotations[currentAnnotation]?.type === 'text' &&
                        <AnnotationTextDetails
                            left={left}
                            width={width}
                        />
                    }

                    {annotations[currentAnnotation]?.type === 'arrow' &&
                        <AnnotationArrowDetails
                            left={left}
                            width={width}
                        />
                    }

                    {annotations[currentAnnotation]?.type === 'rectangle' &&
                        <AnnotationRectDetails
                            left={left}
                            width={width}
                        />
                    }

                    {annotations[currentAnnotation]?.type === 'ruler' &&
                        <AnnotationRulerDetails
                            left={left}
                            width={width}
                        />
                    }
                </>
            }
        </div>
    )
}