import { rectangle, Circle, Group, Rect, Text, Transformer } from "react-konva";
import { useContext, useEffect, useRef, useState } from "react";

import { TakeoffContext } from "../../helper/Context";
import { Html, Portal } from "react-konva-utils";
import pSBC from "../../helper/Colors";
import ContextMenu from "../../../components/ContextMenu";
import { IconCopy, IconTrashX, IconZoomScan } from "@tabler/icons-react";

export default function AnnotationRect({ annotation }) {
    const {
        annotations, setAnnotations,
        handleCreateAnnotation,
        handleUpdateAnnotation,
        pages, pageID,
        setEditingMeasurement,
        drawing,
        currentMeasurement, setCurrentMeasurement,
        selectedAnnotations, setSelectedAnnotations,
        currentAnnotation, setCurrentAnnotation,
        selectedMeasurements, setSelectedMeasurements,
        setShowDeleteAnnotationModal,
        handleZoomToAnnotation,
    } = useContext(TakeoffContext);

    const shapeRef = useRef();
    const transformerRef = useRef();

    const isSelected = (currentAnnotation === annotation.id && !drawing && !currentMeasurement);

    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        if (isSelected) {
            transformerRef.current.nodes([shapeRef.current]);
            transformerRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                setCurrentAnnotation(null);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    const handleTransformEnd = (e) => {
        const node = shapeRef.current;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();

        node.scaleX(1);
        node.scaleY(1);

        handleUpdateAnnotation({
            ...annotation,
            rectangle: {
                ...annotation.rectangle,
                x: node.x(),
                y: node.y(),
                width: Math.max(node.width() * scaleX),
                height: Math.max(node.height() * scaleY),
            },
        });
    };

    return (
        <>
            <Html>
                {showContextMenu && currentAnnotation === annotation.id &&
                    <ContextMenu
                        x={contextMenuPosition.x}
                        y={contextMenuPosition.y}
                        zoom={pages[pageID].zoom}
                        showContextMenu={showContextMenu}
                        setShowContextMenu={setShowContextMenu}
                    >
                        <div
                            className="contextmenu-item"
                            onClick={() => handleCreateAnnotation({
                                type: "rectangle",
                                data: {
                                    x: annotation.rectangle.x + 10,
                                    y: annotation.rectangle.y + 10,
                                    width: annotation.rectangle.width,
                                    height: annotation.rectangle.height,
                                    stroke_color: annotation.stroke_color,
                                    fill_color: annotation.fill_color,
                                    size: annotation.size,
                                }
                            })}
                        >
                            <IconCopy size={16} stroke={1} />
                            <div>
                                Duplicate
                            </div>
                        </div>

                        <div
                            className="contextmenu-item"
                            onClick={() => handleZoomToAnnotation(annotation.id)}
                        >
                            <IconZoomScan size={16} stroke={1} />
                            <div>
                                Zoom
                            </div>
                        </div>

                        <div
                            className="contextmenu-item contextmenu-item-delete"
                            onClick={() => setShowDeleteAnnotationModal(true)}
                        >
                            <IconTrashX size={16} stroke={1} />
                            <div>
                                Delete
                            </div>
                        </div>
                    </ContextMenu>
                }
            </Html>

            <Portal
                selector={'.top-layer'}
                enabled={isSelected}
            >
                <Group
                    draggable={isSelected && !drawing}
                >
                    <Rect
                        ref={shapeRef}
                        onClick={(e) => {
                            if (e.evt.button !== 0) return;

                            if (e.evt.metaKey) {
                                if (selectedAnnotations.includes(annotation.id)) {
                                    setSelectedAnnotations(selectedAnnotations.filter((id) => id !== annotation.id));
                                } else {
                                    setSelectedAnnotations([...selectedAnnotations, annotation.id]);
                                }
                            } else {
                                setSelectedAnnotations([annotation.id]);
                                setCurrentAnnotation(annotation.id)
                            }

                            setCurrentMeasurement(null);
                            setSelectedMeasurements([]);
                        }}
                        onContextMenu={(e) => {
                            e.evt.preventDefault();
                            setSelectedAnnotations([annotation.id]);
                            setCurrentAnnotation(annotation.id);
                            setCurrentMeasurement(null);

                            setContextMenuPosition({
                                x: (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom,
                                y: (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom,
                            })
                            setShowContextMenu(true);
                        }}

                        x={annotation.rectangle.x}
                        y={annotation.rectangle.y}
                        width={annotation.rectangle.width}
                        height={annotation.rectangle.height}
                        fill={annotation.fill_color}
                        stroke={annotation.stroke_color}
                        strokeWidth={annotation.size}
                        opacity={isSelected || selectedAnnotations.includes(annotation.id) ? 0.7 : 0.5}

                        draggable={isSelected && !drawing}
                        onDragStart={(e) => {
                            transformerRef.current.nodes([shapeRef.current]);
                        }}
                        onDragMove={(e) => {
                            const node = shapeRef.current;
                            const scaleX = node.scaleX();
                            const scaleY = node.scaleY();

                            node.scaleX(1);
                            node.scaleY(1);

                            setAnnotations({
                                ...annotations,
                                [annotation.id]: {
                                    ...annotation,
                                    rectangle: {
                                        ...annotation.rectangle,
                                        x: e.target.x(),
                                        y: e.target.y(),
                                        width: Math.max(node.width() * scaleX),
                                        height: Math.max(node.height() * scaleY),
                                    }
                                }
                            })
                        }}
                        onDragEnd={(e) => {
                            handleUpdateAnnotation({
                                ...annotation,
                                rectangle: {
                                    ...annotation.rectangle,
                                    x: e.target.x(),
                                    y: e.target.y(),
                                    width: e.target.width(),
                                    height: e.target.height(),
                                }
                            })
                        }}
                        onTransformEnd={handleTransformEnd}
                        strokeScaleEnabled={false}
                        perfectDrawEnabled={false}
                    />

                    {isSelected &&
                        <Transformer
                            ref={transformerRef}
                            enabledAnchors={[
                                'top-left', 'top-center', 'top-right',
                                'middle-left', 'middle-right',
                                'bottom-left', 'bottom-center', 'bottom-right',
                            ]}
                            flipEnabled={false}
                            ignoreStroke={true}
                            borderStrokeWidth={1}
                            rotateEnabled={false}
                            anchorFill='white'
                            anchorSize={10}
                            anchorStrokeWidth={1}
                            opacity={0.7}
                            keepRatio={false}
                        />
                    }
                </Group>
            </Portal>
        </>
    )
}