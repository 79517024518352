import React, { useContext, useEffect, useState } from "react";
import { Circle, Rect, Layer, Line, Stage, Image, Arc, Shape } from "react-konva";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";

import { API_ROUTE } from "../../../index";
import { selectAuth } from "../../../redux/slices/authSlice";
import { TakeoffContext } from "../../helper/Context";
import pSBC from "../../helper/Colors";

export default function PolygonConstructor({ }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages, setPages,
        groups, setGroups,
        setDrawingPolygon,
        currentGroup,
        selectedGroups,
        createMeasurement,
        createMeasurements,
        history, setHistory,
        currentHistory, setCurrentHistory,
        settings,
        setMeasurements,
        handleChangeFilter,
    } = useContext(TakeoffContext);

    const [points, setPoints] = useState([]);
    const [nextPoint, setNextPoint] = useState(null);
    const [pointsHistory, setPointsHistory] = useState([]);

    const [isComplete, setIsComplete] = useState(false);

    const [showRectangle, setShowRectangle] = useState(false);
    const [rectangleOrientation, setRectangleOrientation] = useState();

    const [showArc, setShowArc] = useState(false);
    const [arcOrientation, setArcOrientation] = useState();
    const [arcAngle, setArcAngle] = useState();

    const [showLine, setShowLine] = useState(false);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Enter" && !isComplete) {
                if (points.length <= 2) return;
                e.stopPropagation();
                e.preventDefault();

                addPolygon(points);
                setNextPoint(null);
                setPoints([]);
                setIsComplete(false);
                setShowArc(false);
                setShowRectangle(false);
                setShowLine(false);
            }

            if (e.key === "Delete" || e.key === "Backspace") {
                if (points.length > 0) {
                    e.stopPropagation();
                    let lastPoint = points[points.length - 1];
                    setPoints(prev => prev.slice(0, -1));
                    setPointsHistory(prev => [...prev, lastPoint]);
                }
            } else if (e.key === "Tab") {
                if (pointsHistory.length > 0) {
                    e.stopPropagation();
                    let lastPoint = pointsHistory[pointsHistory.length - 1];
                    setPoints(prev => [...prev, lastPoint]);
                    setPointsHistory(prev => prev.slice(0, -1));
                }
            } else if (e.key === "a" || e.key === "A") {
                e.stopPropagation();
                console.log(e);
                setPoints(prev => {
                    let newPoints = [...prev];
                    if (newPoints.length === 0) return [];
                    newPoints[newPoints.length - 1] = { ...newPoints[newPoints.length - 1], arc: !newPoints[newPoints.length - 1].arc };
                    return newPoints;
                })
            }
        }

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        }
    }, [points, isComplete, pointsHistory]);

    const addPolygon = (poly) => {
        handleChangeFilter('include_area', true);

        let tempName = 'temp' + uuidv4();

        setMeasurements(prev => ({
            ...prev,
            [tempName]: {
                'type': 'polygon',
                'page': pageID,
                'color': groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3',
                ['polygon_dots']: [...poly]
            }
        }))

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/polygon/`,
            data: {
                'userID': auth.user.id,
                'pageID': pageID,
                'group': currentGroup || null,
                'groups': [...(currentGroup ? new Set([currentGroup, ...selectedGroups]) : new Set(selectedGroups))],
                'dots': poly,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);

                //createMeasurement(response.data, false, tempName);
                createMeasurements(response.data, tempName);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <>
            <Line
                strokeWidth={2 / pages[pageID].zoom}
                stroke={pSBC(-0.8, groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3')}
                opacity={0.5}
                lineJoin="round"
                closed={isComplete}
                points={points.flatMap((point) => [point.x, point.y]).concat([nextPoint?.x, nextPoint?.y])}
            />

            {/*<Line
                strokeWidth={2 / pages[pageID].zoom}
                stroke={groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3'}
                opacity={0.5}
                lineJoin="round"
                closed={true}
                points={points.flatMap((point) => [point.x, point.y])}
                fill={groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3'}
            />*/}

            {showLine && points.length > 1 &&
                <Line
                    strokeWidth={1 / pages[pageID].zoom}
                    dash={[5, 5]}
                    points={[points[points.length - 1].x - 100, points[points.length - 1].y, points[points.length - 1].x + 100, points[points.length - 1].y]}
                    stroke={pSBC(-0.8, groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3')}
                />
            }

            {showRectangle && points.length > 1 &&
                <Rect
                    x={points[points.length - 1].x}
                    y={points[points.length - 1].y}
                    width={10 / pages[pageID].zoom}
                    height={10 / pages[pageID].zoom}
                    fill={groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3'}
                    stroke={groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3'}
                    strokeWidth={1 / pages[pageID].zoom}
                    opacity={0.5}
                    rotation={rectangleOrientation}
                />
            }

            {showArc && points.length > 1 &&
                <Arc
                    x={points[points.length - 1].x}
                    y={points[points.length - 1].y}
                    innerRadius={0}
                    outerRadius={10 / pages[pageID].zoom}
                    fill={groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3'}
                    stroke={groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3'}
                    strokeWidth={1 / pages[pageID].zoom}
                    opacity={0.25}
                    angle={arcAngle}
                    rotation={arcOrientation}
                />
            }

            {points.length > 0 &&
                <Shape
                    strokeWidth={(2 / pages[pageID].zoom)}
                    stroke={groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3'}
                    opacity={0.5}
                    fill={groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3'}

                    closed={true}

                    sceneFunc={(ctx, shape) => {
                        ctx.beginPath();

                        if (points[0].arc) {
                            if (points[points.length - 1].arc) {
                                ctx.moveTo((points[points.length - 1].x + points[0].x) / 2, (points[points.length - 1].y + points[0].y) / 2);
                            } else {
                                ctx.moveTo(points[points.length - 1].x, points[points.length - 1].y);
                            }
                        } else {
                            ctx.moveTo(points[0].x, points[0].y);
                        }

                        for (var i = 0; i < points.length; i++) {
                            if (points[i].arc) {
                                let right_x = points[(i + 1) % points.length].x;
                                let right_y = points[(i + 1) % points.length].y;

                                if (points[(i + 1) % points.length].arc) {
                                    right_x = (points[i].x + points[(i + 1) % points.length].x) / 2;
                                    right_y = (points[i].y + points[(i + 1) % points.length].y) / 2;
                                }

                                ctx.quadraticCurveTo(points[i].x, points[i].y, right_x, right_y);
                            } else {
                                if (!points[(i + 1) % points.length].arc) {
                                    let right_x = points[(i + 1) % points.length].x;
                                    let right_y = points[(i + 1) % points.length].y;
                                    ctx.lineTo(right_x, right_y);
                                }
                            }
                        }
                        ctx.fillStrokeShape(shape);
                    }}
                />
            }

            <Rect
                x={0}
                y={0}
                width={pages[pageID].width}
                height={pages[pageID].height}
                //onMouseOver={(e) => e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+polygon.svg') 8 24, auto"}
                onClick={(e) => {
                    if (e.evt.button !== 0) return;

                    if (!isComplete) {
                        if (nextPoint?.x && nextPoint?.y) {
                            setPoints(prev => prev.concat(nextPoint));
                        } else {
                            setPoints(prev => prev.concat({
                                x: (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom,
                                y: (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom,
                                arc: false,
                            }));
                        }
                        setPointsHistory([]);
                    }
                }}
                /*onDblClick={(e) => {
                    if (points.length > 3) {
                        addPolygon(points.slice(0, -1));
                        setNextPoint(null);
                        setPoints([]);
                        setIsComplete(false);
                        setShowArc(false);
                        setShowRectangle(false);
                        setShowLine(false);
                    };
                }}*/
                onMouseMove={(e) => {
                    setShowRectangle(false);
                    setShowArc(false);
                    setShowLine(false);

                    const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                    const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;

                    if (points.length < 1 || !settings.angle_snap) {
                        setNextPoint({ x: x, y: y, arc: false });
                        return;
                    }

                    const angleSnap = settings.angle_snap_distance;

                    const angle = Math.atan2(y - points[points.length - 1].y, x - points[points.length - 1].x) * 180 / Math.PI;
                    const x2 = x - points[points.length - 1].x;
                    const y2 = y - points[points.length - 1].y;
                    const hypotenuse = Math.sqrt(x2 * x2 + y2 * y2);
                    const angle3 = Math.round((Math.atan2(y2, x2) * 180 / Math.PI) / 45) * 45;

                    if (settings?.angle_snap_90 && Math.abs(angle) < angleSnap) {
                        setShowRectangle(true);
                        setRectangleOrientation(0);
                        setNextPoint({ x, y: points[points.length - 1].y, arc: false });
                        setShowLine(true);
                    } else if (settings?.angle_snap_90 && Math.abs(angle - 90) < angleSnap || Math.abs(angle + 270) < angleSnap) {
                        setShowRectangle(true);
                        setRectangleOrientation(90);
                        setNextPoint({ x: points[points.length - 1].x, y, arc: false });
                        setShowLine(true);
                    } else if (settings?.angle_snap_90 && Math.abs(angle - 180) < angleSnap || Math.abs(angle + 180) < angleSnap) {
                        setShowRectangle(true);
                        setRectangleOrientation(180);
                        setNextPoint({ x, y: points[points.length - 1].y, arc: false });
                        setShowLine(true);
                    } else if (settings?.angle_snap_90 && Math.abs(angle - 270) < angleSnap || Math.abs(angle + 90) < angleSnap) {
                        setShowRectangle(true);
                        setRectangleOrientation(270);
                        setNextPoint({ x: points[points.length - 1].x, y, arc: false });
                        setShowLine(true);
                    } else if (settings?.angle_snap_45 && Math.abs(angle - 45) < angleSnap || Math.abs(angle + 315) < angleSnap) {
                        setShowArc(true);
                        setArcOrientation(0);
                        setArcAngle(45);
                        setShowLine(true);
                        setNextPoint({ x: points[points.length - 1].x + hypotenuse * Math.cos(angle3 * Math.PI / 180), y: points[points.length - 1].y + hypotenuse * Math.sin(angle3 * Math.PI / 180), arc: false });
                    } else if (settings?.angle_snap_45 && Math.abs(angle - 135) < angleSnap || Math.abs(angle + 225) < angleSnap) {
                        setShowArc(true);
                        setArcOrientation(180 - 45);
                        setArcAngle(45);
                        setShowLine(true);
                        setNextPoint({ x: points[points.length - 1].x + hypotenuse * Math.cos(angle3 * Math.PI / 180), y: points[points.length - 1].y + hypotenuse * Math.sin(angle3 * Math.PI / 180), arc: false });
                    } else if (settings?.angle_snap_45 && Math.abs(angle - 225) < angleSnap || Math.abs(angle + 135) < angleSnap) {
                        setShowArc(true);
                        setArcOrientation(180);
                        setArcAngle(45);
                        setShowLine(true);
                        setNextPoint({ x: points[points.length - 1].x + hypotenuse * Math.cos(angle3 * Math.PI / 180), y: points[points.length - 1].y + hypotenuse * Math.sin(angle3 * Math.PI / 180), arc: false });
                    } else if (settings?.angle_snap_45 && Math.abs(angle - 315) < angleSnap || Math.abs(angle + 45) < angleSnap) {
                        setShowArc(true);
                        setArcOrientation(-45);
                        setArcAngle(45);
                        setShowLine(true);
                        setNextPoint({ x: points[points.length - 1].x + hypotenuse * Math.cos(angle3 * Math.PI / 180), y: points[points.length - 1].y + hypotenuse * Math.sin(angle3 * Math.PI / 180), arc: false });
                    } else if (points.length > 1) {

                        //constant variables needed for calculations
                        const prev_x1 = points[points.length - 1].x - points[points.length - 2].x;
                        const prev_y1 = points[points.length - 1].y - points[points.length - 2].y;
                        const prev_x2 = x - points[points.length - 1].x;
                        const prev_y2 = y - points[points.length - 1].y;
                        const prev_hypotenuse1 = Math.sqrt(prev_x1 * prev_x1 + prev_y1 * prev_y1);
                        const prev_hypotenuse2 = Math.sqrt(prev_x2 * prev_x2 + prev_y2 * prev_y2);
                        const prev_angle1 = Math.atan2(prev_y1, prev_x1) * 180 / Math.PI;
                        const prev_angle2 = Math.atan2(prev_y2, prev_x2) * 180 / Math.PI;
                        const prev_angle3 = Math.round((Math.atan2(prev_y2, prev_x2) * 180 / Math.PI) / 45) * 45;

                        //snap to 90, 180, 270, 360, 45, 135, 225, 315 of the angle made between (next point, previous point) and (previous point, previous previous point)
                        if (settings?.angle_snap_90_prev && Math.abs(prev_angle1 - prev_angle2) < angleSnap || Math.abs(prev_angle1 - prev_angle2 - 360) < angleSnap || Math.abs(prev_angle1 - prev_angle2 + 360) < angleSnap) {
                            setNextPoint({ x: points[points.length - 1].x + prev_hypotenuse2 * Math.cos(prev_angle1 * Math.PI / 180), y: points[points.length - 1].y + prev_hypotenuse2 * Math.sin(prev_angle1 * Math.PI / 180), arc: false });
                        } else if (settings?.angle_snap_90_prev && Math.abs(prev_angle1 - prev_angle2 - 90) < angleSnap || Math.abs(prev_angle1 - prev_angle2 + 270) < angleSnap) {
                            let angle = prev_angle1 + 270;
                            if (angle > 360) angle -= 360;
                            if (angle < 0) angle += 360;

                            setNextPoint({ x: points[points.length - 1].x + prev_hypotenuse2 * Math.cos(angle * Math.PI / 180), y: points[points.length - 1].y + prev_hypotenuse2 * Math.sin(angle * Math.PI / 180), arc: false });

                            setShowRectangle(true);
                            setRectangleOrientation(angle - 90);
                        } else if (settings?.angle_snap_90_prev && Math.abs(prev_angle1 - prev_angle2 - 180) < angleSnap || Math.abs(prev_angle1 - prev_angle2 + 180) < angleSnap) {
                            setNextPoint({ x, y, arc: false });
                        } else if (settings?.angle_snap_90_prev && Math.abs(prev_angle1 - prev_angle2 - 270) < angleSnap || Math.abs(prev_angle1 - prev_angle2 + 90) < angleSnap) {
                            let angle = prev_angle1 + 90;
                            if (angle > 360) angle -= 360;
                            if (angle < 0) angle += 360;

                            setNextPoint({ x: points[points.length - 1].x + prev_hypotenuse2 * Math.cos(angle * Math.PI / 180), y: points[points.length - 1].y + prev_hypotenuse2 * Math.sin(angle * Math.PI / 180), arc: false });

                            setShowRectangle(true);
                            setRectangleOrientation(angle);
                        } else if (settings?.angle_snap_45_prev && Math.abs(prev_angle1 - prev_angle2 - 45) < angleSnap || Math.abs(prev_angle1 - prev_angle2 + 315) < angleSnap) {
                            let angle = prev_angle1 + 315;
                            if (angle > 360) angle -= 360;
                            if (angle < 0) angle += 360;

                            setNextPoint({ x: points[points.length - 1].x + prev_hypotenuse2 * Math.cos(angle * Math.PI / 180), y: points[points.length - 1].y + prev_hypotenuse2 * Math.sin(angle * Math.PI / 180), arc: false });

                            setShowArc(true);
                            setArcOrientation(angle - 135);
                            setArcAngle(180 - 45);
                        } else if (settings?.angle_snap_45_prev && Math.abs(prev_angle1 - prev_angle2 - 135) < angleSnap || Math.abs(prev_angle1 - prev_angle2 + 225) < angleSnap) {
                            let angle = prev_angle1 + 225;
                            if (angle > 360) angle -= 360;
                            if (angle < 0) angle += 360;

                            setNextPoint({ x: points[points.length - 1].x + prev_hypotenuse2 * Math.cos(angle * Math.PI / 180), y: points[points.length - 1].y + prev_hypotenuse2 * Math.sin(angle * Math.PI / 180), arc: false });

                            setShowArc(true);
                            setArcOrientation(angle - 45);
                            setArcAngle(45);
                        } else if (settings?.angle_snap_45_prev && Math.abs(prev_angle1 - prev_angle2 - 225) < angleSnap || Math.abs(prev_angle1 - prev_angle2 + 135) < angleSnap) {
                            let angle = prev_angle1 + 135;
                            if (angle > 360) angle -= 360;
                            if (angle < 0) angle += 360;

                            setNextPoint({ x: points[points.length - 1].x + prev_hypotenuse2 * Math.cos(angle * Math.PI / 180), y: points[points.length - 1].y + prev_hypotenuse2 * Math.sin(angle * Math.PI / 180), arc: false });

                            setShowArc(true);
                            setArcOrientation(angle);
                            setArcAngle(45);
                        } else if (settings?.angle_snap_45_prev && Math.abs(prev_angle1 - prev_angle2 - 315) < angleSnap || Math.abs(prev_angle1 - prev_angle2 + 45) < angleSnap) {
                            let angle = prev_angle1 + 45;
                            if (angle > 360) angle -= 360;
                            if (angle < 0) angle += 360;

                            setNextPoint({ x: points[points.length - 1].x + prev_hypotenuse2 * Math.cos(angle * Math.PI / 180), y: points[points.length - 1].y + prev_hypotenuse2 * Math.sin(angle * Math.PI / 180), arc: false });

                            setShowArc(true);
                            setArcOrientation(angle);
                            setArcAngle(180 - 45);
                        } else {
                            setNextPoint({ x, y, arc: false });
                        }
                    } else {
                        setNextPoint({ x, y, arc: false });
                    }
                }}
            />

            {points[0] && !isComplete && (
                <PolygonOriginAnchor
                    point={{ x: points[0].x, y: points[0].y }}
                    onValidClick={() => {
                        setShowArc(false);
                        setShowRectangle(false);
                        setShowLine(false);
                        addPolygon(points);
                        setNextPoint(null);
                        setPoints([]);
                        setIsComplete(false);
                    }}
                    onValidMouseOver={() => {
                        setNextPoint(points[0]);
                    }}
                    validateMouseEvents={() => {
                        return points.length > 2;
                    }}
                />
            )}

            {points.slice(1).map((point, index) => (
                <PolygonVertex
                    key={index}
                    points={points}
                    point={point}
                    setPoints={setPoints}
                    index={index}
                    pageID={pageID}
                    pages={pages}
                    setPages={setPages}
                    groups={groups}
                    currentGroup={currentGroup}
                    nextPoint={nextPoint}
                    setNextPoint={setNextPoint}
                />
            ))}
        </>
    );
}

function PolygonVertex({
    points,
    point,
    setPoints,
    index,
    pageID,
    pages,
    setPages,
    groups,
    currentGroup,
    nextPoint,
    setNextPoint,
}) {
    const [mouseHover, setMouseHover] = useState(false);

    return (
        <Rect
            key={index}
            x={point.x - 5 / pages[pageID].zoom}
            y={point.y - 5 / pages[pageID].zoom}
            width={10 / pages[pageID].zoom}
            height={10 / pages[pageID].zoom}
            fill={mouseHover ? '#9DD9F3' : 'white'}
            stroke={groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3'}
            strokeWidth={1 / pages[pageID].zoom}
            opacity={0.5}
            draggable={true}
            onDragMove={(e) => {
                const newPoints = [...points];
                newPoints[index + 1] = {
                    ...newPoints[index + 1],
                    x: e.target.x() + 5 / pages[pageID].zoom,
                    y: e.target.y() + 5 / pages[pageID].zoom
                };
                setPoints(newPoints);
            }}
            onMouseEnter={(e) => {
                e.target.getStage().container().style.cursor = "pointer";
                setMouseHover(true)
            }}
            onMouseLeave={() => setMouseHover(false)}
        />
    )
}

function PolygonOriginAnchor({
    point,
    onValidClick, onValidMouseOver,
    validateMouseEvents,
}) {
    const isValid = validateMouseEvents();
    const [fill, setFill] = useState("transparent");

    return (
        <Anchor
            point={point}
            fill={fill}
            onClick={() => {
                if (isValid) {
                    onValidClick();
                }
            }}
            onMouseOver={() => {
                if (isValid) {
                    document.body.style.cursor = "pointer";
                    setFill("green");
                    onValidMouseOver();
                } else {
                    document.body.style.cursor = "not-allowed";
                    setFill("red");
                }
            }}
            onMouseOut={() => {
                setFill("transparent");
            }}
        />
    );
}

function Anchor({
    point, fill,
    onClick, onMouseOver, onMouseOut
}) {
    const {
        pageID,
        pages,
    } = useContext(TakeoffContext);

    const [strokeWidth, setStrokeWidth] = useState(2);

    return (
        <Circle
            x={point.x}
            y={point.y}
            radius={10.0 / pages[pageID].zoom}
            stroke="#666"
            fill={fill}
            strokeWidth={strokeWidth / pages[pageID].zoom}
            onMouseOver={() => {
                document.body.style.cursor = "pointer";
                setStrokeWidth(3);
                onMouseOver();
            }}
            onMouseOut={() => {
                document.body.style.cursor = "default";
                setStrokeWidth(2);
                onMouseOut();
            }}
            onClick={(e) => {
                if (e.evt.button !== 0) return;

                document.body.style.cursor = "default";
                onClick(e);
            }}
        />
    );
}