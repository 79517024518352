import { useContext, useState } from "react";
import { Rect } from "react-konva";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";

import { selectAuth } from "../../../redux/slices/authSlice";
import { TakeoffContext } from "../../helper/Context";
import { API_ROUTE } from "../../..";

export default function AILocalizationDots({ }) {
    const {
        AIAutoClassifying,
        AILocalization,
        pageID,
        pages,
    } = useContext(TakeoffContext);

    return (
        <>
            {AIAutoClassifying && AILocalization && AILocalization.sort((a, b) => (b.x2 - b.x1) * (b.y2 - b.y1) - (a.x2 - a.x1) * (a.y2 - a.y1)).map((rect, i) =>
                <AILocalizationDot
                    key={i}
                    rect={rect}
                    pageID={pageID}
                    pages={pages}
                />
            )}
        </>
    )
}

function AILocalizationDot({ rect, pageID, pages }) {
    const auth = useSelector(selectAuth);

    const {
        AIAutoClassifying, setAIAutoClassifying,
        AILocalization, setAILocalization,
        setMeasurements,
        tree, setTree,
    } = useContext(TakeoffContext);

    const [hovering, setHovering] = useState(false);

    const handleClassify = () => {
        setHovering(false);

        let tempName = 'temp' + uuidv4();

        setMeasurements(prev => ({
            ...prev,
            ['temp']: {
                'hide': false,
                'type': 'count',
                'page': pageID,
                'color': '#9DD9F3',
                'size': 5,
                'shape': 'circle',
                ['count_dots']: [
                    ...prev['temp']?.count_dots || [],
                    {
                        id: tempName,
                        x: (rect.x1 + rect.x2) / 2,
                        y: (rect.y1 + rect.y2) / 2,
                    }
                ],
            }
        }))
        setAILocalization(prev => prev.filter(r => r.id !== rect.id))

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/auto-classify/`,
            data: {
                'symbol': rect.id,
                'page': pageID,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);

                setMeasurements(prev => ({
                    ...response.data.measurements,
                    ['temp']: {
                        ...prev['temp'],
                        ['count_dots']: prev['temp']?.count_dots.filter(d => d.id !== tempName),
                    }
                }))
                setTree(response.data.tree)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <Rect
            x={rect.x1}
            y={rect.y1}
            width={rect.x2 - rect.x1}
            height={rect.y2 - rect.y1}
            fill={hovering ? "rgba(0, 0, 255, 0.1)" : "rgba(0, 0, 255, 0.00)"}
            stroke={hovering ? "blue" : ""}
            strokeWidth={1 / pages[pageID].zoom}
            onMouseEnter={(e) => {
                setHovering(true);
                e.target.getStage().container().style.cursor = "pointer";
            }}
            onMouseLeave={(e) => {
                setHovering(false)
                e.target.getStage().container().style.cursor = "default";
            }}
            onClick={(e) => {
                if (e.evt.button !== 0) return;

                handleClassify();
            }}
        />
    );
}