import React, { useContext, useState } from "react";
import { Circle, Rect, Layer, Line, Stage, Image } from "react-konva";
import { useSelector } from "react-redux";

import axios from "axios";

import { API_ROUTE } from "../../..";
import { TakeoffContext } from "../../helper/Context";
import { selectAuth } from "../../../redux/slices/authSlice";

export default function AIAutoFindDWRectangle({ }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages,
        groups, setGroups,
        currentGroup,
        createMeasurement,
        setDrawingRectangle,
        history, setHistory,
        currentHistory, setCurrentHistory,
        setMeasurements,
        tree, setTree,
        handleChangeFilter,
        AILocalization, setAILocalization,
        hintingAutoFindDW, setHintingAutoFindDW,
        AIMessages, setAIMessages,
    } = useContext(TakeoffContext);

    const [drawing, setDrawing] = useState(false);

    const handleInference = (points) => {
        setAIMessages(prev => ({
            ...prev,
            [pageID]: {
                ...prev[pageID],
                'autoFindDWCrop': {
                    ...prev[pageID].autoFindDWCrop,
                    'progress': 1 / 100,
                    'show': true,
                    'running': true,
                }
            }
        }));

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/auto-find-door-window-hint/`,
            data: {
                'page': pageID,
                'x1': Math.min(points.x1, points.x2),
                'y1': Math.min(points.y1, points.y2),
                'x2': Math.max(points.x1, points.x2),
                'y2': Math.max(points.y1, points.y2),
                'contractor': auth.contractor.id,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);

                setHintingAutoFindDW(prev => ({
                    ...prev,
                    [pageID]: {
                        ...prev[pageID],
                        start: null,
                        end: null,
                    }
                }));
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <>
            {hintingAutoFindDW?.[pageID]?.end && hintingAutoFindDW?.[pageID]?.start &&
                <Rect
                    x={hintingAutoFindDW?.[pageID]?.start?.x}
                    y={hintingAutoFindDW?.[pageID]?.start?.y}
                    width={hintingAutoFindDW?.[pageID]?.end?.x - hintingAutoFindDW?.[pageID]?.start?.x}
                    height={hintingAutoFindDW?.[pageID]?.end?.y - hintingAutoFindDW?.[pageID]?.start?.y}
                    fill={'#9DD9F3'}
                    stroke={'#006AFE'}
                    opacity={0.5}
                />
            }

            <Rect
                x={0}
                y={0}
                width={pages[pageID].width}
                height={pages[pageID].height}
                onMouseOver={(e) => {
                    if (AIMessages?.[pageID]?.autoFindDWCrop?.running) {
                        e.target.getStage().container().style.cursor = "progress";
                    } else {
                        e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+rectangle.svg') 8 24, auto";
                    }
                }}
                onMouseUp={(e) => {
                    if (e.evt.button !== 0) return;

                    if (!AIMessages?.[pageID]?.autoFindDWCrop?.running) {
                        if (drawing) {
                            const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                            const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                            setDrawing(false);
                            setHintingAutoFindDW(prev => ({
                                ...prev,
                                [pageID]: {
                                    ...prev[pageID],
                                    end: { x: x, y: y },
                                }
                            }));

                            handleInference({ x1: hintingAutoFindDW?.[pageID]?.start.x, y1: hintingAutoFindDW?.[pageID]?.start.y, x2: x, y2: y });
                        } else {
                            setDrawing(true);
                            const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                            const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                            setHintingAutoFindDW(prev => ({
                                ...prev,
                                [pageID]: {
                                    ...prev[pageID],
                                    start: { x: x, y: y },
                                }
                            }));
                        }
                    }
                }}
                onMouseMove={(e) => {
                    if (drawing) {
                        const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                        const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                        setHintingAutoFindDW(prev => ({
                            ...prev,
                            [pageID]: {
                                ...prev[pageID],
                                end: { x: x, y: y },
                            }
                        }));
                    }
                }}
            />
        </>
    );
}